import { Box } from '@mui/material';
import React from 'react';

const WaveAnimationDot = () => {
  return (
    <Box
      display="flex"
      justifyContent="left"
      alignItems="center"
      sx={{ height: '10px' }} // Adjust height as needed
    >
      <Box
        sx={{
          width: '6px',
          height: '6px',
          borderRadius: '50%',
          backgroundColor: '#858326',
          animation: 'wave 0.6s infinite alternate',
          margin: '0 5px'
        }}
      />
      <Box
        sx={{
          width: '6px',
          height: '6px',
          borderRadius: '50%',
          backgroundColor: '#858326',
          animation: 'wave 0.6s 0.2s infinite alternate',
          margin: '0 5px'
        }}
      />
      <Box
        sx={{
          width: '6px',
          height: '6px',
          borderRadius: '50%',
          backgroundColor: '#858326',
          animation: 'wave 0.6s 0.4s infinite alternate',
          margin: '0 5px'
        }}
      />

      {/* Add this style in your CSS */}
      <style>
        {`
          @keyframes wave {
            0% {
              transform: translateY(0);
            }
            100% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </Box>
  );
};

export default WaveAnimationDot;
