import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'organization';
// const API_URL = 'http://10.10.1.189:3000/api/organization';

export const GetOrganizationDetails = (reqData: any) => {
  return axios.get(API_URL, { params: reqData });
};
export const GetOrganizationDetailsByName = (reqData: any) => {
  return axios.get(API_URL + '/getOrganizationByName', { params: reqData });
  // return axios.get('http://10.10.1.132:3000/api/organization/getOrganizationByName', { params: reqData });
};

export const GetOrganizationSetting = async (reqData: any) => {
  return await axios.get(API_URL + '/setting', { params: reqData });
};

export const UpdateOrganizationSettings = (reqData: object) => {
  return axios.put(API_URL + '/setting', reqData);
};

export const GetBasicSettings = (reqData: object) => {
  return axios.get(API_URL + '/getbasicsetting', { params: reqData });
};

export const CreatePaymentSetting = (reqData: object) => {
  return axios.post(API_URL + '/paymentSettings', reqData);
};

export const UpdatePaymentSetting = (reqData: object) => {
  return axios.put(API_URL + '/paymentSettings', reqData);
};
export const UpdatePaymentStatus = (reqData: object) => {
  return axios.put(API_URL + '/paymentSettingStatus', reqData);
};

export const CreateEmailSetting = (reqData: object) => {
  return axios.post(API_URL + '/emailSettings', reqData);
};

export const UpdateEmailSetting = (reqData: object) => {
  return axios.put(API_URL + '/emailSettings', reqData);
};
export const UpdateEmailStatus = (reqData: object) => {
  return axios.put(API_URL + '/emailSettingStatus', reqData);
};
export const CreateSmsSetting = (reqData: object) => {
  return axios.post(API_URL + '/smsSettings', reqData);
};

export const UpdateSmsSetting = (reqData: object) => {
  return axios.put(API_URL + '/smsSettings', reqData);
};

export const UpdateSmsStatus = (reqData: object) => {
  return axios.put(API_URL + '/smsSettingStatus', reqData);
};

export const UpdateApiSetting = (reqData: object) => {
  return axios.put(API_URL + '/apiSettings', reqData);
};