import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
  TableBody,
  Stack,
  useTheme,
  TableContainer,
  Divider,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Pagination
} from '@mui/material';
import Loader2 from 'components/Loader2';
import ScrollX from 'components/ScrollX';
// import ScrollX from 'components/ScrollX';
import { EmptyTable } from 'components/third-party/ReactTable';
import { ArrowUp2, ArrowDown2 } from 'iconsax-react';
import NotFound from 'pages/extra-pages/not-found';
import { FormattedMessage } from 'react-intl';
import { Column, useTable, HeaderGroup, Cell } from 'react-table';
import moment from 'moment';
import { getCookie, setCookie } from 'utils/cookieUtils';
import { useLocation } from 'react-router';

function CustomTable({
  icon,
  text,
  link,
  columns,
  data,
  striped,
  sortArray,
  sort,
  setSortingData,
  notFound,
  search,
  count,
  size,
  pageIndex,
  handlePageChange,
  handleSizeChange,
  disableButton,
  selectedRowIndex
}: {
  icon?: any;
  text?: any;
  link?: any;
  disableButton?: boolean;
  columns: Column[];
  data: any;
  sortArray: string[];
  sort: any;
  setSortingData: any;
  striped?: boolean;
  notFound?: boolean;
  search?: any;
  count?: any;
  size?: any;
  pageIndex?: any;
  handlePageChange?: any;
  handleSizeChange?: any;
  color?: string;
  selectedRowIndex?: number[];
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });
  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;
  const primaryColor = theme.palette.primary.main;

  // const styles = {
  //   stickyCell: {
  //     '&:not(:last-of-type)': {
  //       position: 'sticky'
  //       /* Add other sticky-related properties as needed */
  //       // backgroundColor: 'white', // Example of additional property
  //       // top: 0, // Example of additional property
  //       // zIndex: 1, // Example of additional property
  //     }
  //   }
  // };

  // const rowStyles = {
  //   borderBottom: 'none'
  // };
  // const desiredRowCount = data && data.length < 10 ? 15 - data.length : 0;
  // const displayRows = Math.max(desiredRowCount, rows.length);
  return (
    <>
      <TableContainer>
        <ScrollX style={{ maxHeight: 650, minHeight: 'auto' }}>
          <Table stickyHeader {...getTableProps()}>
            <TableHead sx={{ zIndex: 1, position: 'relative' }}>
              {headerGroups?.map((headerGroup: HeaderGroup<{}>, index) => (
                <TableRow {...headerGroup?.getHeaderGroupProps()} key={index}>
                  {headerGroup?.headers?.map((column: HeaderGroup<{}> | any) => {
                    let data = sort && sort?.length > 0 ? sort : [];
                    let val: any = data?.length > 0 && data?.find((item: any) => item.id === column.id);
                    return (
                      <TableCell key={column.id} style={{ position: 'sticky' }}>
                        {column?.id === 'checkbox' ? (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ) : (
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Box sx={{ width: 'max-content' }}>
                              <FormattedMessage id={String(column?.render('Header'))} />
                            </Box>
                            {sortArray?.includes(column?.id) && (
                              <Stack
                                sx={{ color: 'secondary.light' }}
                                onClick={() => {
                                  setSortingData({ name: column?.id });
                                  if (val?.sort === true && val?.desc === true) {
                                    val = { ...val, sort: false, desc: false };
                                  } else if (val?.sort === true) {
                                    val = { ...val, desc: true };
                                  } else {
                                    data = [...data, { id: column?.id, sort: true, desc: false }];
                                  }
                                }}
                              >
                                <ArrowUp2
                                  size={16}
                                  variant="Bold"
                                  style={{
                                    color: val?.sort && !val?.desc ? theme.palette.secondary.main : 'inherit'
                                  }}
                                />
                                <ArrowDown2
                                  size={16}
                                  variant="Bold"
                                  style={{
                                    marginTop: -8,
                                    color: val?.desc ? theme.palette.secondary.main : 'inherit'
                                  }}
                                />
                              </Stack>
                            )}
                          </Stack>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()} {...(striped && { className: 'striped' })}>
              {rows?.length > 0 ? (
                rows.map((row: any, rowIndex: number) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      key={rowIndex}
                      sx={{
                        backgroundColor:
                          selectedRowIndex?.includes(rowIndex) ||
                          getCookie('tableSelectedRow') === JSON.stringify({ id: row.cells[0]?.row?.original?._id, path: pathname })
                            ? theme.palette.mode === 'dark'
                              ? '#3b698f !important'
                              : '#cee6ff !important'
                            : 'inherit',
                        cursor: 'pointer'
                      }}
                    >
                      {row.cells.map((cell: Cell<{}>, cellIndex: number) => {
                        const cellProps = cell.getCellProps([{ className: cell.column.className }]);
                        const { key, ...otherProps } = cellProps;

                        return (
                          <TableCell
                            onClick={() =>
                              setCookie('tableSelectedRow', JSON.stringify({ id: row.cells[0]?.row?.original?._id, path: pathname }), 0.5)
                            }
                            {...otherProps}
                            style={{ padding: 3, paddingLeft: '16px' }}
                            key={cellIndex}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : notFound && (!search || search?.length === 0) ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      height: '100%',
                      fontWeight: 'bold'
                    }}
                  >
                    <NotFound icon={icon} text={text} link={link} disableButton={disableButton} />
                  </TableCell>
                </TableRow>
              ) : !notFound && (search || search?.length > 0) ? (
                <EmptyTable msg="No Data" colSpan={columns.length} />
              ) : rows?.length === 0 ? (
                <EmptyTable msg="No Data" colSpan={columns.length} />
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      textAlign: 'center',
                      height: '20vh',
                      fontWeight: 'bold'
                    }}
                  >
                    <Loader2 />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollX>
        {size || count ? <Divider /> : <></>}
        {size || count ? (
          <Box sx={{ p: 2, py: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                {size ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="caption" color="secondary">
                        <FormattedMessage id="Row per page" />
                      </Typography>
                      <FormControl sx={{ m: 1 }}>
                        <Select
                          id="demo-controlled-open-select"
                          value={size}
                          onChange={handleSizeChange}
                          size="small"
                          defaultValue={10}
                          sx={{ '& .MuiSelect-select': { py: 0.75, px: 1.25 } }}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Stack>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-end' } }}>
                {!count || count === 0 ? (
                  <></>
                ) : (
                  <Pagination
                    onChange={handlePageChange}
                    count={Math.ceil(count / size)}
                    page={pageIndex}
                    variant="combined"
                    color="primary"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <></>
        )}
      </TableContainer>
    </>
  );
}

export default CustomTable;
