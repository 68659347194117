import { FormattedMessage } from 'react-intl';

// assets
import { Diagram } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

const icons = { Diagram };

// ==============================|| MENU ITEMS - category ||============================== //

const Timeline: NavItemType = {
  id: 'group-timeline',
  //   title: <FormattedMessage id="Get Started" />,
  type: 'group',
  icon: icons.Diagram,
  name: 'Timeline',
  children: [
    {
      icon: icons.Diagram,
      id: 'Timeline',
      title: <FormattedMessage id="Timeline" />,
      type: 'item',
      url: '/timeline'
    }
  ]
};

export default Timeline;
