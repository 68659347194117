import { Slide, useScrollTrigger } from '@mui/material';

interface Props {
  target?: any;
  children?: React.ReactElement<unknown>;
}
function HideOnScroll(props: Props) {
  const { children, target } = props;

  const trigger =
    target?.current &&
    useScrollTrigger({
      target: target ? target.current : undefined
    });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

export default HideOnScroll;
