import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
// project-imports
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import axios from 'axios';

// ==============================|| AUTH GUARD ||============================== //
const setSession = (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.authToken = `${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.authToken;
  }
};
const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn, user, tokenRemoved } = useAuth();
  const isLoggedInUserType = user && user.type ? user.type : '';
  const navigate = useNavigate();
  const location = useLocation();

  const userType = ['admin', 'sub-admin', 'superAdmin', 'manager', 'faculty', 'teacher', 'student'];
  // const adminType = ['admin', 'sub-admin'];
  // const facultyType = ['teacher'];
  // const studentType = ['student'];

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     navigate('login', {
  //       state: {
  //         from: location.pathname
  //       },
  //       replace: true
  //     });
  //   }
  // }, [isLoggedIn, navigate, location]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/');
    } else if (!isLoggedIn && location.pathname !== '/blog/viewblog') {
      navigate('/login');
    } else if (user?.type !== 'student' && location?.pathname?.split('/')?.[1] === 'learner') {
      navigate('/');
    } else if (user?.type === 'student' && location?.pathname?.split('/')?.[1] !== 'learner') {
      navigate('/learner');
    }
  }, [isLoggedIn, navigate, location]);

  useEffect(() => {
    if (isLoggedIn && isLoggedInUserType !== '' && userType.includes(isLoggedInUserType)) {
      let updatedUrl;
      const words = location?.pathname?.split('/');
      const lastWord = words?.[words?.length - 1];

      if (lastWord === 'edit') {
        updatedUrl = location?.pathname?.replace(/\/edit$/, '');
        updatedUrl === '/users' ? navigate(`${updatedUrl}/admin`) : navigate(`${updatedUrl}`);
      }
    }
  }, []);
  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          setSession();
          navigate('/login', {
            state: {
              unauthorized: true
            },
            replace: true
          });
        }
        return Promise.reject(error);
      }
    );
  }, []);
  useEffect(() => {
    let interval = setInterval(() => {
      let token: any = localStorage.getItem('serviceToken');
      let decodedToken: any = () => {
        if (token) {
          return jwt_decode(token);
        }
        return { exp: 0 };
      };
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken().exp * 1000 < currentDate.getTime()) {
        setSession();
        clearInterval(interval);
        navigate('/login');
        tokenRemoved()
      }
    }, 10000);
  }, []);
  return children;
};

export default AuthGuard;
