import { useState, ReactNode, useEffect } from 'react';
import Countdown from './Countdown';
import { getAssessment, addCompleteAssessment, submitCompleteAssessment, completeAssessment, submitQuestions } from 'services/quiz.service';
import {
  Alert,
  Box,
  Button,
  Step,
  Stepper,
  StepButton,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
  styled,
  TextField,
  CardContent,
  Modal,
  Stack,
  Checkbox
} from '@mui/material';
import MainCard from 'components/MainCard';
import { Warning2 } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import Editor from '@monaco-editor/react';
import Loader2 from 'components/Loader2';
import AssessmentReport from './Report';
import { getUserContent } from 'services/userCourse.service';
import { useSelector } from 'react-redux';
interface StepWrapperProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const CodeEditorWrapper = styled('div')({
  position: 'relative',
  borderRadius: '8px',
  overflow: 'hidden',
  width: '100%',
  height: '85vh'
});
function StepWrapper({ children, value, index, ...other }: StepWrapperProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <MainCard content={false} sx={{ p: 2 }}>
          {children}
        </MainCard>
      )}
    </div>
  );
}
export default function SkillMatrixStepper() {
  const [quiz, setQuiz] = useState<any>();
  const [id, setId] = useState('');
  const location = useLocation();
  const contestActiveTime = location?.state?.time;
  const navigateLocation = location?.state?.location;
  const assessmentId = location.state?.assessmentId;
  const { rowData } = location?.state;

  // const [all, setAll] = useState<any[]>([]);
  const { user }: any = useAuth();
  const { data: contentState } = useSelector((state: any) => state.currentContent);
  const [selectedAnswers, setSelectedAnswers] = useState<Array<any>>(Array(quiz?.questions?.length).fill([]));
  let reqData = { organizationId: user?.organizationId?._id };
  const quizDataSet = { assessmentId: assessmentId, orgId: reqData.organizationId };
  const [openAgain, setOpenAgain] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [message, setMessage] = useState('');
  // const [reload, setReload] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [resetId, setresetId] = useState();
  const [complete, setComplete] = useState(0);
  const [report, setReport] = useState<any>(null);
  const [done, setDone] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [yes, setYes] = useState<string | undefined>('both');
  const trueCount = Object.values(completed).filter((value) => value === true).length;
  const totalSteps = () => quiz?.questions?.length;
  const completedSteps = () => Object.keys(completed).length;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [errors, setErrors] = useState<string[]>([]);
  const [limits, setLimits] = useState(2);
  const initialTotalMinutes = quiz?.isLimit === 'limit' && parseInt(quiz?.duration, 10) !== 0 ? parseInt(quiz?.duration, 10) : 0;
  const [countdownValue, setCountdownValue] = useState(initialTotalMinutes);
  const [currentAssessmentId, setCurrentAssessmentId] = useState('');
  const [isCourse, setIsCourse] = useState('');
  const data: number = 0;
  const navigate = useNavigate();
  //   if (!assessmentId) {
  //     navigate('/quiz');
  //   }
  let steps: number[];
  try {
    steps = Array(quiz?.questions?.length).fill(data);
  } catch (error) {
    steps = [];
  }

  useEffect(() => {
    if (rowData) {
      setQuiz(rowData);
      setId(rowData?.quizId);
    }
  }, []);
  // useEffect(() => {
  //   const completeAssessment = {
  //     organizationId: reqData.organizationId,
  //     assessmentId: id,
  //     duration: '0'
  //   };
  //   if (id) {
  //     addCompleteAssessment(completeAssessment).then((res) => {
  //       setCurrentAssessmentId(res.data.data._id);
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [id]);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden === true) {
        if (!report && limits === 0 && done === 0) {
          setMessage('Quiz Submitted Because Of Your Misbehavior. Don`t Try This Again.');
          setDone(1);
          onSkip('misconduct');
        }
        if (limits > 0 && limits !== 0 && done !== 1) {
          setMessage(`You switched tabs or minimized the current tab. To This You Lose Chance Of Attend Quiz ${limits}`);
          setLimits(limits - 1);
        }
        setOpenAgain(true);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line
  }, [limits, report]);
  const handleClose = () => {
    setOpen(false);
    handleComplete();
  };
  const handleCloseAgain = () => {
    setOpenAgain(false);
  };
  const handleConfirm = () => {
    setOpenAgain(false);
  };
  // const handleNext = () => {
  //   if (activeStep !== steps.length - 1) {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   }
  // };
  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };
  const handleReset = (questionIndex: number) => {
    setCompleted((prevCompleted) => {
      const newCompleted = { ...prevCompleted };
      newCompleted[questionIndex] = false;
      return newCompleted;
    });

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[questionIndex] = '';
      return newErrors;
    });

    setSelectedAnswers((prevSelectedAnswers) => {
      const newSelectedAnswers = [...prevSelectedAnswers];
      newSelectedAnswers[questionIndex] = [];
      return newSelectedAnswers;
    });
  };

  const handleChange = (questionId: string, value: any, index: any) => {
    const updatedSelectedAnswers = [...selectedAnswers];
    if (!updatedSelectedAnswers[activeStep]) {
      updatedSelectedAnswers[activeStep] = { questionId, selectedValue: [] };
    }
    if (!updatedSelectedAnswers[activeStep].selectedValue) {
      updatedSelectedAnswers[activeStep].selectedValue = [];
    }
    updatedSelectedAnswers[activeStep].selectedValue[index] = value;
    updatedSelectedAnswers[activeStep].questionId = questionId;
    setSelectedAnswers(updatedSelectedAnswers);
  };
  const handleCheckChange = (questionId: any, value: any, index: any, isChecked: any) => {
    const updatedSelectedAnswers = [...selectedAnswers];
    if (!updatedSelectedAnswers[activeStep]) {
      updatedSelectedAnswers[activeStep] = { questionId, selectedValue: [] };
    }
    if (!updatedSelectedAnswers[activeStep].selectedValue) {
      updatedSelectedAnswers[activeStep].selectedValue = [];
    }
    if (isChecked) {
      updatedSelectedAnswers[activeStep].selectedValue.push(value);
    } else {
      updatedSelectedAnswers[activeStep].selectedValue = updatedSelectedAnswers[activeStep].selectedValue.filter(
        (item: any) => item !== value
      );
    }
    updatedSelectedAnswers[activeStep].questionId = questionId;
    setSelectedAnswers(updatedSelectedAnswers);
  };

  const handleChangeAnswerText = (index: any, value: any) => {
    const updatedSelectedAnswers = [...selectedAnswers];

    if (!updatedSelectedAnswers[index]) {
      updatedSelectedAnswers[index] = {};
    }

    updatedSelectedAnswers[index].answerText = value;

    setSelectedAnswers(updatedSelectedAnswers);
  };

  const handleComplete = () => {
    const newCompleted = { ...completed };
    const newErrors = [...errors];
    if (!selectedAnswers[activeStep]?.selectedValue) {
      newErrors[activeStep] = 'Please provide an answer before submitting.';
      setErrors(newErrors);
      setTimeout(() => {
        newErrors[activeStep] = '';
        setErrors(newErrors);
      }, 500);
      return;
    }
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    setErrors(newErrors);
    if (newErrors.some((error) => error)) {
      return;
    }
    if (activeStep === steps.length - 1) {
      const nextUnansweredStep = steps.findIndex((step, i) => !(i in newCompleted));
      if (nextUnansweredStep !== -1) {
        setActiveStep(nextUnansweredStep);
        return;
      }
    }
    const nextUnansweredStep = steps.slice(activeStep + 1).findIndex((step, i) => !(i + activeStep + 1 in newCompleted));
    if (nextUnansweredStep !== -1) {
      setActiveStep(activeStep + 1 + nextUnansweredStep);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const onCheck = () => {
    if (activeStep === totalSteps() - 1 && completedSteps() !== totalSteps() - 1) {
      if (!open) {
        handleOpen();
      }
    }
    if (activeStep === totalSteps()) {
      if (1 === activeStep) {
        if (!open) {
          handleOpen();
        }
      }
    }
    if (yes === 'yes') {
      if (!selectedAnswers[activeStep]?.selectedValue) {
        handleComplete();
      }
      setYes('both');
    }
    if (trueCount === totalSteps() - 1) {
      if (!selectedAnswers[activeStep]?.selectedValue) {
        handleComplete();
        handleOpen();
      } else {
        setOpenSummary(true);
        setDone(1);
      }
    }
    if (yes === 'both') {
      if (selectedAnswers[activeStep]?.selectedValue) {
        onSubmit();
        handleComplete();
      } else {
        handleComplete();
      }
    }
  };

  const onSkip = async (status: any) => {
    navigate('/learner/quiz');
  };

  const onSubmit = async () => {
    let singleQuestionData: any = {
      questionId: selectedAnswers[activeStep]?.questionId,
      answer: selectedAnswers[activeStep]?.selectedValue
        ? selectedAnswers[activeStep]?.selectedValue
        : selectedAnswers[activeStep]?.answerText
    };

    if (quiz) {
      singleQuestionData.quizId = id;
      // singleQuestionData.quizSetId = quiz.quizSetId;
    }
    let addResponse = await submitQuestions(singleQuestionData);

    if (complete !== 1 && !resetId) {
      setComplete(1);
      setresetId(addResponse?.data?.data?._id);
    }
    if (trueCount === totalSteps() - 1) {
      if (addResponse?.status === 200 || addResponse?.status === 201) {
        setReport(addResponse?.data?.data);
      }
    }
  };

  //   const handleCountdownChange = (newValue: any) => {
  //     setCountdownValue(newValue);
  //     if (countdownValue === 1) {
  //       setDone(1);
  //       if (done !== 1) {
  //         onSkip('timeOver');
  //       }
  //     }
  //   };

  const handleResetAll = async () => {
    // const response: any = await completeAssessment({
    //   assessmentId: quiz?._id,
    //   completedAssessmentId: currentAssessmentId
    // });
    // if (response.status === 200) {
      setCompleted({});
      setLimits(2);
      setSelectedAnswers([]);
      setDone(0);
      setYes('both');
      setReport(null);
      setComplete(0);
      setActiveStep(0);
    // }
  };

  const reportcard = {
    report: report,
    level: quiz?.difficulty
  };
  const handleClear = () => {};
  return (
    <>
      {report ? (
        <AssessmentReport
          open={true}
          reportcard={reportcard}
          OtherWise={handleResetAll}
          status={true}
          Ids={{ userId: location?.state?.userId, courseId: location?.state?.courseId }}
          location={navigateLocation}
          handleClear={handleClear}
          setQuiz={setQuiz}
          handleResetAll={handleResetAll}
          setId={setId}
        />
      ) : (
        <MainCard>
          {quiz?.questions ? (
            <>
              <Grid container alignItems="center" spacing={2} sx={{ mb: 1 }}>
                <Grid item xs={12} sm={6} md={9} lg={9}>
                  <Typography variant="h4">Skill Matrix</Typography>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3} lg={3}>
                  {initialTotalMinutes !== 0 && done !== 1 ? (
                    <Countdown
                      initialTotalMinutes={contestActiveTime ? parseInt(contestActiveTime) / 60 : initialTotalMinutes / 60}
                      onChange={handleCountdownChange}
                    />
                  ) : null}
                </Grid> */}
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={9} md={9} sm={12} xs={12}>
                  <div>
                    {done !== 0 ? (
                      <>
                        <Alert sx={{ my: 3 }}>Quiz Is Submitted.</Alert>
                        <Loader2 />
                      </>
                    ) : (
                      <>
                        {/* <Box
                          sx={{
                            display: 'flex',
                            // flexDirection: { xs: 'column', sm: 'row' },
                            // justifyContent: { xs: 'space-between', sm: 'flex-start' },
                            // alignItems: { xs: 'flex-start', sm: 'center' },
                            pb: 2,
                            width: '100%'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            {trueCount !== totalSteps() && (
                              <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, width: { xs: '100%', sm: 'auto' } }}>
                                <Button
                                  variant="outlined"
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{
                                    width: { xs: '100%', sm: 'auto' },
                                    mb: { xs: 2, sm: 0 },
                                    mr: { xs: 2, sm: 2 }
                                  }}
                                >
                                  Previous
                                </Button>
                              </Box>
                            )}
                          </Box>
                          {activeStep !== steps.length && (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                flexDirection: { xs: 'row', sm: 'row' },
                                width: { xs: '100%', sm: 'auto' }
                              }}
                            >
                              <Button
                                disabled={activeStep === steps.length - 1 || !enableNext}
                                onClick={handleNext}
                                sx={{
                                  width: { xs: '100%', sm: 'auto' },
                                  mb: { xs: 2, sm: 0 }
                                }}
                                variant="outlined"
                                color="primary"
                              >
                                Next
                              </Button>
                            </Box>
                          )}
                        </Box> */}
                        {quiz?.questions?.map((option: any, index: number) => (
                          <StepWrapper value={activeStep} index={index} key={index}>
                            <Typography variant="h6" component="span" sx={{ mb: 1 }}>
                              Question {index + 1}
                            </Typography>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '10px',
                                flexWrap: 'wrap'
                              }}
                            >
                              <Typography variant="h4" sx={{ userSelect: 'none', pointerEvents: 'none' }}>
                                {option.question}
                              </Typography>
                              {errors[index] && (
                                <Alert
                                  color="error"
                                  sx={{
                                    fontSize: { xs: '10px', sm: '12px', md: '14px' },
                                    sm: { mb: 2 },
                                    lg: { mb: 1 },
                                    md: { mb: 1 },
                                    xs: { mb: 1 }
                                  }}
                                  icon={<Warning2 variant="Bold" />}
                                >
                                  {errors[index]}
                                </Alert>
                              )}
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                marginTop: '4px'
                              }}
                            >
                              {/* {option?.image?.type && option?.image?.file && ( */}
                              <img
                                src={
                                  option?.image?.path
                                    ? process.env.REACT_APP_FILE_URL + option?.image?.path
                                    : `data:${option?.image?.type};base64,${option?.image?.file}`
                                }
                                alt="Preview"
                                height={option?.image ? '210px' : '0'}
                                style={{ borderRadius: '8px', objectFit: 'cover', marginBottom: 1 }}
                              />
                              {/* )} */}
                            </div>
                            <Divider sx={{ mt: 1 }} />
                            {(option?.type === 'mcq' || option?.type === 'fillInTheBlanks') &&
                              option?.multiOptions === 'mcq' &&
                              option?.optionType === 'single' && (
                                <FormControl component="fieldset">
                                  {option?.option && (
                                    <RadioGroup
                                      aria-label={`question-radio-${index}`}
                                      defaultValue=""
                                      name={`radio-buttons-group-${index}`}
                                      row
                                      value={selectedAnswers[index]?.selectedValue?.[0] || ''}
                                      onChange={(event) => {
                                        handleChange(option._id, event.target.value, 0);
                                      }}
                                    >
                                      {option.option.map((optionValue: any) => (
                                        <FormControlLabel
                                          key={optionValue}
                                          value={optionValue}
                                          control={<Radio />}
                                          label={optionValue}
                                          sx={{ ml: '4px', width: '100%' }}
                                        />
                                      ))}
                                    </RadioGroup>
                                  )}
                                  {option.otherAnswer && (
                                    <TextField
                                      id={`question-text-${index}`}
                                      label="Write Different Answer"
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                      sx={{ marginTop: '20px' }}
                                      onChange={(event) => handleChangeAnswerText(index, event.target.value)}
                                      value={selectedAnswers[index]?.answerText || ''}
                                    />
                                  )}
                                </FormControl>
                              )}
                            {(option?.type === 'mcq' || option?.type === 'fillInTheBlanks') && option?.optionType === 'multiple' && (
                              <>
                                {option.option.map((optionValue: any, optionIndex: number) => (
                                  <Box
                                    key={optionValue}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start'
                                    }}
                                  >
                                    <Checkbox
                                      color="primary"
                                      checked={selectedAnswers[index]?.selectedValue?.includes(optionValue) || false}
                                      onChange={(event) => handleCheckChange(option._id, optionValue, optionIndex, event.target.checked)}
                                    />
                                    <p>{optionValue}</p>
                                  </Box>
                                ))}
                              </>
                            )}
                            {option?.type === 'fillInTheBlanks' && option?.multiOptions === 'fillInTheBlanks' && (
                              <>
                                {Array.from({ length: option?.correctAnsCount || option?.correctAnswer?.length }).map((_, optionIndex) => (
                                  <TextField
                                    key={`question-text-${index}-${optionIndex}`}
                                    id={`question-text-${index}-${optionIndex}`}
                                    label={`Fill In The Blank #${optionIndex + 1}`}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    sx={{ marginTop: '10px' }}
                                    onChange={(event) => handleChange(option._id, event.target.value, optionIndex)}
                                    value={selectedAnswers[index]?.selectedValue?.[optionIndex] || ''}
                                  />
                                ))}
                              </>
                            )}
                            {option.type === 'simple' && (
                              <TextField
                                id={`question-text-${index}`}
                                label="Text Answer"
                                variant="outlined"
                                fullWidth
                                size="small"
                                rows={4}
                                multiline
                                sx={{ marginTop: '10px' }}
                                onChange={(event) => handleChange(option._id, event.target.value, 0)}
                                value={selectedAnswers[index]?.selectedValue?.[0] || ''}
                              />
                            )}
                            {option.type === 'code' && (
                              <CodeEditorWrapper sx={{ height: 'auto', mt: 1 }}>
                                <Editor
                                  height="260px"
                                  language={option?.selectedLanguage ? option?.selectedLanguage : 'text'}
                                  value={selectedAnswers[index]?.selectedValue?.[0] || ''}
                                  onChange={(value) => {
                                    handleChange(option._id, value, 0);
                                  }}
                                />
                              </CodeEditorWrapper>
                            )}
                          </StepWrapper>
                        ))}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            justifyContent: { xs: 'space-between', sm: 'flex-start' },
                            alignItems: { xs: 'flex-start', sm: 'center' },
                            pt: 2,
                            width: '100%'
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                            sx={{
                              '@media (max-width: 600px)': {
                                flexDirection: 'column-reverse'
                              },
                              '@media (min-width: 601px) and (max-width: 959.95px)': {
                                flexDirection: 'column-reverse'
                              }
                            }}
                          >
                            <Grid item lg={8} md={8} sm={8} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: { xs: 'column', sm: 'row' },
                                  justifyContent: { xs: 'flex-start', sm: 'flex-start' },
                                  alignItems: { xs: 'flex-start', sm: 'center' },
                                  width: { xs: '100%', sm: 'auto' },
                                  mb: { xs: 2, sm: 0 },
                                  order: { xs: 2, sm: 0 }
                                }}
                              >
                                {trueCount !== totalSteps() && (
                                  <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, width: { xs: '100%', sm: 'auto' } }}>
                                    <Button
                                      onClick={() => {
                                        onCheck();
                                      }}
                                      color="success"
                                      variant="contained"
                                      // variant={trueCount === totalSteps() - 1 ? 'contained' : 'outlined'}
                                      sx={{
                                        width: { xs: '100%', sm: 'auto' },
                                        ml: { xs: 0, sm: 'auto' }
                                      }}
                                    >
                                      {trueCount === totalSteps() - 1 ? 'Finish' : 'Submit'}
                                    </Button>
                                    {selectedAnswers[activeStep]?.selectedValue &&
                                      selectedAnswers[activeStep]?.selectedValue.length !== 0 && (
                                        <Button
                                          style={{ marginLeft: '16px' }}
                                          onClick={() => {
                                            setCompleted((prevCompleted) => {
                                              const newCompleted = { ...prevCompleted };
                                              newCompleted[activeStep] = false;
                                              return newCompleted;
                                            });
                                            handleReset(activeStep);
                                          }}
                                          color="error"
                                          variant="outlined"
                                          sx={{
                                            width: { xs: '100%', sm: 'auto' },
                                            ml: { xs: 0, sm: 'auto' }
                                          }}
                                        >
                                          Reset
                                        </Button>
                                      )}
                                  </Box>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <MainCard title="Total Questions" content={false}>
                    <Box sx={{ p: 1 }}>
                      <Stepper
                        nonLinear
                        orientation="vertical"
                        activeStep={activeStep}
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: {
                            xl: 'repeat(5, 1fr)',
                            lg: 'repeat(5, 1fr)',
                            md: 'repeat(15, 1fr)',
                            sm: 'repeat(8, 1fr)',
                            xs: 'repeat(5, 1fr)'
                          },
                          textAlign: 'center',
                          '.MuiStepConnector-root': {
                            display: 'none'
                          }
                        }}
                      >
                        {steps.map((label, index) => (
                          <Step key={label} completed={completed[index]}>
                            <StepButton
                              color="inherit"
                              onClick={() => {
                                if (completed[index]) {
                                  handleStep(index);
                                }
                              }}
                              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            ></StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  </MainCard>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Loader2 />
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h5">Quiz Getting Ready</Typography>
              </Box>
            </>
          )}
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <MainCard title="Quiz Alert" modal darkTitle content={false}>
              <CardContent>
                <Typography id="modal-modal-description">Do You Attend Remaining Questions?</Typography>
              </CardContent>
              <Divider />
              <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ px: 2.5, py: 2 }}>
                <Button
                  color="error"
                  size="small"
                  onClick={() => {
                    handleClose();
                    setYes('no');
                    onSkip('completed');
                  }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleClose();
                    setYes('yes');
                  }}
                >
                  Yes
                </Button>
              </Stack>
            </MainCard>
          </Modal>
          <Modal open={openAgain} onClose={handleCloseAgain} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <MainCard title="Quiz Alert" modal darkTitle content={false}>
              <CardContent>
                <Typography id="modal-modal-description">{message}</Typography>
              </CardContent>
              <Divider />
              <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ px: 2.5, py: 2 }}>
                <Button variant="contained" size="small" onClick={handleConfirm}>
                  Ok
                </Button>
                {/* {reload && (
                    <Button color="error" size="small" onClick={handleCloseAgain}>
                      Cancel
                    </Button>
                  )} */}
              </Stack>
            </MainCard>
          </Modal>
        </MainCard>
      )}
    </>
  );
}
