import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Box,
  TextField,
  FormControl,
  Typography,
  Autocomplete,
  IconButton,
  Chip,
  FormHelperText
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import * as Iconsax from 'iconsax-react';
import React from 'react';
import { Add, AddCircle, BoxRemove, MinusCirlce } from 'iconsax-react';
import { addContent } from 'services/landingPageManagement.service';
import { editContent } from 'services/landingPageManagement.service';
import UploadAvatar from 'components/third-party/dropzone/Avatar';

const validationSchema = yup.object({
  //   type: yup.string().required('Type is required'),
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  cards: yup
    .array()
    .of(
      yup.object({
        cardTitle: yup.string().required('Card title is required'),
        cardContent: yup.string().required('Card content is required'),
        cardImage1: yup
          .mixed()
          .required('cardImage1 is required')
          .test('fileType', 'Only JPG, PNG, JPEG, SVG and GIF files are allowed', (value) => {
            if (!value || value.length === 0) return false;
            const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
            return allowedFileTypes.includes(value.type ? value.type : value.mimetype);
          }),
        cardImage2: yup
          .mixed()
          .required('cardImage2 is required')
          .test('fileType', 'Only JPG, PNG, JPEG, SVG and GIF files are allowed', (value) => {
            if (!value || value.length === 0) return false;
            const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
            return allowedFileTypes.includes(value.type ? value.type : value.mimetype);
          })
      })
    )
    .min(1, 'At least one card is required')
    .max(5, 'You can only add up to 5 cards')
});

interface Card {
  cardTitle: string;
  cardContent: string;
  icon: string;
}

interface FormValues {
  cards: Card[];
}

function WhyUs(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { organization } = useAuth();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      title: rowData?.title || '',
      description: rowData?.description || '',
      cards: rowData?.cards || [
        {
          cardImage1: rowData ? rowData.cards.cardImage1 : [],
          cardImage2: rowData ? rowData.cards.cardImage2 : [],
          cardTitle: '',
          cardContent: ''
        }
      ]
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;
      formData.append('title', values.title);
      formData.append('description', values.description);
      rowData && formData.append(`id`, rowData?._id?.toString());
      // Append cards details
      if (rowData) {
        values.cards.forEach((card: any, index: any) => {
          formData.append(`cards[${index}][cardImage1]`, card.cardImage1._id ? card.cardImage1._id : card.cardImage1);
          formData.append(`cards[${index}][cardImage2]`, card.cardImage2._id ? card.cardImage2._id : card.cardImage2);
          formData.append(`cards[${index}][cardTitle]`, card.cardTitle);
          formData.append(`cards[${index}][cardContent]`, card.cardContent);
        });
      } else {
        values.cards.forEach((card: any, index: any) => {
          formData.append(`cards[${index}][cardImage1]`, card.cardImage1);
          formData.append(`cards[${index}][cardImage2]`, card.cardImage2);
          formData.append(`cards[${index}][cardTitle]`, card.cardTitle);
          formData.append(`cards[${index}][cardContent]`, card.cardContent);
        });
      }

      serviceMethod(formData, 'whyUs')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  // const existingImages1 = rowData?.cards?.length
  // ? rowData.cards.map((image: any) => process.env.REACT_APP_FILE_URL + image.cardImage1.path)
  // : [];

  // const existingImages2 = rowData?.cards?.length
  // ? rowData.cards.map((image: any) => process.env.REACT_APP_FILE_URL + image.cardImage2.path)
  // : [];

  const handleAddCard = () => {
    if (formik.values.cards.length < 5) {
      formik.setFieldValue('cards', [...formik.values.cards, { cardImage1: [], cardImage2: [], cardTitle: '', cardContent: '' }]);
    }
  };

  const handleRemoveCard = (index: number) => {
    if (formik.values.cards.length > 1) {
      const updatedCards = [...formik.values.cards];
      updatedCards.splice(index, 1);
      formik.setFieldValue('cards', updatedCards);
    }
  };

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Content' : 'Add Content'}</span>
        </span>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <>
            {/* Title and Description */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Title</InputLabel>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title ? <>{String(formik.errors.title)}</> : ''}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && <>{String(formik.errors.description)}</>}
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>
            <Divider />
            {/* Cards Section */}
            {formik.values.cards.map((card: any, index: any) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: 4,
                  m: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px'
                }}
              >
                <Grid container spacing={3} sx={{ width: '100%' }}>
                  <Grid item xs={12} sm={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="image">Normal Image Icon</InputLabel>
                      <Stack spacing={1}>
                        <UploadAvatar
                          setFieldValue={formik.setFieldValue}
                          file={card.cardImage1 ? process.env.REACT_APP_FILE_URL + card.cardImage1.path : null}
                          name={`cards[${index}].cardImage1`}
                        />
                        {formik.touched.cards?.[index]?.cardImage1 && formik.errors.cards?.[index]?.cardImage1 && (
                          <FormHelperText error style={{ color: 'red' }}>
                            {formik.errors.cards?.[index]?.cardImage1 as string}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="image">Hovered Image Icon</InputLabel>
                      <Stack spacing={1}>
                        <UploadAvatar
                          setFieldValue={formik.setFieldValue}
                          file={card.cardImage2 ? process.env.REACT_APP_FILE_URL + card.cardImage2.path : null}
                          name={`cards[${index}].cardImage2`}
                        />
                        {formik.touched.cards?.[index]?.cardImage2 && formik.errors.cards?.[index]?.cardImage2 && (
                          <FormHelperText error style={{ color: 'red' }}>
                            {formik?.errors?.cards?.[index]?.cardImage2 as string}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      label="Card Title"
                      name={`cards[${index}].cardTitle`}
                      value={card.cardTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.cards?.[index]?.cardTitle && Boolean(formik.errors.cards?.[index]?.cardTitle)}
                      helperText={formik.touched.cards?.[index]?.cardTitle && formik.errors.cards?.[index]?.cardTitle}
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      label="Card Content"
                      name={`cards[${index}].cardContent`}
                      value={card.cardContent}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        Array.isArray(formik.touched.cards) &&
                        formik.touched.cards?.length > 0 &&
                        formik.touched.cards?.[index]?.cardContent
                          ? true
                          : false
                      }
                      helperText={
                        Array.isArray(formik.touched.cards) &&
                        formik.touched.cards?.[index]?.cardContent && <>{String(formik.errors.cards?.[index]?.cardContent)}</>
                      }
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                      <IconButton onClick={() => handleRemoveCard(index)} disabled={formik.values.cards.length <= 1}>
                        <MinusCirlce />
                      </IconButton>
                      <IconButton onClick={handleAddCard} disabled={formik.values.cards.length >= 5}>
                        <AddCircle />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>

          <Divider />

          <Grid item xs={12}>
            <Stack direction="row" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-end' }}>
              <Link to={''} onClick={() => navigate(-1)}>
                <Button variant="outlined" color="error" sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Link>
              <Button variant="contained" type="submit">
                {rowData ? 'Update' : 'Add'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default WhyUs;
