import { useState, MouseEvent } from 'react';

// material-ui
import { Menu, MenuItem, Typography } from '@mui/material';

// project-imports
import IconButton from 'components/@extended/IconButton';

// assets
import { Copy, More, Trash } from 'iconsax-react';
import useChat from 'hooks/useChat';
import useAuth from 'hooks/useAuth';

// ==============================|| CHAT - ACTION ||============================== //

interface ChatMessageActionProps {
  chatId: string;
  chatMessage: string;
  filesInChat: boolean;
}

const ChatMessageAction = ({ chatId, chatMessage, filesInChat }: ChatMessageActionProps) => {
  const { user } = useAuth();
  const { deleteChat, currentUser } = useChat();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCopy = async () => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(chatMessage);
    }
    handleCloseMenu();
  };

  const handleDelete = async () => {
    if (user) {
      deleteChat(chatId);
    }
    handleCloseMenu();
  };

  return (
    <>
      {(!currentUser?.isAIChat || filesInChat) && (
        <>
          <IconButton
            id={`chat-action-button-${chatId}`}
            aria-controls={open ? `chat-action-menu-${chatId}` : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenMenu}
            size="small"
            color="secondary"
          >
            <More />
          </IconButton>
          <Menu
            id={`chat-action-menu-${chatId}`}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            MenuListProps={{
              'aria-labelledby': `chat-action-button-${chatId}`
            }}
            sx={{
              p: 0,
              zIndex:'999999999999',
              '& .MuiMenu-list': {
                p: 0
              }
            }}
          >
            {filesInChat && (
              <MenuItem onClick={handleCopy}>
                <Copy style={{ paddingRight: 8 }} />
                <Typography>Copy</Typography>
              </MenuItem>
            )}
            {!currentUser?.isAIChat && (
              <MenuItem onClick={handleDelete}>
                <Trash style={{ paddingRight: 8, paddingLeft: 0 }} />
                <Typography>Delete</Typography>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </>
  );
};

export default ChatMessageAction;
