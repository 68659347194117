import { IconButton, Paper, Slide, Stack, Typography, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { ArrowDown2, ArrowLeft2, ArrowUp2, Gemini, Maximize2 } from 'iconsax-react';
import Dot from '../@extended/Dot';
import Chat from 'pages/chat/Chat';
import DrawerChat from './MainInnerChat';
import useChat from 'hooks/useChat';

const ChatOptions = () => {
  const { user } = useAuth();
  const { getRoomUsers } = useChat();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [maximize, setMaximize] = useState(false);
  // const [contact, setContact] = useState<string | null>(null);
  // const [messages, setMessages] = useState<Array<{ message: string; type: string }>>([]);
  // const navigate = useNavigate();
  // const location = useLocation();
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
    // setContact(null);
  };
  // const handleChatNavigate = () => {
  //   navigate('/learner/chat', { state: { type: 'aibot' } });
  //   setOpen(false);
  //   setContact('aibot');
  // };
  const handleClose = () => {
    setOpen(false);
    setMaximize(false);
    // setContact(null);
  };
  useEffect(() => {
    if (user) {
      getRoomUsers();
    }
  }, [user, maximize]);
  const handleMaximize = () => {
    setMaximize(true);
  };
  const handleMinimize = () => {
    setMaximize(false);
  };
  // const handleSetChatMessages = (data: { message: string; type: 'bot' | 'user' }[]) => {
  //   setMessages((prev) => [...prev, ...data]);
  // };
  if (user?._id && user?.type === 'student') {
    return (
      <Stack position={'fixed'} bottom={0} right={0} zIndex={99999999} maxWidth={!maximize ? '320px' : '100%'} width={'100%'}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          mt={1}
          maxWidth={!maximize ? '320px' : '100%'}
          height={'50px'}
          sx={{
            backgroundColor: open ? '#141414' : '#a9dfd7',
            overflowX: 'hidden',
            transition: '0.3s ease-in-out',
            transform: open ? 'translateY(0)' : 'translateY(-10%)'
          }}
        >
          <Stack mx={3} direction={'row'} justifyContent={'right'} alignItems={'center'}>
            {drawerOpen && !maximize && (
              <IconButton
                onClick={() => {
                  setDrawerOpen(false);
                }}
              >
                <ArrowLeft2 color={theme.palette.primary.main} />
              </IconButton>
            )}
            <Stack direction={'row'} alignItems={'flex-start'}>
              <Typography variant="h4" color={'primary'}>
                {user?.firstName}
              </Typography>
              <Dot color="success" />
            </Stack>
          </Stack>
          <Stack direction={'row'}>
            <IconButton onClick={open ? handleClose : handleOpen} sx={{ mr: 0.5 }}>
              {open ? <ArrowDown2 color={theme.palette.primary.main} /> : <ArrowUp2 color={theme.palette.primary.main} />}
            </IconButton>
            {open && (
              <IconButton onClick={maximize ? handleMinimize : handleMaximize} sx={{ ml: 0.5 }}>
                {maximize ? <Gemini color={theme.palette.primary.main} /> : <Maximize2 color={theme.palette.primary.main} />}
              </IconButton>
            )}
          </Stack>
        </Stack>

        <Slide direction="up" in={open} unmountOnExit>
          <Paper
            elevation={2}
            sx={{ maxHeight: { lg: '92.2vh', md: '92.2vh', xs: '94vh' }, height: '100%', overflow: 'auto', borderRadius: '0px' }}
          >
            {!maximize ? <DrawerChat open={drawerOpen} setOpen={setDrawerOpen} /> : <Chat />}
          </Paper>
        </Slide>
        {/* {!open && (location.pathname === '/learner/chat' && contact === 'aibot' ? false : true) && ( */}
        {/* <Stack
          aria-label="add"
          onClick={handleOpen}
          sx={{
            cursor: 'pointer',
            animation: 'scale 4s ease-in-out infinite, shake 1s ease-in-out infinite',
            '@keyframes scale': {
              '0%, 100%': {
                transform: 'scale(1)'
              },
              '50%': {
                transform: 'scale(1.4)'
              }
            },
            '@keyframes shake': {
              '0%': { transform: 'scale(1) rotate(0deg)' },
              '15%': { transform: 'scale(1.2) rotate(0deg)' },
              '25%': { transform: 'scale(1.2) rotate(25deg)' },
              '50%': { transform: 'scale(1.2) rotate(-25deg)' },
              '60%': { transform: 'scale(1.2) rotate(0deg)' },
              '100%': { transform: 'scale(1) rotate(0deg)' }
            }
          }}
        >
          <Avatar
            variant="square"
            src={image}
            alt="sapphire"
            sx={{ width: '100%', height: 'auto', maxWidth: '70px', borderRadius: '10px' }}
          />
        </Stack> */}
        {/* )} */}
        {/* {open && (
          <Card sx={{ zIndex: 99, height: '100%', minHeight: '150px', backgroundColor: '#EAEEF3' }}>
            <CardHeader
              title={<Typography variant="h5">Welcome To Sapphire Lms!</Typography>}
              subheader={<Typography variant="subtitle2">Please Select Option To Communicate</Typography>}
              avatar={
                <Avatar
                  variant="square"
                  src={image}
                  alt="sapphire"
                  sx={{ width: '100%', height: 'auto', maxWidth: '50px', borderRadius: '10px' }}
                />
              }
              sx={{ backgroundColor: '#fff' }}
              action={
                <IconButton onClick={handleClose}>
                  <Add style={{ transform: 'rotate(45deg)' }} />
                </IconButton>
              }
            />

            <CardContent sx={{ height: '100%', maxHeight: '420px', overflowY: 'auto', p: 0, pt: contact === null ? 2 : 1, px: 2 }}>
              {contact === null ? (
                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} maxWidth={{ md: '420px', xs: '320px' }}>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    width={'100%'}
                    height={'100%'}
                    p={1}
                    alignItems={'center'}
                    mx={1}
                    onClick={() => {
                      setContact('faq');
                      setMessages([{ message: 'Thank you for Showing Interest!', type: 'bot' }]);
                    }}
                    sx={{
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease',
                      border: '1px solid',
                      color: theme.palette.primary.main,
                      borderRadius: '10px',
                      maxWidth: '50%',
                      '&:active': {
                        transform: 'scale(0.95)' // Scale down on click
                      }
                    }}
                  >
                    <Typography variant="h4">FAQ</Typography>
                    <Message />
                  </Stack>
                  {!(location.pathname === '/learner/chat') && (
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      p={1}
                      width={'100%'}
                      height={'100%'}
                      alignItems={'center'}
                      mx={1}
                      onClick={handleChatNavigate}
                      sx={{ cursor: 'pointer', border: '1px solid', color: theme.palette.primary.main, borderRadius: '10px' }}
                    >
                      <Typography variant={'h4'}>AI Chat</Typography>
                      <Messages2 />
                    </Stack>
                  )}
                </Stack>
              ) : (
                <Stack sx={{ minHeight: '200px', width: 'auto', mt: 0.5, maxHeight: '80%' }}>
                  {messages.map((message) => (
                    <Stack
                      spacing={1.25}
                      direction="row"
                      flexDirection={'column'}
                      // sx={{ marginRight: chat?.text?.split(/\s+/).some((word: string) => word.length > 80) ? '40px' : '0px' }}
                    >
                      <Stack>
                        {message.type === 'bot' && (
                          <Stack direction={'row'} alignItems={'center'}>
                            <Avatar
                              alt={user?.firstName || 'User Name'}
                              src={image}
                              sx={{
                                width: 34,
                                height: '5vh',
                                mr: 2,
                                border: '1px solid',
                                borderColor: theme.palette.primary.main,
                                p: 2,
                                bgcolor: 'transparent',
                                objectFit: 'contain',
                                marginRight: '7px',
                                '& .MuiAvatar-img': {
                                  height: '5vh',
                                  objectFit: 'contain',
                                  width: '88px',
                                  borderRadius: '50%'
                                }
                              }}
                            />
                            <Typography>Bot</Typography>
                          </Stack>
                        )}
                      </Stack>
                      <Grid container>
                        <Grid
                          item
                          xs={message?.type === 'bot' ? 10 : 12}
                          md={message?.type === 'bot' ? 9 : 12}
                          xl={message?.type === 'bot' ? 8 : 12}
                        >
                          <Stack
                            direction="row"
                            justifyContent={message?.type === 'bot' ? 'flex-start' : 'flex-end'}
                            alignItems={message?.type === 'bot' ? 'flex-start' : 'flex-end'}
                            mt={message.type === 'bot' ? 0 : 4}
                          >
                            <Card
                              sx={{
                                display: 'inline-block',
                                bgcolor: theme.palette.primary.lighter,
                                boxShadow: 'none',
                                ml: 1
                              }}
                            >
                              <CardContent sx={{ p: 1.2, pb: '8px !important' }}>{message?.message}</CardContent>
                            </Card>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  ))}
                  <Grid container sx={{ ml: 2, mt: 2 }}>
                    <Grid item xs={10} md={9} xl={8}>
                      <ChatOptionChip setMessages={handleSetChatMessages} />
                    </Grid>
                  </Grid>
                </Stack>
              )}
            </CardContent>
          </Card>
        )} */}
      </Stack>
    );
  }
  return null;
};

export default ChatOptions;
