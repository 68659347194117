// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Ticket2 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Ticket2 };

// ==============================|| MENU ITEMS - category ||============================== //

const SuperAdminTickets: NavItemType = {
    id: 'SuperAdminTickets',
    type: 'group',
    name: 'Super Admin Tickets List',
    icon: icons.Ticket2,
    children: [
        {
            icon: icons.Ticket2,
            id: 'SuperAdminTickets',
            title: <FormattedMessage id="Tickets List" />,
            type: 'item',
            url: '/superadmin/ticket'
        }
    ]
};

export default SuperAdminTickets;
