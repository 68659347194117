import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'subject/';

export const getAllSubjects = (reqData: Object) => {
  return axios.get(API_URL + 'getAll', { params: reqData });
};

export const addSubject = (reqData: Object) => {
  return axios.post(API_URL + 'add', reqData);
};

export const updateSubject = (reqData: Object, subjectId: string) => {
  return axios.put(API_URL + `update?subjectId=${subjectId}`, reqData);
};

export const statusChangeSubject = (reqData: { _id: string;[key: string]: any }) => {
  const { _id, ...data } = reqData;
  return axios.patch(API_URL + `statusChange/${_id}`, data);
};

export const deleteSubject = (reqData: Object) => {
  return axios.delete(API_URL + 'delete', { params: reqData });
};
