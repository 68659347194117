import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllUser } from 'services/user.service';
import useAuth from 'hooks/useAuth';
import { UserProfile } from 'types/auth';

const initialState: any = {
  data: [],
  users: [],
  loginDevices: [],
  sortingData: [],
  pageIndex: 1,
  count: 0,
  size: 10,
  totalUsers: 0,
  timeline: []
};

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserState: () => {
      return initialState;
    },
    setTotalUsers(state, action) {
      state.totalUsers = action.payload;
    },
    updateUser(state, action) {
      state.data = action.payload;
    },
    setSortingValue(state, action) {
      state.sortingData = action.payload;
    },
    getUsersDetails(state, action) {
      return {
        ...state,
        users: action.payload
      };
    },
    setUserPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setUserCount(state, action) {
      return {
        ...state,
        count: action.payload
      };
    },
    setUserTableSize(state, action) {
      return {
        ...state,
        size: action.payload
      };
    },
    // setLoggedDevices(state, action) {
    //   return {
    //     ...state,
    //     loginDevices: action.payload
    //   };
    // },

    removeUser(state, action) {
      return {
        ...state,
        users: state.users.filter((user: UserProfile) => user._id !== action.payload)
      };
    },
   
    removeLoginDevice(state, action) {
      const deviceIdToRemove = action.payload;

      const updatedData = state.users.map((user: any) => {
        if (user.loginDevices && user.loginDevices.some((device: any) => device._id === deviceIdToRemove)) {
          return {
            ...user,
            loginDevices: user.loginDevices.filter((device: any) => device._id !== deviceIdToRemove)
          };
        }
        return user;
      });


      return {
        ...state,
        users: updatedData
      };
    },


    getTimeLine(state, action) {
      return {
        ...state,
        timeline: action.payload
      };
    },

    extraReducers: (builder) => {
      builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
        state.data = action.payload;
      });
    }
  }
});

export const {
  updateUser,
  getUsersDetails,
  setUserPageIndex,
  setUserCount,
  setTotalUsers,
  setSortingValue,
  setUserTableSize,
  // setLoggedDevices,
  removeUser,
  getTimeLine,
  resetUserState,
  removeLoginDevice
} = users.actions;

export default users.reducer;

export const getUsers = createAsyncThunk('users/get', async () => {
  const { user }: any = useAuth();
  const response = await getAllUser({ orgId: user?.organizationId?._id });
  const users = response.data.data;
  return users;
});
