import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { Box, ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project-imports
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';
import { APP_DEFAULT_PATH } from 'config';
import useAuth from 'hooks/useAuth';
import LearnerBackToggle from 'components/third-party/dropzone/LearnerBackToggle';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => {
  const { user }: any = useAuth();
  // const currentPath = window.location.pathname;
  // const hasLearnerRoute = currentPath.includes('/learner');
  return (
    <Box sx={{ display: 'flex' }} className="sidebar-icon">
      {/* <Box sx={{ mr: 2, mt: 2.2 }}>{hasLearnerRoute ? <LearnerBackToggle show={true} /> : null}</Box> */}

      <ButtonBase
        disableRipple
        component={Link}
        to={!to ? (user?.type === 'student' ? '/' : user?.type === 'faculty' ? '/faculty' : '/dashboard') : to}
        sx={sx}
      >
        {isIcon ? <LogoIcon /> : <Logo reverse={reverse} />}
      </ButtonBase>
    </Box>
  );
};

export default LogoSection;
