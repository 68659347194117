import { useEffect, useState } from 'react';

// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Typography,
  List,
  FormGroup,
  Divider,
  Tooltip,
  OutlinedInput,
  Stack,
  FormControlLabel,
  Checkbox,
  Radio,
  IconButton,
  Chip,
  InputLabel,
  TextField,
  InputAdornment,
  useTheme,
  Grid,
  RadioGroup,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { Add, Calendar, CloseCircle, FilterAdd, SearchNormal1, TagCross } from 'iconsax-react';
import useConfig from 'hooks/useConfig';
import { FormattedMessage } from 'react-intl';
import { dispatch } from 'store';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useAuth from 'hooks/useAuth';
import { getAllSubjects } from 'services/subject.service';
import Snackbar from 'utils/Snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllClassRoom } from 'services/classRoom.service';

// ==============================|| DIALOG - SIZES ||============================== //
interface FilterProps {
  type: { val1: Array<string>; val2: string };
  // data?: Array<{ label: string; value: boolean }>;
  title: string;
  count?: number;
  setFilterKey: any;
  value: any;
  handleCloseOnSearch: any;
  disabled: any;
  setDisabled: any;
  pageIndex?: any;
  selectedEndDate?: any;
  selectedStartDate?: any;
  handleApplyFilters?: any;
}

const FilterComponent = ({
  type,
  title,
  count,
  setFilterKey,
  value,
  handleCloseOnSearch,
  disabled,
  setDisabled,
  pageIndex,
  selectedStartDate,
  selectedEndDate,
  handleApplyFilters
}: FilterProps) => {
  const { organization, user } = useAuth();
  const theme = useTheme();
  const primaryColor = user?.type === 'student' ? '#13AC9E' : theme.palette.primary.main;
  const { i18n } = useConfig();
  const [search, setSearch] = useState('');
  const [range, setRange] = useState(selectedStartDate || selectedEndDate ? true : false);
  const navigate = useNavigate();
  const [classType, setClassType] = useState('');
  const [subject, setSubject] = useState({});
  const [classRoomId, setClassRoomId] = useState('');
  const [classRoomName, setClassRoomName] = useState('');
  const [semester, setSemester] = useState('');
  const [otherName, setOtherName] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [streamName, setStreamName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [subjectData, setSubjectData] = useState([]);
  const [classTypeOption, setClassTypeOption] = useState([]);
  const [classRoomData, setClassRoomData] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handlePreferences = (field: string, value: any) => {
    const matchedClassRoom: any = classRoomData.find((room: { _id: any; class: string }) => room._id == value);

    switch (field) {
      case 'classType':
        setClassType(value);
        setFilterKey('classType', { names: [value], indices: value });

        break;
      case 'classRoomId':
        setClassRoomId(value);
        handleClassSelect(value);
        if (matchedClassRoom) {
          setClassRoomName(matchedClassRoom?.className);
          setFilterKey('classRoom', { names: [matchedClassRoom?.className], _ids: [value] });
        }

        break;
      case 'semester':
        setSemester(value);
        handleClassSelect(value);
        if (matchedClassRoom) {
          setClassRoomName(matchedClassRoom?.semester);
          setFilterKey('classRoom', { names: [matchedClassRoom?.semester], _ids: [value] });
        }
        break;
      case 'streamName':
        setStreamName(value);
        handleClassSelect(value);
        if (matchedClassRoom) {
          setClassRoomName(matchedClassRoom?.streamName);
          setFilterKey('classRoom', { names: [matchedClassRoom?.streamName], _ids: [value] });
        }
        break;
      case 'otherName':
        setOtherName(value);
        handleClassSelect(value);
        if (matchedClassRoom) {
          setClassRoomName(matchedClassRoom?.className);
          setFilterKey('classRoom', { names: [matchedClassRoom?.className], _ids: [value] });
        }
        break;
      case 'subject':
        setSubject(value);
        const selectedSubject: any = subjectData?.find((item: any) => item?._id === value);
        setFilterKey('subject', { names: [selectedSubject?.subjectName], _id: value });
        break;
      default:
        break;
    }
  };

  const handleClassSelect = async (id: any) => {
    const selectedClassroom: any = classRoomData?.find((classroom: any) => classroom._id.toString() === id.toString());
    const subjects = selectedClassroom?.subjects ?? [];
    setSubjectData(subjects);
  };

  useEffect(() => {
    getAllClassRoom({
      organizationId: user?.organizationId?._id,
      classType: classType ? classType : 'class',
      active: true
    }).then((res) => {
      if (res.status === 200) {
        const subjects = res.data.data?.classRoomData.flatMap((item: any) => item.subjects.map((subject: any) => subject)) || [];
        // setSubjectData(subjects);
        setClassRoomData(res.data.data?.classRoomData);
        setClassTypeOption(
          res.data.data?.classRoomData?.map((item: any) => {
            return classType === 'class'
              ? { _id: item?._id, className: item?.className || item?.class, subject: item?.subject, classType: item?.classType }
              : classType === 'semester'
              ? {
                  _id: item?._id,
                  semester: item?.semester,
                  streamName: item?.streamName || item?.className,
                  subject: item?.subject,
                  classType: item?.classType
                }
              : classType === 'other'
              ? { _id: item?._id, otherName: item?.className, subject: item?.subject, classType: item?.classType }
              : {};
          })
        );
      }
    });
  }, [classType]);

  // useEffect(() => {
  //   classTypeOption && getSubjectData();
  // }, [subject]);
  // const getSubjectData = () => {
  //   setIsLoading(true);
  //   getAllSubjects({ organizationId: organization?.organizationId, limit: 100, skip: 1 })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setIsLoading(false);
  //         setSubjectData(res?.data?.data?.data);
  //         // setDisabledFilter(false);
  //       } else {
  //         setIsLoading(false);
  //         Snackbar(res.data.message, 'error');
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       if (err?.response?.status === 403) {
  //         const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
  //         Snackbar(errorMessage, 'warning');
  //       } else {
  //         const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
  //         Snackbar(errorMessage, 'error');
  //       }
  //     });
  // };

  function capitalizeFirstLetter(str: any) {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  const [startDate, setStartDate] = useState(selectedStartDate);
  const [endDate, setEndDate] = useState(selectedEndDate);

  // Handler for date change
  const handleDateChange = (date: any, type: any) => {
    if (type === 'start') {
      setStartDate(date);
    } else if (type === 'end') {
      setEndDate(date);
      // handleClose();
    }
  };

  // useEffect(() => {
  //   // let quizReqData: any = {};
  //   let newClassRoomId: any = '';
  //   if (classType) {
  //     if (classType === 'class') {
  //       newClassRoomId = classRoomId;
  //     } else if (classType === 'semester') {
  //       newClassRoomId = semester;
  //       if (semester) {
  //         newClassRoomId = streamName;
  //       }
  //     } else if (classType === 'other') {
  //       newClassRoomId = otherName;
  //     } else {
  //       newClassRoomId = classType;
  //     }
  //     if (
  //       (classType === 'class' && classRoomId) ||
  //       (classType === 'semester' && semester && streamName) ||
  //       (classType === 'other' && otherName)
  //     ) {
  //       // const selectedSubject: any = subjectData?.find((item) => item?._id === subject);
  //       // setFilterKey('subject', { names: [selectedSubject?.subjectName], _id: subject });
  //     }
  //   }
  //   // setFilterKey('subject', subject);
  //   // setFilterKey('classRoomId', newClassRoomId);
  //   setFilterKey('classType', { names: [classType], indices: classType });
  //   setFilterKey('classRoom', { names: [classRoomName], _ids: [newClassRoomId] });
  //   // setFilterKey('classType', { names: [classType], indices: classType });
  //   // setFilterKey('classType', classType);
  //   if (pageIndex) dispatch(pageIndex(1));
  // }, [classType, subject, classRoomId, pageIndex]);

  useEffect(() => {
    endDate && setFilterKey('endDate', endDate);
    startDate && setFilterKey('startDate', startDate);
  }, [endDate, startDate]);
  // setFilterKey('endDate', endDate);

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h5">{title}</Typography>
      {typeof type === 'string' && (
        <>
          <Stack direction="row" spacing={1} sx={{ py: 1, mr: 2, justifyContent: 'left', alignItems: 'center' }} alignItems="center">
            <Stack>
              <OutlinedInput
                // value={value || search || ''}
                value={search || value || ''}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setFilterKey('search', e.target.value);
                }}
                placeholder={
                  i18n === 'fr'
                    ? `recherche à partir de ${count} enregistrements`
                    : i18n === 'ro'
                    ? `cauta din ${count} inregistrari`
                    : i18n === 'zh'
                    ? `从 ${count} 条记录中搜索`
                    : `Search ${count} records...`
                }
                id="start-adornment-email"
              />
            </Stack>
            <Stack>
              <Tooltip title={<FormattedMessage id="Search" />}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (search) {
                      if (pageIndex) dispatch(pageIndex(1));
                      handleApplyFilters();
                      // handleCloseOnSearch();
                    }
                  }}
                  sx={{ p: '9px', minWidth: 'auto' }}
                >
                  <SearchNormal1 />
                </Button>
              </Tooltip>
            </Stack>
            {range ? (
              <></>
            ) : (
              <Stack>
                <Tooltip title={<FormattedMessage id="Select Range" />}>
                  <Button variant="outlined" color="primary" onClick={() => setRange(true)} sx={{ p: '9px', minWidth: 'auto' }}>
                    <Calendar />
                  </Button>
                </Tooltip>
              </Stack>
            )}
          </Stack>

          <Grid container spacing={3} sx={{ minWidth: '100px' }}>
            <Grid item xs={12} sm={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <RadioGroup aria-label="size" name="radio-buttons-group" value={classType} row>
                <FormControlLabel
                  value="class"
                  control={<Radio />}
                  label="Class"
                  disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'class' : false}
                  onClick={(e: any) => handlePreferences('classType', e.target.value)}
                />
                <FormControlLabel
                  value="semester"
                  control={<Radio />}
                  label="Semester"
                  disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'semester' : false}
                  onClick={(e: any) => handlePreferences('classType', e.target.value)}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                  disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'other' : false}
                  onClick={(e: any) => handlePreferences('classType', e.target.value)}
                />
              </RadioGroup>
            </Grid>

            {classType === 'class' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="classRoomId">Classroom Name:</InputLabel>
                  <Select
                    fullWidth
                    id="classRoomId"
                    value={classRoomId}
                    name="classRoomId"
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      // console.log('selectedValues', selectedValues);
                      if (selectedValues?.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('classRoomId', selectedValues);
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                      <Add />
                      <FormattedMessage id="Add Class" />
                    </MenuItem>
                    {classTypeOption?.map((value: any, index: number) => (
                      <MenuItem key={index} value={value._id}>
                        {value.className}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}

            {classType === 'semester' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="semester">Semester Name:</InputLabel>
                  <Select
                    fullWidth
                    id="semester"
                    value={semester}
                    name="semester"
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues?.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('semester', selectedValues);
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                      <Add />
                      <FormattedMessage id="Add Semester" />
                    </MenuItem>
                    {classTypeOption?.map((value: any) => (
                      <MenuItem key={value._id} value={value._id}>
                        {value.semester}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}
            {classType === 'semester' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="streamName">Learner's stream name:</InputLabel>
                  <Select
                    fullWidth
                    id="streamName"
                    value={streamName}
                    name="streamName"
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues?.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('streamName', selectedValues);
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    {/* <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                          <Add />
                          <FormattedMessage id="Add streamName" />
                        </MenuItem> */}
                    {classTypeOption?.map((value: any) => {
                      if (semester === value._id) return <MenuItem value={value._id}>{value.streamName}</MenuItem>;
                    })}
                  </Select>
                </Stack>
              </Grid>
            )}
            {classType === 'other' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="otherName">Learner's other classType:</InputLabel>
                  <Select
                    labelId="otherName"
                    fullWidth
                    id="otherName"
                    value={otherName || ''}
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues?.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('otherName', selectedValues);
                      }
                    }}
                  >
                    <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                      <Add />
                      <FormattedMessage id="Add other classType" />
                    </MenuItem>
                    {classTypeOption?.map((value: any) => (
                      <MenuItem key={value._id} value={value._id}>
                        {value.otherName}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}

            {classType &&
              ((classType === 'class' && classRoomId) ||
                (classType === 'semester' && semester && streamName) ||
                (classType === 'other' && otherName)) && (
                <Grid item xs={6}>
                  <Stack>
                    <InputLabel htmlFor="Subject" sx={{ mb: 1 }}>
                      Subject:
                    </InputLabel>
                    <Select
                      name="subject"
                      id="subject"
                      value={subject}
                      onChange={(event) => {
                        if (event.target?.value?.includes('custom')) {
                          navigate('/subject/add');
                        } else {
                          handlePreferences('subject', event.target.value);
                        }
                      }}
                      fullWidth
                    >
                      <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                        <Add />
                        <FormattedMessage id="Add Subject" />
                      </MenuItem>
                      {subjectData?.map((item: any) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.subjectName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              )}
          </Grid>
        </>
      )}

      {typeof type === 'string' && (
        <>
          {range ? (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h5">Range</Typography>
              <Stack direction={'row'} spacing={1} mt={1} mb={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack>
                    <InputLabel htmlFor="cal-startDate-date">Start Date</InputLabel>
                    <MobileDatePicker
                      value={startDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date, 'start')}
                      disabled={disabled}
                      closeOnSelect={true}
                      // maxDate={endDate}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          id="cal-startDate-date"
                          placeholder="Start date"
                          fullWidth
                          // error={Boolean(touched.startDate && errors.startDate)}
                          // helperText={touched.startDate && (errors.startDate as String)}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </Stack>
                  <Stack>
                    <InputLabel htmlFor="cal-endDate-date">End Date</InputLabel>
                    <MobileDatePicker
                      value={endDate}
                      inputFormat="dd/MM/yyyy"
                      onChange={(date) => handleDateChange(date, 'end')}
                      closeOnSelect={true}
                      minDate={startDate}
                      disabled={!startDate}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          id="cal-endDate-date"
                          placeholder="End date"
                          fullWidth
                          // error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                          // helperText={formik.touched.endDate && (formik.errors.endDate as String)}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Calendar />
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                  </Stack>

                  <Stack>
                    <IconButton
                      sx={{ mt: 2.5 }}
                      onClick={() => {
                        setRange(false);
                        setStartDate(null);
                        setEndDate(null);
                        setFilterKey('startDate', null);
                        setFilterKey('endDate', null);
                      }}
                      size="large"
                      color="error"
                    >
                      <TagCross size={44} />
                    </IconButton>
                  </Stack>
                </LocalizationProvider>
              </Stack>
              <Typography variant="caption" color={'red'} sx={{ mt: 2 }}>
                {' '}
                *note: End date can only be selected after the Start Date
              </Typography>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <List disablePadding component="nav" aria-label="main mailbox folders" sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {type &&
          Array.isArray(type?.val1) &&
          type?.val1 &&
          type?.val1?.length > 0 &&
          type?.val1.map((item, index: any) => (
            <Box key={index}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    key={item}
                    id={index}
                    name={title}
                    value={item}
                    control={type?.val2 === 'checkbox' ? <Checkbox disabled={disabled} /> : <Radio disabled={disabled} />}
                    label={capitalizeFirstLetter(item)}
                    labelPlacement="end"
                    sx={{ ml: 1 }}
                    checked={
                      type?.val2 === 'checkbox' ? (value?.names ? value?.names?.includes(item) : value.includes(item)) : value === item
                    }
                    onChange={(e: any) => {
                      setFilterKey(title, index);
                      if (pageIndex) dispatch(pageIndex(1));
                      // setDisabled(true);
                    }}
                  />
                </FormGroup>
              </FormControl>
            </Box>
          ))}
      </List>
    </>
  );
};

export default function ModalFilter({
  filterKeywords,
  onFilterKeywordChange,
  values,
  count,
  disabled,
  setDisabled,
  pageIndex,
  applyFilter,
  filterFlag,
  filterValuesSet,
  setFilterSubmitValues,
  submittedFilterValues
}: {
  filterKeywords?: any;
  onFilterKeywordChange: any;
  applyFilter: any;
  values: any;
  count: number;
  disabled: any;
  setDisabled: any;
  pageIndex?: any;
  filterFlag?: any;
  filterValuesSet?: any;
  setFilterSubmitValues?: any;
  submittedFilterValues?: any;
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const { organization, user } = useAuth();
  const theme = useTheme();
  const primaryColor = user?.type === 'student' ? '#13AC9E' : theme.palette.primary.main;

  const handleClose = () => {
    setOpen(false);
    applyFilter(!filterFlag);
    filterValuesSet(submittedFilterValues);
  };
  const handleCloseOnSearch = () => {
    applyFilter(!filterFlag);
    setOpen(false);
  };
  const handleApplyFilters = () => {
    applyFilter(!filterFlag);
    setOpen(false);
    setFilterSubmitValues(values);
  };
  return (
    <>
      <Box sx={{ mr: 2, ml: 2, justifyContent: 'center', display: 'flex' }}>
        {/* <Tooltip title={<FormattedMessage id="Filters" />}> */}
        <Chip
          color="primary"
          variant={user?.type === 'student' ? 'filled' : 'light'}
          icon={<FilterAdd variant="Bold" />}
          onClick={handleClickOpen}
          label="Filter"
          // sx={{ py: 1, cursor: 'pointer', backgroundColor: primaryColor, "& .MuiChip-label": { color: primaryColor } }}
        />
        {/* <Button variant="outlined" color="primary" onClick={handleClickOpen} sx={{ p: '9px', minWidth: 'auto' }}>
            <Filter size="32" />
          </Button> */}
        {/* </Tooltip> */}
      </Box>
      {/* <Button sx={{ mr: 2, ml: 2, mt: 1 }} variant="contained" onClick={handleClickOpen}></Button> */}
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <IconButton
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            background: 'transparent',
            border: 'none'
          }}
          onClick={handleClose}
        >
          <CloseCircle />
        </IconButton>
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle>Filters</DialogTitle>

          <DialogContent>
            {Object.entries(filterKeywords).map(([key, value]: any) => (
              <FilterComponent
                key={key} // Make sure to add a unique key for each component in the array
                type={value}
                title={key}
                value={values[key]}
                count={count}
                setFilterKey={onFilterKeywordChange}
                handleCloseOnSearch={handleCloseOnSearch}
                disabled={disabled}
                setDisabled={setDisabled}
                pageIndex={pageIndex}
                selectedStartDate={values?.startDate}
                selectedEndDate={values?.endDate}
                handleApplyFilters={handleApplyFilters}
              />
            ))}

            {/* <FilterComponent
              type={'category'}
              title={'Search from catageory'}
              count={count}
              setFilterKey={onFilterKeywordChange}
              filterKeywords={filterKeywords}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleApplyFilters}>
              Apply Filter
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
