import { ReactNode, useMemo } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, AppBarProps, Typography } from '@mui/material';

// project-imports
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

import IconButton from 'components/@extended/IconButton';
import { DRAWER_WIDTH, MINI_DRAWER_WIDTH } from 'config';
import useConfig from 'hooks/useConfig';

// assets
import { HambergerMenu } from 'iconsax-react';

// typs
import { LAYOUT_CONST } from 'types/config';
import useAuth from 'hooks/useAuth';
import { useSelector } from 'store';
import Avatar from 'components/@extended/Avatar';
import { useNavigate } from 'react-router';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;
  const { mode } = useConfig();
  // header content
  const headerContent = useMemo(() => <HeaderContent />, []);
  const setting: any = useSelector((state) => state.organization)?.logo;
  const organization: any = useSelector((state) => state.organization);

  // const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  // const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';

  // common header
  const mainHeader: ReactNode = (
    <Toolbar id="header-nav" sx={{ px: { xs: 1, sm: 3.5, lg: 6 } }}>
      {!isHorizontal ? (
        <>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            variant="light"
            size="large"
            sx={{ color: 'secondary.main', backgroundColor: 'transparent', ml: { xs: 0, lg: -2 }, p: 1 }}
          >
            <HambergerMenu className={mode ? 'mainlogoicon' : ''} />
          </IconButton>
          {!open && (
            <>
              <Typography
                onClick={() => navigate(user?.type === 'faculty' ? '/faculty' : '/dashboard')}
                sx={{
                  color: '#0073A8',
                  fontWeight: 700,
                  fontSize: { xs: 12, sm: 14, md: 18 },
                  mx: 1,
                  fontFamily: 'revert',
                  display: { xs: 'none', md: 'block' },
                  textTransform: 'capitalize',
                  cursor: 'pointer'
                }}
              >
                {organization?.setting?.identity?.site_name ? organization?.setting?.identity?.site_name : organization?.name}
              </Typography>
            </>
          )}
          <Avatar
            src={
              setting?.[0]?.path
                ? process.env.REACT_APP_FILE_URL + setting?.[0]?.path
                : `data:${setting?.[0]?.mimetype};base64,${setting?.[0]?.file}`
            }
            onClick={() => navigate(user?.type === 'faculty' ? '/faculty' : '/dashboard')}
            sx={{
              mx: 1,
              display: { xs: 'block', md: 'none' },
              objectFit: 'contain',
              border: '1px solid',
              borderColor: theme.palette.primary.main,
              bgcolor: 'transparent',
              cursor: 'pointer'
            }}
          />
        </>
      ) : null}
      {headerContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    elevation: 0,
    sx: {
      bgcolor: alpha(theme.palette.background.default, 0.8),
      backdropFilter: 'blur(10px)',
      zIndex: 1200,
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
      borderRadius: ['0px 0px 15px 15px', '0px 0px 15px 15px', '0px 0px 15px 0px'],
      width: isHorizontal ? '100%' : open ? `calc(100% - ${DRAWER_WIDTH}px)` : { xs: '100%', lg: `calc(100% - ${MINI_DRAWER_WIDTH}px)` }
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
