// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { CalendarEdit } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';


// icons
const icons = { icon1: CalendarEdit };

// ==============================|| MENU ITEMS - category ||============================== //

const TimeTableSchedulerMenu: NavItemType = {
    id: 'TimetableScheduler',
    name: 'TimetableScheduler',
    type: 'group',
    icon: icons.icon1,
    children: [
        {
            icon: icons.icon1,
            id: 'TimetableScheduler',
            title: <FormattedMessage id="Timetable Scheduler" />,
            type: 'item',
            url: '/timetable-scheduler'
        }
    ]
};

export default TimeTableSchedulerMenu;
