import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customNotificationsList: [],
  pageIndex: 1,
  size: 10,
  totalCount: 0,
  deleted: false,
  search: undefined,
  customNotificationsSorting: []
};

const customNotification = createSlice({
  name: 'customNotification',
  initialState,
  reducers: {
    updateCustomNotificationsList(state, action) {
     state.customNotificationsList = action.payload;
    },
    setCustomNotificationsPageIndex(state, action) {
      return {
        ...state,
        pageIndex: action.payload
      };
    },
    setCustomNotificationsSearch(state, action) {
      state.search = action.payload;
    },
    setCustomNotificationsTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setCustomNotificationsSorting(state, action) {
      state.customNotificationsSorting = action.payload;
    },
    setCustomNotificationsTableSize(state, action) {
        return {
          ...state,
          size: action.payload
        };
      },
    resetCustomNotificationsState: (state) => {
      return initialState;
    },
  }
});

export const {
  updateCustomNotificationsList,
  setCustomNotificationsPageIndex,
  setCustomNotificationsSearch,
  setCustomNotificationsTotalCount,
  setCustomNotificationsSorting,
  resetCustomNotificationsState,
  setCustomNotificationsTableSize
} = customNotification.actions;

export default customNotification.reducer;
