import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'userActivity';
// const API_URL = process.env.REACT_APP_BASEURL + 'course';

// export const getAllCourse = async (reqData: any) => {
//   return axios.get(`${API_URL}/allOrg`, { params: reqData });
// };

// export const getAllCourseEdit = async (courseId: any) => {
//   return axios.get(`${API_URL}?courseId=${courseId}`);
// };

export const getAllLogs = async (reqData: object) => {
  return axios.get(`${API_URL}/all`, { params: reqData });
};
