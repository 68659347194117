import { ReactNode, useMemo } from 'react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, useMediaQuery, AppBarProps } from '@mui/material';

// project-imports
import AppBarStyled from './AppBarStyled';
// import StudentHeaderContent from './StudentNavBar';

import IconButton from 'components/@extended/IconButton';
import useConfig from 'hooks/useConfig';

// assets
import { HambergerMenu } from 'iconsax-react';

// typs
import { LAYOUT_CONST } from 'types/config';
import StudentHeaderContent from './HeaderContent/StudentNavBar';
import useAuth from 'hooks/useAuth';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
  hideDrawer: boolean;
  isStudent: boolean;
}

const Header = ({ open, handleDrawerToggle, hideDrawer, isStudent }: Props) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down(1266));
  const { menuOrientation } = useConfig();
  const { user } = useAuth();

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  // header content
  const studentHeaderContent = useMemo(() => <StudentHeaderContent hideDrawer={hideDrawer} isStudent={isStudent} />, []);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'secondary.200' : 'secondary.200';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'secondary.100';

  // common header
  const mainHeader: ReactNode = (
    <Toolbar id="header-nav" sx={{ boxShadow: '4'}}>
      <style>
        {user?.type === 'student'
          ? `.MuiToolbar-root{
        padding-left:0px !important;
        padding-right:0px !important;
        padding-top:0px !important;
        padding-bottom:0px !important;
        margin-bottom:0px !important;
        min-height:0px !important;
      }`
          : ``}
      </style>
      {!isHorizontal && !hideDrawer ? (
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          color="secondary"
          variant="light"
          size="large"
          sx={{ color: 'secondary.main', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 }, p: 1 }}
        >
          <HambergerMenu />
        </IconButton>
      ) : null}
      {studentHeaderContent}
    </Toolbar>
  );

  // app-bar params
  const appBar: AppBarProps = {
    position: 'fixed',
    elevation: 0,
    sx: {
      bgcolor: alpha(theme.palette.background.default, 0.8),
      backdropFilter: 'blur(8px)',
      zIndex: 1200
      // width: isHorizontal ? '100%' : open ? `calc(100% - ${DRAWER_WIDTH}px)` : { xs: '100%', lg: `calc(100% - ${MINI_DRAWER_WIDTH}px)` }
    }
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default Header;
