import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL + 'notifyMessage';

export const getAllNotifyMessage = (reqData: Object) => {
  return axios.get(API_URL + '/all', { params: reqData });
};

export const markSingleRead = (reqData: Object) => {
  return axios.put(`${API_URL}/singleRead`, reqData);
};

export const markAllRead = (reqData: Object) => {
  return axios.put(`${API_URL}/allRead`, reqData);
};
export const clearAll = (reqData: Object) => {
  return axios.put(API_URL + '/clearAll', reqData);
};
export const resendNotification = (reqData: Object) => {
  return axios.put(`${API_URL}/resend`, reqData);
};

export const addCustomNotification = async (reqData: Object) => {
  return await  axios.post(API_URL + '/sendCustom', reqData);
};

export const getAllCustomNotification = async (reqData: Object) => {
  return await  axios.get(API_URL + '/all', { params: reqData });
};