import { Grid, SelectChangeEvent } from '@mui/material';
import { getAllLogs } from 'services/useractivity.service';
import { DragPreview } from 'components/third-party/ReactTable';
import { useEffect, useMemo, useState } from 'react';
import { Chip } from '@mui/material';
import { getTimeLine } from 'store/reducers/users';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Data404 from 'components/third-party/Data404';
import CustomTable from 'components/@extended/CustomTable';
import { Timer } from 'iconsax-react';
import SkeletonTable from 'components/SkeletonTable';
import useAuth from 'hooks/useAuth';
import MainCard from 'components/MainCard';

const TimeLineTable = (props: any) => {
  const [pageSize, setPageSize] = useState(10);
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(true);
  const handlePageChange = (event: any, page: number) => {
    setCurrentPage(page);
  };
  const userGet = async (userId: any) => {
    dispatch(getTimeLine([]));
    setLoading(true);
    getAllLogs({ userId: user?._id, limit: pageSize, skip: currentPage })
      .then((response) => {
        setCount(response.data.totalCount);
        setLoading(false);
        dispatch(getTimeLine(response.data.newArray));
        setLoading(false);
        if (response.data.newArray?.length > 0) setNotFound(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    userGet(props.id);
  }, [currentPage, pageSize]);

  const data = useSelector((state: any) => state.users).timeline;
  const columns = useMemo(
    () => [
      {
        Header: 'Message',
        accessor: 'message',
        Cell: ({ value }: { value: string }) => {
          let color: 'error' | 'info' | 'success' | 'default' | 'primary' | 'secondary' | 'warning' = 'default';

          // Determine the color based on the message type
          if (value.toLowerCase().includes('logged in')) {
            color = 'success';
          } else if (value.toLowerCase().includes('logged out')) {
            color = 'warning';
          } else if (value.toLowerCase().includes('created')) {
            color = 'primary';
          } else if (value.toLowerCase().includes('deleted')) {
            color = 'error';
          } else if (value.toLowerCase().includes('updated')) {
            color = 'info';
          }

          return <Chip color={color} label={value} size="small" variant="light" sx={{ my: 1 }} />;
        }
      },
      {
        Header: 'Message Data',
        accessor: 'messageData'
      }
    ],
    []
  );

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(+event.target.value);
    setCurrentPage(1);
  };
  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: 600 }}>
          <span style={{ marginLeft: 5 }}>User Activity</span>
        </span>
      }
      content={false}
    >
      <Grid container spacing={1}>
        {loading ? (
          <Grid item xs={12}>
            <SkeletonTable columns={columns} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            {data && data.length > 0 ? (
              <DndProvider backend={HTML5Backend}>
                {data && (
                  <CustomTable
                    icon={Timer}
                    text="No timeline to display"
                    sortArray={[]}
                    columns={columns}
                    data={data || []}
                    sort={[]}
                    setSortingData={null}
                    notFound={notFound}
                    search={''}
                    count={count}
                    size={pageSize}
                    pageIndex={currentPage}
                    handlePageChange={handlePageChange}
                    handleSizeChange={handleChange}
                  />
                )}
                <DragPreview />
              </DndProvider>
            ) : (
              <Data404 title="No Data Available" />
            )}
          </Grid>
        )}
      </Grid>
    </MainCard>
  );
};

export default TimeLineTable;
