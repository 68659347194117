import { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import useChat from 'hooks/useChat';
import sapphireImage from 'assets/images/sapphire_logo.jpg';
import useAuth from 'hooks/useAuth';
import Dot from 'components/@extended/Dot';
import { useDispatch } from 'react-redux';
import {
  removeUnreadCount,
  setAiUnreadCount,
  setChatLoading,
  setChatMessage,
  setChatSkip,
  setCurrentUser,
  setTotalChatCount,
  setUnreadChip,
  setUserChatId,
  setUserRoomId,
  setUserSearch,
  setUserSwitching
} from 'store/reducers/chat';
import Avatar from 'components/@extended/Avatar';
import { useTheme } from '@mui/system';
import { useSelector } from 'store';
import { UserAdd } from 'iconsax-react';
import { Image } from 'types/auth';
import { useLocation } from 'react-router';
import { AIChatEvents, socket } from 'utils/socket';

// Import avatars dynamically
const avatarImage = (require as any).context('assets/images/users', true);

interface User {
  _id: string;
  firstName: string;
  lastName: string;
  type: string;
  isLoggedIn: boolean;
  unReadMessage?: string;
  isOnline?: boolean;
  userProfilePicId: Image;
  isAIChat?: boolean;
  roomId?: string;
}

interface AIUser {
  isAIChat: boolean;
  roomId: string;
}

interface RoomUser {
  users: User[];
  roomId: string;
  isAIChat: boolean;
}

function UserList({ closeDrawer }: { closeDrawer: () => void }) {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { user, organization } = useAuth();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { roomUsers, orgUsers, userChats, getRoomChats, skip, searchText, createRoom, activeUser, readChat } = useChat();
  const currentRoomId = useSelector((state) => state.chat.roomId);
  const AiChatId = useSelector((state) => state.chat.aiRoomId);
  const [data, setData] = useState<User[][]>([]);

  useEffect(() => {
    const filteredUsers =
      roomUsers?.length > 0 &&
      roomUsers?.map((roomUser: RoomUser) => {
        if (roomUser?.isAIChat) {
          return [{ roomId: roomUser?.roomId, isAIChat: true }];
        }
        return roomUser?.users?.filter((roomUser) => roomUser._id !== user?._id);
      });
    // const finalData =
    //   filteredUsers?.length > 0 ? filteredUsers.filter((val: any) => !(val?.[0]?.isAIChat && !organization?.GeminiApi)) : [];
    setData(filteredUsers);
  }, [roomUsers, user?._id, activeUser]);

  const handleUserClick = async (roomId: string, user: User | AIUser, count?: number) => {
    dispatch(setChatSkip(1));
    dispatch(setUserChatId(null));
    dispatch(setChatMessage(''));
    dispatch(setUserSwitching(true));
    await getRoomChats(roomId, 1);
    if (count && count > 0) {
      dispatch(removeUnreadCount(roomId));
    }
    dispatch(setUnreadChip(true));
    dispatch(setUserRoomId(roomId));
    dispatch(setCurrentUser(user));
    (matchDownLG || location.pathname !== '/learner/chat') && closeDrawer();
  };

  const handleCreateRoom = async (receiverUserId: string) => {
    const users = [receiverUserId, user?._id as string];
    createRoom(users as [string]);
    dispatch(setUserSearch(''));
  };

  // const readAllMessages = () => {
  //   const chatIds = userChats.map((chat: any) => chat._id);
  //   console.log('chatIds', chatIds);
  //   // readChat(chatIds)
  // };

  useEffect(() => {
    if (currentRoomId && skip !== 1) {
      // readAllMessages();
      getRoomChats(currentRoomId);
    } else {
      if (skip !== 1) {
        dispatch(setTotalChatCount(userChats.length - 1));
        dispatch(setChatLoading(false));
      }
    }
  }, [skip]);

  useEffect(() => {
    if (AiChatId && state?.type === 'aibot') {
      const filteredRoom = roomUsers.filter((room: { _id: string }) => room?._id === AiChatId);
      handleUserClick(AiChatId, { roomId: AiChatId, isAIChat: true }, filteredRoom?.[0]?.unreadChatCount);
      dispatch(setAiUnreadCount(filteredRoom?.[0]?.unreadChatCount));
    }
  }, [AiChatId]);
  const typing = useSelector((state) => state.chat.isTyping);

  return (
    <List component="nav">
      {searchText === '' ? (
        data?.length > 0 &&
        data.map((users, index) => (
          <Fragment key={index}>
            <ListItemButton
              sx={{ pl: 1 }}
              onClick={() => {
                const roomUser = roomUsers?.find((roomUser: any) => roomUser.users.includes(users[0]));
                if (roomUser || users?.[0]?.isAIChat) {
                  if (users?.[0]?.isAIChat) {
                    handleUserClick(AiChatId, { roomId: AiChatId, isAIChat: true }, roomUsers[index]?.unreadChatCount);
                  } else {
                    handleUserClick(roomUser.roomId, users[0], roomUsers[index]?.unreadChatCount);
                  }
                }
              }}
            >
              <ListItemAvatar>
                {users?.map((user, index) => (
                  <Box position={'relative'} key={index}>
                    <Avatar
                      key={user._id}
                      alt={user?.isAIChat ? 'Sapphire Bot' : user.firstName || 'User Name'}
                      src={
                        user?.isAIChat
                          ? sapphireImage
                          : `data:${user?.userProfilePicId?.mimetype};base64,${user?.userProfilePicId?.file}` || avatarImage
                      }
                      size="md"
                      sx={{
                        // width: 40,
                        // height: '6vh',
                        border: '1px solid',
                        borderColor: theme.palette.primary.main,
                        p: 2,
                        bgcolor: 'transparent',
                        objectFit: 'contain',
                        marginRight: '7px',
                        '& .MuiAvatar-img': {
                          objectFit: 'contain',
                          height: '6vh',
                          width: '88px',
                          borderRadius: '50%'
                        }
                      }}
                    />
                    <Stack direction={'row-reverse'} alignItems={'center'} sx={{ position: 'absolute', bottom: 0, left: 30 }}>
                      {/* <Chip
                          variant="light"
                          label={users[0]?.type || 'User Role'}
                          color={users[0]?.type === 'admin' ? 'primary' : users[0]?.type === 'faculty' ? 'warning' : 'info'}
                          sx={{ mt: 0.5 }}
                          size="small"
                        /> */}

                      <Dot
                        color={users[0]._id in activeUser || users?.[0]?.isAIChat ? 'success' : 'error'}
                        sx={{ height: '7px', width: '7px' }}
                      />
                    </Stack>
                  </Box>
                ))}
              </ListItemAvatar>
              {users.length < 2 && (
                <Tooltip title={users[0]?.isAIChat ? 'Sapphire Bot' : users[0]?.firstName + ' ' + users[0]?.lastName}>
                  <ListItemText
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                    primary={
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            {users[0]?.isAIChat
                              ? 'Sapphire Bot'
                              : `${users[0].firstName} ${users[0].lastName}`.length > 20
                              ? `${users[0].firstName} ${users[0].lastName}`.slice(0, 20) + '...'
                              : `${users[0].firstName} ${users[0].lastName}`}
                          </Typography>
                          <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
                            {users[0]?.isAIChat ? 'Bot' : users[0]?.type || 'User Role'}
                          </Typography>
                          {users[0]?._id && users[0]?._id in activeUser && (
                            <Typography sx={{ color: 'green' }} variant="subtitle2">
                              {typing?.[users[0]._id] ? 'typing...' : ''}
                            </Typography>
                          )}
                        </Box>
                      </Stack>
                    }
                    secondary={
                      <Stack component="span" direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        {roomUsers[index]?.unreadChatCount > 0 && (
                          <Tooltip title="Unread messages">
                            {/* <Typography
                              color="text.secondary"
                              variant="caption"
                              sx={{
                                fontWeight: '500',
                                backgroundColor: '#FFEAD1',
                                borderRadius: '50%',
                                width: '17px',
                                height: '17px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                color: '#0A4A8A',
                                border: '1px solid #0A4A8A'
                              }}
                            >
                              {roomUsers[index]?.unreadChatCount
                                ? roomUsers[index]?.unreadChatCount > 99
                                  ? `99+`
                                  : roomUsers[index]?.unreadChatCount
                                : null}
                            </Typography> */}
                            <Chip
                              variant="light"
                              size="small"
                              color="primary"
                              label={
                                roomUsers[index]?.unreadChatCount
                                  ? roomUsers[index]?.unreadChatCount > 99
                                    ? `99+`
                                    : roomUsers[index]?.unreadChatCount
                                  : null
                              }
                            />
                          </Tooltip>
                        )}
                      </Stack>
                    }
                  />
                </Tooltip>
              )}
            </ListItemButton>
            <Divider />
          </Fragment>
        ))
      ) : orgUsers?.length > 0 ? (
        orgUsers?.map((currentUser: any) => {
          let roomId;
          const isUserInRoom = roomUsers?.some((room: any) => room?.users?.some((roomUser: any) => roomUser?._id === currentUser?._id));
          if (currentUser?._id === user?._id) {
            return null;
          }
          return (
            <Fragment key={currentUser._id}>
              <Tooltip title={currentUser.firstName + ' ' + currentUser.lastName}>
                <ListItemButton
                  sx={{ pl: 1 }}
                  onClick={() => {
                    roomId = roomUsers
                      ?.map((room: any) => {
                        const receiverUser = room?.users?.filter((roomUser: any) => roomUser?._id === currentUser?._id);
                        if (receiverUser?.length > 0) {
                          return room?.roomId;
                        }
                      })
                      .filter((roomUser: any) => roomUser !== null && roomUser !== undefined);

                    if (roomId?.length > 0) {
                      handleUserClick(roomId[0], currentUser);
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Box position={'relative'}>
                      <Avatar
                        key={currentUser?._id}
                        alt={currentUser?.firstName || 'User Name'}
                        src={avatarImage}
                        size="xl"
                        sx={{
                          width: 40,
                          height: 40,
                          borderColor: theme.palette.primary.main,
                          p: 2,
                          bgcolor: 'transparent',
                          marginRight: '7px',
                          '& .MuiAvatar-img': {
                            height: '88px',
                            width: '88px',
                            borderRadius: '50%'
                          }
                        }}
                      />
                      <Stack direction={'row-reverse'} alignItems={'center'} sx={{ position: 'absolute', bottom: 0, left: 30 }}>
                        {currentUser?.isLoggedIn && (
                          <Dot color={currentUser?._id in activeUser ? 'success' : 'error'} sx={{ height: '7px', width: '7px' }} />
                        )}
                      </Stack>
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                          <Box>
                            <Typography
                              variant="subtitle1"
                              color="text.primary"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              {`${currentUser?.firstName} ${currentUser?.lastName}`.length > 20
                                ? `${currentUser?.firstName} ${currentUser?.lastName}`.slice(0, 20) + '...'
                                : `${currentUser?.firstName} ${currentUser?.lastName}`}
                            </Typography>
                            <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
                              {currentUser?.type || 'User Role'}
                            </Typography>
                          </Box>
                        </Stack>
                        {!isUserInRoom && (
                          <IconButton
                            onClick={() => {
                              handleCreateRoom(currentUser?._id);
                            }}
                          >
                            <UserAdd size="28" />
                          </IconButton>
                        )}
                      </Stack>
                    }

                    // primary={
                    //   <>
                    //     <Stack direction="column" alignItems="left" justifyContent="space-between" spacing={1}>
                    //       <Typography
                    //         variant="subtitle1"
                    //         color="text.primary"
                    //         sx={{
                    //           overflow: 'hidden',
                    //           textOverflow: 'ellipsis',
                    //           whiteSpace: 'nowrap'
                    //         }}
                    //       >
                    //         {`${currentUser?.firstName} ${currentUser?.lastName}`}
                    //       </Typography>
                    //       <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2">
                    //         {currentUser?.type || 'User Role'}
                    //       </Typography>
                    //     </Stack>
                    //     <Stack direction="row" alignItems="center" justifyContent="right" spacing={1}>
                    //       {!isUserInRoom && (
                    //         <UserAdd
                    //           size="28"
                    //           color="#FF8A65"
                    //           onClick={() => {
                    //             handleCreateRoom(currentUser?._id);
                    //           }}
                    //         />
                    //       )}
                    //     </Stack>
                    //   </>
                    // }
                  />
                </ListItemButton>
              </Tooltip>
              <Divider />
            </Fragment>
          );
        })
      ) : (
        <Typography>No User Found</Typography>
      )}
    </List>
  );
}

export default UserList;
