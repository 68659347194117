import { FormattedMessage } from 'react-intl';
import { DirectNotification } from 'iconsax-react';
import { NavItemType } from 'types/menu';

const icons = { DirectNotification };

// ==============================|| MENU ITEMS - category ||============================== //

export const CustomNotification: NavItemType = {
  id: 'CustomNotification',
  type: 'group',
  name: 'CustomNotification',
  icon: icons.DirectNotification,
  children: [
    {
      icon: icons.DirectNotification,
      id: 'CustomNotification',
      title: <FormattedMessage id="Custom Notification" />,
      type: 'item',
      url: 'customNotification'
    }
  ]
};


export const SuperAdminCustomNotification: NavItemType = {
  id: 'CustomNotification',
  type: 'group',
  name: 'CustomNotification',
  icon: icons.DirectNotification,
  children: [
    {
      icon: icons.DirectNotification,
      id: 'CustomNotification',
      title: <FormattedMessage id="Custom Notification" />,
      type: 'item',
      url: '/superadmin/customNotification'
    }
  ]
};
