import { createContext, useEffect, useReducer, ReactElement } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, SETORGANIZATION } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
import axios from 'axios';
import { KeyedObject } from 'types/root';
import { AuthProps, JWTContextType } from 'types/auth';
import { registerService, loginService, logoutService } from '../services/auth.service';
import { GetOrganizationSetting } from '../services/organization.service';
import { getUserByToken } from '../services/user.service';
import { useLocation, useNavigate } from 'react-router';

// import { encryptData, decryptData } from '../utils/helper';
import { useDispatch } from 'react-redux';
import { setLogo, setSettings } from 'store/reducers/organizationSettings';
import { APP_ADMIN_DEFAULT_PATH, APP_DEFAULT_PATH, APP_FACULTY_DEFAULT_PATH, APP_STUDENT_DEFAULT_PATH } from 'config';
import { connectSocket, socket } from 'utils/socket';
// import { setActiveUser } from 'store/reducers/chat';
import useConfig from 'hooks/useConfig';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false
};

// if (process.env.REACT_APP_ENV === 'development') {
//   // Request interceptor
//   axios.interceptors.request.use(
//     function (config) {
//       if (config.data) {
//         config.data = { encryptedData: encryptData(config.data) };
//       }
//       if (config.params) {
//         config.params = { encryptedData: encryptData(config.params) };
//       }
//       // if (config.query) {
//       //   config.query = { encryptedData: encryptData(config.query) };
//       // }
//       return config;
//     },
//     function (error) {
//       console.error('Request Error Interceptor:', error);
//       return Promise.reject(error);
//     }
//   );

//   // Response interceptor
//   axios.interceptors.response.use(
//     function (response) {
//       if (response.data) {
//         if (response.data.data) {
//           response.data.data = response.data.data ? decryptData(response.data.data) : decryptData(response.data);
//         } else {
//           response.data = response.data.data ? decryptData(response.data.data) : decryptData(response.data);
//         }
//       }
//       return response;
//     },
//     function (error) {
//       console.error('Response Error Interceptor:', error);
//       return Promise.reject(error);
//     }
//   );
// }

const verifyToken: (st: string) => boolean = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded: KeyedObject = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = async (serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.authToken = `${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.authToken;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const dispatches = useDispatch();
  const { onChangePresetColor, onChangeMode } = useConfig();

  const init = async () => {
    try {
      /**
       * Commented code is for domain url redirection code so don't remove it.
       */

      // const url = new URL(window.location.href)
      // const searchParams = new URLSearchParams(location.search);
      const serviceToken = localStorage.getItem('serviceToken');
      // || searchParams.get("st");
      await setSession(serviceToken);
      // const isFirstLogin = searchParams.get("ifl");
      // console.log('Step-1')

      if (serviceToken && verifyToken(serviceToken)) {
        // console.log('=======INITIALIZING=======')
        // console.log('Step-2')
        let mySetting: any;

        const response = await getUserByToken();
        // console.log('Step-3')

        const user = response.data.data;
        // let organizationUrl = user?.organizationId?.domainUrl

        // if(process.env.REACT_APP_ENVIRONMENT !== 'production' && organizationUrl) {
        //   // console.log('Step-4')
        //   organizationUrl = organizationUrl + '.localhost:3006'
        // }

        // console.log('organizationUrl-->', organizationUrl)
        // console.log('url-->', url)
        // if(user?.type !== 'superadmin' && url.host !== organizationUrl) {
        //   window.location.replace(`${url.protocol}//${organizationUrl}`)
        // }
        // console.log('Step-5')

        if (response?.data?.data?.organizationId?._id && user?.type !== 'superadmin') {
          mySetting = await GetOrganizationSetting({ orgId: response.data.data.organizationId._id });
          dispatches(setSettings(mySetting.data.data));
          dispatches(setLogo(mySetting.data.data.logoImage));
        }
        // console.log('Step-6')
        if (user?.userTypeId?.permission.Chats?.View) {
          connectSocket();
        }

        if (user?.type === 'superadmin') {
          connectSocket();
        }
        if (user?.type === 'student') {
          onChangePresetColor('theme8');
          onChangeMode('light');
        } else {
          onChangePresetColor('default');
        }
        // console.log('Step-7')

        dispatch({
          type: LOGIN,
          payload:
            user?.type !== 'superadmin'
              ? {
                  isLoggedIn: true,
                  isInitialized: true,
                  user,
                  organization: mySetting?.data?.data,
                  permission: response?.data?.data?.userTypeId
                }
              : {
                  isLoggedIn: true,
                  isInitialized: true,
                  user,
                  permission: response?.data?.data?.userTypeId
                }
        });
        // console.log('Step-8')
        // if(url.pathname === '/login') {
        //   // console.log('Step-9')
        //   navigate(
        //     user?.type === 'student'
        //       ? APP_STUDENT_DEFAULT_PATH
        //       : user?.type === 'faculty'
        //       ? APP_FACULTY_DEFAULT_PATH
        //       : user?.type === 'admin'
        //       ? isFirstLogin
        //         ? '/getStarted'
        //         : APP_ADMIN_DEFAULT_PATH
        //       : user?.type === 'superadmin'
        //       ? APP_ADMIN_DEFAULT_PATH
        //       : APP_DEFAULT_PATH
        //   );
        // }
      } else {
        dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
      }
    } catch (err: any) {
      Snackbar(err.response?.data?.message, 'error');
      dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
      if (err?.response?.status === 401) navigate('/login');
    }
  };

  useEffect(() => {
    init();
  }, []);

  const login = async (email: string, password: string, webFcmToken: string) => {
    /**
     * Commented code is for domain url redirection code so don't remove it.
     */
    const loginDetails = { email, password };

    // const url = new URL(window.location.href)
    // console.log('URL-->', url)
    // const currentUrl = url.origin?.split('//')?.[1]
    const response = await loginService(loginDetails);
    const userData = response?.data?.data;
    // console.log('userData==>', userData)
    // let organizationDomainUrl: string;
    // if(process.env.REACT_APP_ENVIRONMENT === 'production') {
    //   organizationDomainUrl = userData?.organizationId?.domainUrl
    // } else {
    //   organizationDomainUrl = userData?.organizationId?.domainUrl + '.localhost:3006'
    // }

    // if(currentUrl !== organizationDomainUrl && userData?.type !== 'superadmin') {
    //   // console.log('Redirecting====>', `${url.protocol}//${organizationDomainUrl}/login?${response.data.data.loginToken}`)
    //   window.location.replace(`${url.protocol}//${organizationDomainUrl}/login?st=${response.data.data.loginToken}&ifl=${userData?.isFirstLogin || false}`)
    // } else {
    //   await setSession(response.data.data.loginToken ? response.data.data.loginToken : response.data.loginToken);
    // }
    // init()
    await setSession(response.data.data.loginToken ? response.data.data.loginToken : response.data.loginToken);

    await setSession(response.data.data.loginToken ? response.data.data.loginToken : response.data.loginToken);

    // console.log('currentUrl-->', currentUrl)
    // console.log('organizationDomainUrl-->', organizationDomainUrl)

    const res = await getUserByToken();
    const user = res?.data?.data;
    let mySetting: any;

    if (userData?.type !== 'superadmin') {
      mySetting = await GetOrganizationSetting({ orgId: response.data.data.organizationId._id });
      dispatches(setSettings(mySetting.data.data));
      dispatches(setLogo(mySetting.data.data.logoImage));
    }

    if (userData?.type === 'student') {
      onChangePresetColor('theme8');
      onChangeMode('light');
    } else {
      onChangePresetColor('default');
    }

    if (user?.userTypeId?.permission.Chats?.View) {
      connectSocket();
    }

    dispatch({
      type: LOGIN,
      payload:
        user?.type !== 'superadmin'
          ? {
              isLoggedIn: true,
              isInitialized: true,
              user,
              organization: mySetting?.data?.data,
              permission: response?.data?.data?.userTypeId
            }
          : {
              isLoggedIn: true,
              isInitialized: true,
              user,
              // organization: mySetting?.data?.data,
              permission: response?.data?.data?.userTypeId
            }
    });

    navigate(
      user?.type === 'student'
        ? APP_STUDENT_DEFAULT_PATH
        : user?.type === 'faculty'
        ? APP_FACULTY_DEFAULT_PATH
        : user?.type === 'admin'
        ? userData?.isFirstLogin
          ? '/getStarted'
          : APP_ADMIN_DEFAULT_PATH
        : user?.type === 'superadmin'
        ? APP_ADMIN_DEFAULT_PATH
        : APP_DEFAULT_PATH
    );
  };

  const register = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    company: string,
    vidyalayaMigration: boolean,
    trainingMode: boolean,
    domainUrl: string,
    currentPermissions: Object
    // modulePermissions: Object,
  ) => {
    const registerReq = {
      email,
      password,
      firstName,
      lastName,
      organizationName: company,
      organizationType: 'private',
      isActive: true,
      domainUrl,
      vidyalayaMigration,
      trainingMode,
      modulePermissions: currentPermissions
    };
    let userRegister = await registerService(registerReq);
    return userRegister;
  };

  const logout = async (userId?: string) => {
    const logoutReq = { userId: state?.user?._id };
    const response = await logoutService(logoutReq);
    if (response.status === 200) {
      setSession(null);
      localStorage.setItem('tabsettings', '0');
      navigate(`/login`);
      socket?.disconnect();
      dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
    }
  };

  const tokenRemoved = () => {
    dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
  };

  const setOrganization = async (orgId: string | undefined) => {
    const mySetting = await GetOrganizationSetting({ orgId: orgId });
    dispatch({
      type: SETORGANIZATION,
      payload: {
        isLoggedIn: true,
        organization: mySetting?.data?.data
      }
    });
  };

  const resetPassword = async (email: string) => {};

  const updateProfile = async () => {
    try {
      const serviceToken = localStorage.getItem('serviceToken');
      if (serviceToken && verifyToken(serviceToken)) {
        setSession(serviceToken);

        const response = await getUserByToken();
        const user = response.data.data;
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user,
            permission: response?.data?.data?.userTypeId
          }
        });
      } else {
        dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
      }
    } catch (err: any) {
      console.error(err);
      dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
      if (err?.response?.status === 401) navigate('/login');
    }
  };
  const updateOnboardingStatus = async (data: object) => {
    try {
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: { ...state.user, onboardingStatus: data }
        }
      });
    } catch (err: any) {
      console.error(err);
      dispatch({ type: LOGOUT, payload: { isLoggedIn: false } });
      if (err?.response?.status === 401) navigate('/login');
    }
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{ ...state, login, tokenRemoved, logout, register, resetPassword, updateProfile, updateOnboardingStatus, setOrganization }}
    >
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
