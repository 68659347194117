// Utility to set a cookie
export function setCookie(name: string, value: string, days: number): void {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)};${expires};path=/`;
}

// Utility to get a cookie
export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return decodeURIComponent(parts.pop()?.split(";").shift() || '');
  return null;
}

// Utility to delete a cookie
export function deleteCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function containsSubstring(text: string, substring: string): boolean {
  // Escape special characters in the substring to match them literally
  const escapedSubstring = substring.replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&');
  
  // Create a regex with the substring
  const regex = new RegExp(escapedSubstring, 'i'); // 'i' for case-insensitive search
  return regex.test(text);
}