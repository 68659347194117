// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { AddCircle, Document, Chart21, UserSquare, Book, ClipboardText, Messages } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  icon: Document,
  icon1: Chart21,
  addIcon: AddCircle,
  userIcon: UserSquare,
  courseIcon: Book,
  assesmentIcon: ClipboardText,
  Messages: Messages
};

// ==============================|| MENU ITEMS - category ||============================== //

const report: NavItemType = {
  id: 'group-reports',
  type: 'group',
  icon: icons.icon1,
  name: 'Reports',
  children: [
    {
      icon: icons.icon1,
      id: 'reports',
      title: <FormattedMessage id="Reports" defaultMessage={'Reports'} />,
      type: 'collapse',
      children: [
        {
          id: 'Users',
          icon: icons.userIcon,
          title: <FormattedMessage id="Users" defaultMessage={'Users'} />,
          type: 'item',
          url: '/reports/user'
        },
        {
          id: 'Courses',
          icon: icons.courseIcon,
          title: <FormattedMessage id="Courses" defaultMessage={'Courses'} />,
          type: 'item',
          url: '/reports/course'
        },
        {
          id: 'Assessments',
          icon: icons.assesmentIcon,
          title: <FormattedMessage id="Assessments" defaultMessage={'Assessments'} />,
          type: 'item',
          url: '/reports/assessment'
        },
        {
          id: 'Meeting',
          icon: icons.Messages,
          title: <FormattedMessage id="Meetings" />,
          type: 'item',
          url: '/reports/meeting'
        }
      ]
    }
  ]
};

export const facultyReport: NavItemType = {
  id: 'group-reports',
  type: 'group',
  icon: icons.icon1,
  name: 'Reports',
  children: [
    {
      icon: icons.icon1,
      id: 'reports',
      title: <FormattedMessage id="Reports" />,
      type: 'collapse',
      children: [
        {
          id: 'Users',
          title: <FormattedMessage id="Users" defaultMessage={'Users'} />,
          type: 'item',
          url: '/reports/user'
        },
        {
          id: 'Courses',
          title: <FormattedMessage id="Courses" />,
          type: 'item',
          url: '/reports/course'
        },
        {
          id: 'Assessments',
          title: <FormattedMessage id="Assessments" />,
          type: 'item',
          url: '/reports/assessment'
        }
      ]
    }
  ]
};
export default report;
