import React, { useState, ReactNode, useEffect, useRef } from 'react';
import {
  Alert,
  Box,
  Button,
  Step,
  Stepper,
  StepButton,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
  styled,
  TextField,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Chip,
  Tooltip,
  Skeleton,
  Avatar,
  InputLabel,
  Stack,
  Rating,
  useTheme,
  List,
  ListItem
} from '@mui/material';
import * as Yup from 'yup';
import MainCard from 'components/MainCard';
import { CloseCircle, InfoCircle, TickCircle, Warning2 } from 'iconsax-react';
import useAuth from 'hooks/useAuth';
import Editor from '@monaco-editor/react';
import { addMisconductImage, addUserDetails, addUserImage, getExam, submitExam } from 'services/completeExam.service';
import { dispatch } from 'store';
import {
  AddAnswerMultiple,
  AddAnswerSingle,
  AddAnswerText,
  AddFillUps,
  AnswerSubmitted,
  decreaseQuestionIndex,
  examQuestionsList,
  findQuestionIndex,
  increaseQuestionIndex,
  markAsReview,
  resetQuestionIndex
} from 'store/reducers/examattend';
import { useSelector } from 'react-redux';
import CountdownTimer from './CountdownTimer';
import SubmitGIF from 'assets/images/homepage/exam_submit.gif';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTextEditor from 'pages/quiz/CustomTextEditor';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import Snackbar from 'utils/Snackbar';
import { addExamFeedback } from 'services/review.service';
import ExamRecording from './ExamRecording';
import { connectWebcamSocket, webcamSocket } from 'utils/socket';
import Webcam from 'react-webcam';
interface StepWrapperProps {
  title: any;
  children?: ReactNode;
  index: number;
  value: number;
}

const CodeEditorWrapper = styled('div')({
  position: 'relative',
  borderRadius: '8px',
  overflow: 'hidden',
  width: '100%',
  height: '85vh'
});

function StepWrapper({ title, children, value, index, ...other }: StepWrapperProps) {
  return (
    <Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      <Typography
        variant="h6"
        component="span"
        sx={{
          p: 1.2,
          lineHeight: '12px',
          border: '1px solid grey',
          borderBottom: 'none',
          borderRadius: '12px 12px 0 0'
        }}
      >
        <strong>Section: </strong>
        {title}
      </Typography>
      {value === index && (
        <MainCard sx={{ mt: 0.8, px: 0, borderTopLeftRadius: 0, border: '1px solid grey !important' }}>{children}</MainCard>
      )}
    </Box>
  );
}

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user'
};

const ExamStepper = () => {
  const location = useLocation();
  const webcamRef = useRef(null);
  const webcamModalRef = useRef(null);
  const [userReadyWithWebcam, setReadyWithWebcam] = useState(false);
  const [image, setImage] = useState(null);
  const [initialImage, setInitialImage] = useState(null);
  // const misattemptInitialCount = Number(localStorage.getItem('misattempt'));
  const [misconductImages, setMisconductImages] = useState<string[]>([]);
  const [currentMisconductImage, setCurrentMisconductImage] = useState<string | null>(null);
  const examId = location?.state?.examId;
  const timetableId = location?.state?.timetableId;
  const completeExamId = location?.state?.completeExamId;
  const startTime = location?.state?.startTime;
  const endTime = location?.state?.endTime;
  const { user }: any = useAuth();
  const validationSchema = Yup.object().shape({
    rating: Yup.number()
      .required('*Rating is required')
      .test('is-not-zero', '*Rating is required', (value) => value !== 0),
    description: Yup.string().required('*Description is required')
  });
  const reqData: any = {
    timetableId: timetableId,
    examId: examId,
    userId: user?._id,
    organizationId: user?.organizationId?._id
  };
  const navigate = useNavigate();
  if (!examId || !completeExamId) {
    navigate('/learner/timetable');
  }
  const formik: any = useFormik({
    initialValues: {
      userId: user?._id,
      examId: examId,
      organizationId: user?.organizationId?._id,
      rating: 0,
      description: ''
    },
    validationSchema,
    onSubmit: (values: any) => {
      setLoad(true);
      addExamFeedback(values)
        .then((response: any) => {
          if (response.status === 201) {
            closeFeedback();
            Snackbar('Feedback added successfully', 'success');
          } else {
            Snackbar('Error while adding the feedback', 'error');
          }
        })
        .catch((err: any) => {
          if (err?.response?.status === 403) {
            const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
            Snackbar(errorMessage, 'warning');
          } else {
            const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
            Snackbar(errorMessage, 'error');
          }
          setLoad(false);
          setTimeout(() => {
            navigate('/learner/timetable');
          }, 300000);
        });
    }
  });
  const [exam, setExam] = useState<any>();
  const [expanded, setExpanded] = useState('');
  const [alertMessage, setAlertMessage] = useState<{ isSuspicious: boolean; message: string }>();
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(false);
  const [devToolOpen, setDevToolsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [oneMinuteOver, setOneMinuteOver] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [limit, setLimit] = useState(3);
  const [totalCount, setTotalCount] = useState<any>();
  const [loadAlert, setLoadAlert] = useState(true);
  const questions = useSelector((state: any) => state?.examattend)?.questionsList;
  const currentIndex = useSelector((state: any) => state?.examattend)?.currentIndex;
  const sectionId = useSelector((state: any) => state?.examattend)?.sectionId;
  const quizDataSet = { examId: examId, orgId: user?.organizationId?._id };
  const [timerEnded, setTimerEnded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [submit, setSubmit] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  useEffect(() => {
    connectWebcamSocket();
    getExam(quizDataSet)
      .then((data: any) => {
        setExam(data?.data?.data);
        setExpanded(data?.data?.data?.examQuestions?.[0]?._id);
        setTotalCount(data?.data?.data?.totalQuestions);
        dispatch(examQuestionsList(data?.data?.data?.examQuestions));
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      webcamSocket.disconnect();
      localStorage.removeItem('currentImage');
      if (document && document?.exitFullscreen) document?.exitFullscreen();
    };
  }, []);
  //camera integration
  function checkCameraAccess() {
    return navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        // Access granted!
        stream.getTracks().forEach((track) => track.stop()); // Stop the stream
        return true;
      })
      .catch((error) => {
        // Access denied or error occurred
        console.error('Error accessing camera:', error);
        return false;
      });
  }

  const uploadBaseImage = (base64Image: string) => {
    const base64Data = base64Image.split(',')[1];

    // Convert Base64 to binary
    const binaryData = atob(base64Data);

    // Create an array buffer
    const len = binaryData.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryData.charCodeAt(i);
    }

    // Generate timestamp
    const now = new Date();
    const timestamp = now
      .toISOString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14); // 'YYYYMMDDHHMMSS'

    const formData = new FormData();
    const blob = new Blob([bytes], { type: 'image/png' });
    formData.append('userBaseImage', blob, `${timestamp}.png`);
    formData.append('currentAssessmentId', completeExamId);
    console.log('this is form data', formData);
    addUserImage(formData)
      .then((val) => {
        console.log('this is data', val);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadMisconductImage = (base64Image: string, reason: string) => {
    const base64Data = base64Image.split(',')[1];

    // Convert Base64 to binary
    const binaryData = atob(base64Data);

    // Create an array buffer
    const len = binaryData.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryData.charCodeAt(i);
    }

    // Generate timestamp
    const now = new Date();
    const timestamp = now
      .toISOString()
      .replace(/[-:.T]/g, '')
      .slice(0, 14); // 'YYYYMMDDHHMMSS'

    const formData = new FormData();
    const blob = new Blob([bytes], { type: 'image/png' });
    formData.append('misconductImage', blob, `${timestamp}.png`);
    formData.append('currentAssessmentId', completeExamId);
    reason && formData.append('reason', reason);
    console.log('this is form data', formData);
    addMisconductImage(formData)
      .then((val) => {
        console.log('this is data', val);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const captureScreenshot = () => {
    const imageSrc = initialImage;
    setImage(imageSrc);
    uploadBaseImage(imageSrc);
  };

  let timeoutId: any = null;
  useEffect(() => {
    if (timeoutId) clearTimeout(timeoutId);

    // Set a new timeout if the condition is met
    if (
      alertMessage?.isSuspicious &&
      alertMessage?.message !== 'Mobile phone detected. Alert!' &&
      alertMessage?.message !== 'Different person detected.'
    ) {
      // setCurrentMisconductImage()
      if (!loadAlert) {
        timeoutId = setTimeout(() => {
          // setPopupAlert(true);
          if (currentMisconductImage) {
            setLimit((prevLimit) => prevLimit - 1);
            setOpenDialog(true);
            uploadMisconductImage(currentMisconductImage, alertMessage?.message);
            setMisconductImages((prev: string[]) => [...prev, currentMisconductImage]);
          }
        }, 10000);
      }
    }
    const regex = /mobile|phone|different person/i;
    // console.log(regex.test(alertMessage?.message));
    if (alertMessage?.isSuspicious && regex.test(alertMessage?.message) && !loadAlert) {
      // setPopupAlert(true);

      if (currentMisconductImage) {
        setLimit((prevLimit) => prevLimit - 1);
        setOpenDialog(true);
        clearTimeout(timeoutId);
        uploadMisconductImage(currentMisconductImage, alertMessage?.message);
        setMisconductImages((prev: string[]) => [...prev, currentMisconductImage]);
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        setCurrentMisconductImage(null);
        localStorage.setItem('currentImage', '');
      }
    };
  }, [alertMessage?.isSuspicious, loadAlert]);

  // useEffect(() => {

  //   // if (misconductImages?.length > 2) {
  //   //   // setMessage('You have reached your limit for misconduct.');
  //   //   // setDone(1);
  //   //   setSubmit('misconduct');
  //   // }
  // }, [misconductImages]);

  useEffect(() => {
    if (webcamSocket && completeExamId) {
      const handleAlert = (data: any) => {
        // if (data?.message?.message === 'Looking Center' && !image) {
        //   captureScreenshot();
        // }
        if (data.message?.isSuspicious && image) {
          setCurrentMisconductImage(localStorage.getItem('currentImage') || null);
        }
        setAlertMessage(data.message);
      };

      // Function to handle connection errors
      const handleConnectError = (err: any) => {
        console.error('Connection Error:', err.message);
      };
      webcamSocket.on('alert', handleAlert);
      webcamSocket.on('connect_error', handleConnectError);

      // Automatically capture frames every second (1000 ms) and send to the backend
      const intervalId = setInterval(() => {
        if ((image === null && webcamModalRef?.current) || webcamRef.current) {
          const currentImageSrc = image === null ? webcamModalRef?.current?.getScreenshot() : webcamRef.current?.getScreenshot();
          // console.log(webcamModalRef?.current?.getScreenshot(), 'this is screenshot');
          if (currentImageSrc) {
            localStorage.setItem('currentImage', currentImageSrc);
            setInitialImage(currentImageSrc);
            webcamSocket.emit('video_frame', {
              currentImage: currentImageSrc,
              capturedImage: image,
              type: image === null ? 'initial' : 'verify'
            }); // Send frame to backend
            // console.log('Type : ', image);
          }
        }
      }, 3000); // Sending frames every 1 second

      // Cleanup on component unmount
      return () => {
        clearInterval(intervalId);
        if (webcamSocket) {
          webcamSocket.off('alert', handleAlert);
          webcamSocket.off('connect_error', handleConnectError);
        }
      };
    }
  }, [image, completeExamId, webcamRef, webcamSocket]);

  const checkEmptyOrNot = (index: any) =>
    questions[index]?.correctAnswer && questions[index]?.correctAnswer.length > 0 && questions[index]?.correctAnswer[0] !== '';
  const handleChange = (index: any, value: any) => dispatch(AddAnswerSingle({ index, value }));
  const handleChangeFillUpsMultiple = (index: any, optionIndex: any, value: any) => dispatch(AddFillUps({ index, optionIndex, value }));
  const checkMarkAsReview = (value: any) => dispatch(markAsReview(value));
  const handleCheckChange = (index: any, value: any) => dispatch(AddAnswerMultiple({ index, value }));
  const handleChangeAnswerText = (index: any, value: any) => dispatch(AddAnswerText({ index, value }));
  const findStringRepetition = (inputString: any) => {
    const regex = / ___ /g;
    const matches = inputString.match(regex);
    return matches ? matches.length : 0;
  };
  const findSubmit = (sectionId: string, questionId: string) => {
    const foundQuestion = questions.find((question: any) => {
      return question?.sectionId === sectionId && question?.questionId === questionId;
    });
    if (foundQuestion) {
      return foundQuestion?.submit;
    }
    return false;
  };
  const findReview = (sectionId: string, questionId: string) => {
    const foundQuestion = questions.find((question: any) => {
      return question?.sectionId === sectionId && question?.questionId === questionId;
    });
    return foundQuestion?.review ? true : false;
  };
  const findSkippedOne: any = () => {
    const foundQuestion = questions.find((question: any) => {
      return question?.submit === undefined || question?.submit === false;
    });
    if (!foundQuestion) {
      return null;
    } else {
      return { index: foundQuestion?.questionIndex, data: { sectionId: foundQuestion?.sectionId, questionId: foundQuestion?.questionId } };
    }
  };
  const findMarkedOne: any = () => {
    const foundQuestion = questions.find((question: any) => {
      return question?.review === true;
    });
    if (!foundQuestion) {
      return null;
    } else {
      return { index: foundQuestion?.questionIndex, data: { sectionId: foundQuestion?.sectionId, questionId: foundQuestion?.questionId } };
    }
  };
  const checkSectionCompletion = ({ questions, sectionId }: any) => {
    let completed = 0,
      pending = 0,
      total = 0,
      status = 0;
    questions.forEach((question: any) => {
      if (question.sectionId === sectionId) {
        if (question.submit === true) {
          completed += 1;
        } else {
          pending += 1;
        }
        total += 1;
      }
    });
    if (total === completed) status = 0;
    else if (pending < total) status = 1;
    else if (pending === total) status = 2;
    return status;
  };
  const findSectionWiseSubmitted = (sectionId: any) => {
    const count = questions.filter((question: any) => {
      return question?.sectionId === sectionId && question?.submit === true;
    }).length;
    return count;
  };
  const onCheck = (index: any) => {
    setError(!checkEmptyOrNot(index));
  };
  const checkAnswersCount = () => {
    let count = 0;
    questions.filter((question: any) => {
      if (question?.submit === true) {
        count++;
      }
      return count;
    });
    return count;
  };
  const onSubmit = (status: any) => {
    let index = currentIndex;
    if (questions[index]?.correctAnswer && questions[index].correctAnswer?.length > 0 && questions[index].correctAnswer[0] !== '') {
      const newReqData = {
        ...reqData,
        status: 'inprogress',
        submittedAnswer: [
          {
            sectionId: questions[index].sectionId,
            sectionName: questions[index].title,
            questionDetails: {
              questionId: questions[index].questionId,
              correctAnswer: questions[index].correctAnswer,
              answerText: questions[index].answerText
            }
          }
        ]
      };
      setIsLoading(true);
      addUserDetails(newReqData)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            dispatch(AnswerSubmitted({ index, status: true }));
            status && dispatch(increaseQuestionIndex());
          }
        })
        .catch(() => {
          setIsLoading(false);
          dispatch(AnswerSubmitted({ index, status: false }));
        });
    }
  };
  useEffect(() => {
    if (timerEnded) {
      setSubmit('timeOver');
      // onFinish(currentIndex, 'timeOver');
    }
    // eslint-disable-next-line
  }, [timerEnded]);
  useEffect(() => {
    localStorage.setItem('recordingType', '');
  }, []);
  useEffect(() => {
    setExpanded(questions?.[currentIndex]?.sectionId);
    // eslint-disable-next-line
  }, [currentIndex]);

  const onFinish: any = async (index: any, status: any) => {
    localStorage.setItem('recordingType', '');
    let statusSubmit = questions?.[index]?.submit;
    const reqData = { status: status, completeExamId: completeExamId, studentCheckedExam: true, orgId: user?.organizationId?._id };
    if (statusSubmit === true || statusSubmit === undefined) {
      setIsLoading(true);
      submitExam(reqData)
        .then((res) => {
          if (res.status === 200) {
            setDone(true);
            setFeedback(true);
            setIsLoading(false);
          } else {
            setDone(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setDone(false);
          setIsLoading(false);
          // console.error('Error submitting exam:', error);
          // Snackbar(error, 'error');
        });
      closeMessage();
    }
  };

  const handleSkipAndSubmit = () => {
    if (findSkippedOne()?.index !== currentIndex && findSkippedOne()?.index !== undefined && checkAnswersCount() !== totalCount - 1) {
      setOpen(true);
    } else {
      onSubmit(false);
      setOpen(true);
    }
  };
  const handleContinue = () => {
    findSkippedOne() && dispatch(findQuestionIndex(findSkippedOne()?.data));
    onSubmit(false);
    closeMessage();
  };
  const handleMarked = () => {
    findMarkedOne() && dispatch(findQuestionIndex(findMarkedOne()?.data));
    onSubmit(false);
    closeMessage();
  };
  const handleTimerEnd = () => {
    setTimerEnded(true);
  };
  var elem: any = document.documentElement;
  function openFullscreen() {
    if (elem?.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem?.webkitRequestFullscreen) {
      /* Safari */
      elem?.webkitRequestFullscreen();
    } else if (elem?.msRequestFullscreen) {
      /* IE11 */
      elem?.msRequestFullscreen();
    }
  }

  const checkDevTools = () => {
    const threshold = 160; // Adjust the threshold based on your needs
    const isOpen = window.outerWidth - window.innerWidth > threshold || window.outerHeight - window.innerHeight > threshold;

    return isOpen;
  };

  useEffect(() => {
    if (!done) {
      const handleVisibilityChange = () => {
        if (document.hidden === true) {
          setLimit((prevLimit) => prevLimit - 1);
          setOpenDialog(true);
        }
      };
      const handleContextMenu = (event) => {
        event.preventDefault();
      };
      const handleKeyDown = (event) => {
        // Disable F12, Ctrl+Shift+I, and Ctrl+U
        if (
          event.key === 'F12' ||
          event.key === 'F5' ||
          (event.ctrlKey && event.shiftKey && event.key === 'r') ||
          (event.ctrlKey && event.key === 'r') ||
          (event.ctrlKey && event.shiftKey && event.key === 'I') ||
          (event.ctrlKey && event.shiftKey && event.key === 'I') ||
          (event.metaKey && event.key === 'r')
        ) {
          event.preventDefault();
        }
      };
      const handleFullscreenChange = () => {
        const isCurrentlyFullscreen = !!document.fullscreenElement;
        if (!isCurrentlyFullscreen) {
          setLimit((prevLimit) => prevLimit - 1);
          setOpenDialog(true);

          if (limit === 0) {
            setSubmit('misconduct');
          }
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      document.addEventListener('fullscreenchange', handleFullscreenChange);
      document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // For Safari
      document.addEventListener('mozfullscreenchange', handleFullscreenChange); // For Firefox
      document.addEventListener('MSFullscreenChange', handleFullscreenChange); // For IE/Edge
      document.addEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
      document.addEventListener('keydown', handleKeyDown);
      setDevToolsOpen(checkDevTools());
      if (limit === 0) {
        // onFinish(currentIndex, 'misconduct');
        setSubmit('misconduct');
      }
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('fullscreenchange', handleFullscreenChange);
        document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
        document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
    // eslint-disable-next-line
  }, [limit]);

  const dialogClose = () => {
    setOpenDialog(false);
    openFullscreen();
    setAlertMessage({ isSuspicious: true, message: 'Please check your camera' });
  };
  useEffect(() => {
    if (!oneMinuteOver) {
      setTimeout(() => {
        setOneMinuteOver(true);
      }, 60000);
    }
    // eslint-disable-next-line
  }, [oneMinuteOver]);
  const HandleError = (name: any) => {
    return formik.touched[name] && formik.errors[name] ? (
      <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
        {formik.errors[name]}
      </Typography>
    ) : null;
  };
  const closeMessage = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    const type = localStorage.getItem('recordingType');
    onFinish(currentIndex, type !== '' ? type : 'misconduct');
  };
  const closeFeedback = () => {
    setFeedback(false);
    setTimeout(() => {
      navigate('/learner/timetable');
    }, 3000);
  };
  return (
    <MainCard content={false}>
      <Stack position={'fixed'} bottom={'0px'} right={'0px'} zIndex={1} visibility={!loadAlert ? 'visible' : 'hidden'}>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          minScreenshotHeight={480}
          minScreenshotWidth={640}
          width={128}
          height={96}
          videoConstraints={videoConstraints}
          // mirrored={true}
        />
        <Typography variant="h5" color={alertMessage?.isSuspicious ? 'error' : 'success'}>
          {alertMessage?.message}
        </Typography>
      </Stack>
      {/* <Dialog open={popupAlert} maxWidth={'xs'} fullWidth>
        <DialogTitle title="Caution" />
        <DialogContent>
          <Typography variant="h5">
            Misconduct. This has been recorded and if this happens more times your exam will be marked as cheet you have{' '}
            {3 - misconductImages.length} chances
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setPopupAlert(false);
              setAlertMessage({ isSuspicious: true, message: 'Please check your camera' });
            }}
          >
            ok
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={loadAlert} maxWidth={'md'} fullWidth>
        <DialogTitle title="Caution" />
        <DialogContent>
          <Typography variant="h5">
            {!userReadyWithWebcam ? (
              <Typography variant="h5">
                Instructions Please follow below instructions else you will be disqualified
                <List>
                  <ListItem>1. Do not try to go back by pressing any buttons like esc or function keys</ListItem>
                  <ListItem>2. Your webcam should remain ON through out exam</ListItem>
                  <ListItem>3. Any Electronic items are strictly prohibited if found you will lose your chance</ListItem>
                  <ListItem>4. There should be no one around you during exam if anything found you will be disqualified</ListItem>
                  <ListItem>5. You cannot remove fullScreen view else you will be disqualified</ListItem>
                  <ListItem>6. Close any window or popup if open</ListItem>
                </List>
              </Typography>
            ) : (
              <>
                <Typography variant="h5">Please Keep your head at center and webcam ON</Typography>
                <Webcam
                  audio={false}
                  ref={webcamModalRef}
                  screenshotFormat="image/jpeg"
                  minScreenshotHeight={480}
                  minScreenshotWidth={640}
                  width={320}
                  height={240}
                  videoConstraints={videoConstraints}
                  // mirrored={true}
                />
                <Typography variant="h5" color={alertMessage?.isSuspicious ? 'error' : 'success'}>
                  {alertMessage?.message}
                </Typography>
              </>
            )}
            {/* Do not try to go back by pressing any buttons like esc or function keys you will be disqualified */}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={userReadyWithWebcam && alertMessage?.isSuspicious}
            onClick={() => {
              if (devToolOpen && checkDevTools()) {
                alert('Please close extra window or dev tools');
              } else {
                if (userReadyWithWebcam) {
                  setLoadAlert(false);
                  openFullscreen();
                  captureScreenshot();
                } else {
                  checkCameraAccess().then((hasAccess) => {
                    if (hasAccess) {
                      setReadyWithWebcam(true);
                    } else {
                      Snackbar('Please give permission for camera', 'error');
                    }
                  });
                }
              }
            }}
          >
            {userReadyWithWebcam ? 'Ready' : 'Agree'}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container alignItems="center" spacing={2} sx={{ p: '8px 20px' }}>
        <Grid item xs={12} sm={6} md={9} lg={9}>
          {loading ? (
            <Skeleton variant="text" width={150} height={40} />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Typography variant="h4">Exam: {exam?.examName}</Typography>
              <ExamRecording submit={submit} completeExamId={completeExamId} handleSubmit={() => handleSubmit()} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={3}>
          {loading ? (
            <Skeleton variant="text" width={120} height={40} />
          ) : (
            <CountdownTimer startTime={startTime} endTime={endTime} onTimerEnd={handleTimerEnd} aboveText="Remaining Time" />
          )}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={3} sx={{ p: '8px 20px' }}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <div>
            {done ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Avatar alt={`Submitted`} src={SubmitGIF} variant="rounded" sx={{ width: 'auto', height: '300px' }} />
                </div>
                <Typography sx={{ textAlign: 'center', m: 2, pl: { lg: 15 }, fontWeight: 'bolder' }} variant="h4">
                  Your exam has been submitted!
                </Typography>
              </>
            ) : (
              <>
                <Grid container sx={{ pb: 1, mb: 1 }} spacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    {loading ? (
                      <Skeleton variant="text" width={150} height={40} />
                    ) : (
                      <Button
                        variant="outlined"
                        disabled={currentIndex > totalCount || currentIndex === 0}
                        onClick={() => {
                          dispatch(decreaseQuestionIndex());
                          setExpanded(questions[currentIndex]?.sectionId);
                        }}
                        sx={{
                          width: { xs: '100%', sm: 'auto' },
                          mb: { xs: 2, sm: 0 },
                          mr: { xs: 2, sm: 2 }
                        }}
                      >
                        Previous
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
                    {loading ? (
                      <Skeleton variant="text" width={150} height={40} />
                    ) : (
                      <Button
                        disabled={currentIndex >= totalCount - 1}
                        onClick={() => {
                          dispatch(increaseQuestionIndex());
                          setExpanded(questions[currentIndex]?.sectionId);
                          setError(false);
                        }}
                        sx={{
                          width: { xs: '100%', sm: 'auto' },
                          mb: { xs: 2, sm: 0 }
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        {checkEmptyOrNot(currentIndex) ? 'Next' : 'Skip'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {loading ? (
                  <div>
                    <Skeleton variant="rounded" animation="wave" height={300} width={'100%'} />
                  </div>
                ) : (
                  questions?.map((options: any, index: any) => (
                    <StepWrapper title={options?.title} value={currentIndex} index={index} key={index}>
                      <Box>
                        <Grid container>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            {options?.submit ? (
                              <Chip
                                color="success"
                                label={
                                  <>
                                    <b>Status:</b> Submitted
                                  </>
                                }
                              />
                            ) : (
                              <Chip
                                color="primary"
                                label={
                                  <>
                                    <b>Status:</b> Remaining
                                  </>
                                }
                              />
                            )}
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
                            <Chip
                              label={
                                <>
                                  <b>Mark:</b> {options?.mark}
                                </>
                              }
                              color="info"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1, display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6" component="span">
                              Question: {options?.sectionIndex + 1}
                            </Typography>
                            <Button
                              variant="contained"
                              color={options?.review ? 'success' : 'warning'}
                              size="extraSmall"
                              onClick={() => {
                                checkMarkAsReview({ index, status: options?.review ? !options?.review : true });
                              }}
                            >
                              {options?.review ? '✔' : '✖'}&nbsp;Mark as review
                            </Button>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '10px',
                            flexWrap: 'wrap',
                            userSelect: 'none',
                            pointerEvents: 'none'
                          }}
                        >
                          <Typography variant="h4">{options?.question}</Typography>
                          {error && !checkEmptyOrNot(currentIndex) && (
                            <Alert color="error" icon={<Warning2 variant="Bold" />}>
                              Please enter answer.
                            </Alert>
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            marginTop: '4px'
                          }}
                        >
                          {options?.image?.mimetype && (
                            <img
                              src={
                                options?.image?.path
                                  ? process.env.REACT_APP_FILE_URL + options?.image?.path
                                  : `data:${options?.image?.mimetype};base64,${options?.image?.file}`
                              }
                              alt="Preview"
                              height={options?.image ? '210px' : '0'}
                              style={{ borderRadius: '8px', objectFit: 'cover', marginBottom: 1 }}
                            />
                          )}
                        </div>
                      </Box>
                      <Divider sx={{ mt: 1 }} />
                      {(options?.type === 'mcq' || options?.type === 'fillInTheBlanks') &&
                        options?.multiOptions === 'mcq' &&
                        options?.optionType === 'single' && (
                          <>
                            <FormControl component="fieldset">
                              {options?.option && (
                                <RadioGroup
                                  aria-label={`question-radio-${index}`}
                                  name={`radio-buttons-group-${index}`}
                                  row
                                  value={checkEmptyOrNot(currentIndex) ? options?.correctAnswer?.[0] : ''}
                                  onChange={(event) => {
                                    handleChange(index, event.target.value);
                                  }}
                                >
                                  {options?.option.map((optionValue: any) => (
                                    <FormControlLabel
                                      key={optionValue}
                                      value={optionValue}
                                      control={<Radio />}
                                      label={optionValue}
                                      sx={{ ml: '4px', width: '100%' }}
                                    />
                                  ))}
                                </RadioGroup>
                              )}
                              {options?.otherAnswer && (
                                <TextField
                                  id={`question-text-${index}`}
                                  label="Write Different Answer"
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  sx={{ marginTop: '20px' }}
                                  onChange={(event) => handleChangeAnswerText(index, event.target.value)}
                                  value={options?.answerText || ''}
                                />
                              )}
                            </FormControl>
                          </>
                        )}
                      {(options?.type === 'mcq' || options?.type === 'fillInTheBlanks') && options?.optionType === 'multiple' && (
                        <>
                          {options?.option.map((optionValue: any, optionIndex: number) => (
                            <Box
                              key={optionValue}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                              }}
                            >
                              <Checkbox
                                color="primary"
                                checked={options?.correctAnswer?.includes(optionValue) || false}
                                onChange={(event) => handleCheckChange(index, optionValue)}
                              />
                              <p>{optionValue}</p>
                            </Box>
                          ))}
                          {options?.otherAnswer && (
                            <TextField
                              id={`question-text-${index}`}
                              label="Write Different Answer"
                              variant="outlined"
                              fullWidth
                              size="small"
                              sx={{ marginTop: '20px' }}
                              onChange={(event) => handleChangeAnswerText(index, event.target.value)}
                              value={options?.answerText || ''}
                            />
                          )}
                        </>
                      )}
                      {options?.type === 'fillInTheBlanks' && options?.multiOptions === 'fillInTheBlanks' && (
                        <>
                          {Array.from({ length: findStringRepetition(options?.question) }).map((_, optionIndex) => (
                            <TextField
                              key={`question-text-${index}-${optionIndex}`}
                              id={`question-text-${index}-${optionIndex}`}
                              label={`Fill In The Blank #${optionIndex + 1}`}
                              variant="outlined"
                              fullWidth
                              size="small"
                              sx={{ marginTop: '10px' }}
                              onChange={(event) => handleChangeFillUpsMultiple(index, optionIndex, event.target.value)}
                              value={options?.correctAnswer?.[optionIndex] || ''}
                            />
                          ))}
                        </>
                      )}
                      {options?.type === 'simple' &&
                        (options?.correctAnswer?.[0] ? (
                          <CustomTextEditor
                            index={index}
                            body={options?.correctAnswer ? options?.correctAnswer?.[0] : ''}
                            handleChange={handleChange}
                          />
                        ) : (
                          <CustomTextEditor index={index} body={''} handleChange={handleChange} />
                        ))}
                      {options?.type === 'code' && (
                        <CodeEditorWrapper sx={{ height: 'auto', mt: 1 }}>
                          <Editor
                            height="260px"
                            language={options?.selectedLanguage ? options?.selectedLanguage : 'text'}
                            value={options?.correctAnswer?.[0] || ''}
                            onChange={(value) => {
                              handleChange(index, value);
                            }}
                          />
                        </CodeEditorWrapper>
                      )}
                    </StepWrapper>
                  ))
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: { xs: 'space-between', sm: 'flex-start' },
                    alignItems: { xs: 'flex-start', sm: 'center' },
                    pt: 2,
                    width: '100%'
                  }}
                >
                  <Grid
                    container
                    spacing={3}
                    sx={{
                      '@media (max-width: 600px)': {
                        flexDirection: 'column-reverse'
                      },
                      '@media (min-width: 601px) and (max-width: 959.95px)': {
                        flexDirection: 'column-reverse'
                      }
                    }}
                  >
                    <Grid item lg={8} md={8} sm={8} xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          justifyContent: { xs: 'flex-start', sm: 'flex-start' },
                          alignItems: { xs: 'flex-start', sm: 'center' },
                          width: { xs: '100%', sm: 'auto' },
                          mb: { xs: 2, sm: 0 },
                          order: { xs: 2, sm: 0 }
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'row' }, width: { xs: '100%', sm: 'auto' } }}>
                          {loading ? (
                            <Skeleton variant="text" width={150} height={40} />
                          ) : (
                            <Button
                              onClick={() => {
                                onCheck(currentIndex);
                                checkAnswersCount() === totalCount - 1 || currentIndex >= totalCount - 1
                                  ? handleSkipAndSubmit()
                                  : onSubmit(true);
                              }}
                              disabled={
                                (!oneMinuteOver && (checkAnswersCount() >= totalCount - 1 || currentIndex >= totalCount - 1)) ||
                                isLoading ||
                                (!(checkAnswersCount() >= totalCount - 1 || currentIndex >= totalCount - 1) &&
                                  !checkEmptyOrNot(currentIndex))
                              }
                              color="success"
                              variant="contained"
                              sx={{
                                width: { xs: '100%', sm: 'auto' },
                                ml: { xs: 0, sm: 'auto' }
                              }}
                            >
                              {isLoading
                                ? 'Loading...'
                                : checkAnswersCount() >= totalCount - 1 || currentIndex >= totalCount - 1
                                ? 'Finish'
                                : 'Submit'}
                            </Button>
                          )}
                          {loading ? (
                            <Skeleton variant="text" width={150} height={40} sx={{ ml: 2 }} />
                          ) : (
                            checkEmptyOrNot(currentIndex) && (
                              <Tooltip
                                title={
                                  <span>
                                    Reset Answer: You are about to reset the answer for this question. If you choose to enter another
                                    answer, please make sure to submit again to ensure that your changes are recorded.
                                  </span>
                                }
                              >
                                <Button
                                  style={{ marginLeft: '16px' }}
                                  onClick={() => {
                                    dispatch(resetQuestionIndex(currentIndex));
                                  }}
                                  color="error"
                                  variant="outlined"
                                  sx={{
                                    width: { xs: '100%', sm: 'auto' },
                                    ml: { xs: 0, sm: 'auto' }
                                  }}
                                >
                                  Reset
                                </Button>
                              </Tooltip>
                            )
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          {loading ? (
            <Skeleton variant="rounded" height={350} width={'100%'} />
          ) : (
            <MainCard title="Total Questions" content={false}>
              <Box sx={{ p: 1 }}>
                {exam?.examQuestions?.map((questionss: any, questionsIndex: any) => (
                  <Box sx={{ mb: 2 }}>
                    <Accordion
                      sx={{
                        p: 0,
                        borderRadius: '12px'
                      }}
                      expanded={expanded === questionss?._id}
                    >
                      <AccordionSummary
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: expanded === questionss?._id ? '12px 12px 0 0' : '12px',
                          '.MuiAccordionSummary-content': { m: '0 !important' }
                        }}
                        onClick={() => {
                          setExpanded(expanded !== questionss?._id ? questionss?._id : '');
                        }}
                      >
                        <Grid container>
                          <Grid item xs={10} sm={10} md={10} lg={10}>
                            <Typography variant="h5" sx={{ pl: '4px' }}>
                              {questionss?.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} sx={{ textAlign: 'right' }}>
                            <Box>
                              {checkSectionCompletion({ questions, sectionId: questionss?._id }) === 0 && (
                                <TickCircle color={theme.palette.success.main} />
                              )}
                              {checkSectionCompletion({ questions, sectionId: questionss?._id }) === 1 && (
                                <InfoCircle color={theme.palette.warning.main} />
                              )}
                              {checkSectionCompletion({ questions, sectionId: questionss?._id }) === 2 && (
                                <CloseCircle color={theme.palette.error.main} />
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails sx={{ borderRadius: '0 0 12px 12px' }}>
                        <Stepper
                          orientation="vertical"
                          nonLinear
                          activeStep={
                            sectionId === questionss?._id && questions?.[currentIndex]?.sectionIndex
                              ? questions?.[currentIndex]?.sectionIndex
                              : questions?.[currentIndex]?.sectionIndex === 0 && sectionId === questionss?._id
                              ? 0
                              : -1
                          }
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: {
                              xl: 'repeat(5, 1fr)',
                              lg: 'repeat(5, 1fr)',
                              md: 'repeat(15, 1fr)',
                              sm: 'repeat(8, 1fr)',
                              xs: 'repeat(5, 1fr)'
                            },
                            textAlign: 'center',
                            '.MuiStepConnector-root': {
                              display: 'none'
                            }
                          }}
                        >
                          {questionss?.questionDetails.map((question: any, index: any) => (
                            <Step
                              key={index}
                              completed={findSubmit(questionss?._id, question.questionId?._id)}
                              sx={{
                                '& .MuiStepLabel-iconContainer .MuiStepIcon-root.Mui-completed': {
                                  color: !findReview(questionss?._id, question.questionId?._id)
                                    ? `${theme.palette.success.main} !important`
                                    : `${theme.palette.warning.main} !important`
                                },
                                '& .MuiStepLabel-iconContainer .MuiStepIcon-root.Mui-active': {
                                  color: `${theme.palette.primary.main} !important`
                                },
                                '& .MuiStepLabel-iconContainer .MuiStepIcon-root': {
                                  color: !findReview(questionss?._id, question.questionId?._id)
                                    ? `#BEC8D0`
                                    : `${theme.palette.warning.main}`
                                }
                              }}
                            >
                              <StepButton
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                                onClick={() => {
                                  dispatch(
                                    findQuestionIndex({
                                      sectionId: questionss?._id,
                                      questionId: question.questionId?._id
                                    })
                                  );
                                }}
                              ></StepButton>
                            </Step>
                          ))}
                        </Stepper>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
                <Box>
                  <Grid
                    container
                    sx={{
                      '& .MuiGrid-item': {
                        display: 'flex',
                        justifyContent: 'space-between'
                      },
                      '& .MuiGrid-item .Box': {
                        width: 45,
                        height: 20,
                        borderRadius: '4px'
                      }
                    }}
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
                      <Box className="Box" sx={{ backgroundColor: theme.palette.primary.main }}></Box>
                      <Typography variant="body2">Current question</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
                      <Box className="Box" sx={{ backgroundColor: '#BEC8D0' }}></Box>
                      <Typography variant="body2">Not attended</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
                      <Box className="Box" sx={{ backgroundColor: theme.palette.success.main }}></Box>
                      <Typography variant="body2">Submitted question</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12} xl={12}>
                      <Box className="Box" sx={{ backgroundColor: theme.palette.warning.main }}></Box>
                      <Typography variant="body2">Mark as review question</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </MainCard>
          )}
        </Grid>
      </Grid>
      {!done && (
        <Dialog open={openDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px', fontWeight: 'bolder' }}>
              Exam Alert
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="h5">
                  {limit > 0
                    ? 'You switched tabs or minimized the current tab or tried to cheat. You will lose the chance to attend the exam.'
                    : "Exam submitted because of your misbehavior. Don't try this next time again."}
                </Typography>
                <Typography variant="h3" sx={{ mt: 2 }}>
                  {limit === 2 && 'Remaining Chance: ' + limit}
                  {limit === 1 && 'Last Chance!'}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button variant="outlined" onClick={dialogClose}>
                Ok
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
      <Dialog open={open} onClose={closeMessage} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bolder', px: 2, py: 0.5 }}>
          <Typography variant="h4">Exam Alert</Typography>
          <Typography variant="body1">Do you want to submit the exam?</Typography>
        </DialogTitle>
        <DialogContent sx={{ px: 3, py: 1 }} dividers>
          <Grid
            container
            sx={{
              textAlign: 'center',
              '& .MuiGrid-item': {
                p: 1
              }
            }}
          >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ textAlign: 'left' }}>
              <Typography variant="h5">Section</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ textAlign: 'right' }}>
              <Typography variant="h5">Attended</Typography>
            </Grid>
            {exam?.examQuestions?.map((questionss: any, questionsIndex: any) => (
              <>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ textAlign: 'left' }}>
                  <Typography variant="body2">{questionss?.title}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ textAlign: 'right' }}>
                  <Typography variant="body2">
                    {findSectionWiseSubmitted(questionss?._id)}&nbsp;/&nbsp;{questionss?.questionDetails?.length}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {findSkippedOne() && findSkippedOne()?.index !== currentIndex && (
            <Button variant="outlined" sx={{ mx: 1 }} onClick={() => handleContinue()}>
              Submit & attend remaining
            </Button>
          )}
          <Button variant="outlined" sx={{ mx: 1 }} onClick={() => setSubmit('completed')}>
            {findSkippedOne() === null ? 'Submit' : 'Skip & submit'}
          </Button>
          {findMarkedOne() !== null && (
            <Button variant="outlined" sx={{ mx: 1 }} onClick={() => handleMarked()}>
              Check marked
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={feedback} onClose={closeFeedback} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bolder', px: 2, py: 0.5 }}>
          <Typography variant="h4">Give feedback</Typography>
          <Typography variant="body1">give rating and feedback.</Typography>
        </DialogTitle>
        <DialogContent sx={{ px: 2, py: 0.5 }} dividers>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={0.5}>
              <InputLabel>
                <FormattedMessage id={'Rating'} />
              </InputLabel>
              <Rating
                name="rating"
                value={formik.values.rating}
                onChange={(event: any, newValue: any) => {
                  formik.setFieldValue('rating', newValue);
                }}
                sx={{ mb: 1 }}
              />
              {HandleError('rating')}
            </Stack>
            <Stack spacing={0.5}>
              <InputLabel>
                <FormattedMessage id={'Description'} />
              </InputLabel>
              <TextField
                onChange={formik.handleChange}
                value={formik?.values?.description}
                multiline
                rows={3}
                name="description"
                autoComplete="off"
              />
              {HandleError('description')}
            </Stack>
            <Stack spacing={0.5} sx={{ mt: 1.6 }}>
              <Button type="submit" disabled={load} color="success" variant="contained">
                Save
              </Button>
              <Button color="error" variant="outlined" onClick={closeFeedback}>
                Close
              </Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </MainCard>
  );
};

export default ExamStepper;
