import { Button, Divider, Grid, InputLabel, Stack, Box, TextField, IconButton, FormHelperText } from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Snackbar from 'utils/Snackbar';
import useAuth from 'hooks/useAuth';
import { addContent, editContent } from 'services/landingPageManagement.service';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import { AddCircle, MinusCirlce } from 'iconsax-react';
import UploadAvatar from 'components/third-party/dropzone/Avatar';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  cards: yup
    .array()
    .of(
      yup.object({
        cardTitle: yup.string().required('Card title is required'),
        cardContent: yup.string().required('Card content is required'),
        cardImage: yup
          .mixed()
          .required('image is required')
          .test('fileType', 'Only JPG, PNG, JPEG, SVG and GIF files are allowed', (value) => {
            if (!value || value.length === 0) return false;
            const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
            return allowedFileTypes.includes(value.type || value.mimetype);
          })
      })
    )
    .min(1, 'At least one card is required')
    .max(5, 'You can only add up to 5 cards')
});

function LearningForm(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { organization } = useAuth();
  const { rowData } = location.state || {};
  const updateData = location.state?.dataset;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      ...(rowData ? { cmsId: rowData?._id } : {}),
      type: rowData?.type ? rowData?.type : undefined,
      title: rowData?.title || '',
      description: rowData?.description || '',
      organizationId: organization?._id,
      cards: rowData?.cards || [{ cardImage: rowData ? rowData.cards.cardImage : [], cardTitle: '', cardContent: '' }]
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;
      formData.append('title', values.title);
      formData.append('description', values.description);
      rowData && formData.append(`id`, rowData?._id?.toString());
      // Append cards details
      if (rowData) {
        values.cards.forEach((card: any, index: any) => {
          formData.append(`cards[${index}][cardImage]`, card.cardImage._id ? card.cardImage._id : card.cardImage);
          formData.append(`cards[${index}][cardTitle]`, card.cardTitle);
          formData.append(`cards[${index}][cardContent]`, card.cardContent);
        });
      } else {
        values.cards.forEach((card: any, index: any) => {
          formData.append(`cards[${index}][cardImage]`, card.cardImage);
          formData.append(`cards[${index}][cardTitle]`, card.cardTitle);
          formData.append(`cards[${index}][cardContent]`, card.cardContent);
        });
      }
      serviceMethod(formData, 'experience')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  const handleAddCard = () => {
    if (formik.values.cards.length < 5) {
      formik.setFieldValue('cards', [...formik.values.cards, { icon: '', cardTitle: '', cardContent: '' }]);
    }
  };

  const handleRemoveCard = (index: number) => {
    if (formik.values.cards.length > 1) {
      const updatedCards = [...formik.values.cards];
      updatedCards.splice(index, 1);
      formik.setFieldValue('cards', updatedCards);
    }
  };

  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Content' : 'Add Content'}</span>
        </span>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <>
            {/* Title and Description */}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Title</InputLabel>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>
            <Divider />
            {/* Cards Section */}
            {formik.values.cards.map((card: any, index: any) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: 4,
                  m: 2,
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px'
                }}
              >
                <Grid container spacing={3} sx={{ width: '100%' }}>
                  <Grid item xs={12} sm={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="image">Upload Image Icon</InputLabel>
                      <Stack spacing={1}>
                        <UploadAvatar
                          setFieldValue={formik.setFieldValue}
                          file={card.cardImage ? process.env.REACT_APP_FILE_URL + card?.cardImage?.path : null}
                          name={`cards[${index}].cardImage`}
                        />
                        {formik.touched.cards?.[index]?.cardImage && formik.errors.cards?.[index]?.cardImage && (
                          <FormHelperText error style={{ color: 'red' }}>
                            {formik.errors.cards?.[index]?.cardImage as string}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Card Title"
                      name={`cards[${index}].cardTitle`}
                      value={card.cardTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.cards?.[index]?.cardTitle && Boolean(formik.errors.cards?.[index]?.cardTitle)}
                      // helperText={formik.touched.cards?.[index]?.cardTitle && formik.errors.cards?.[index]?.cardTitle}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Card Content"
                      name={`cards[${index}].cardContent`}
                      value={card.cardContent}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.cards?.[index]?.cardContent && Boolean(formik.errors.cards?.[index]?.cardContent)}
                      // helperText={formik.touched.cards?.[index]?.cardContent && formik.errors.cards?.[index]?.cardContent}
                      multiline
                      rows={2}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end" spacing={2}>
                      <IconButton onClick={() => handleRemoveCard(index)} disabled={formik.values.cards.length <= 1}>
                        <MinusCirlce />
                      </IconButton>
                      <IconButton onClick={handleAddCard} disabled={formik.values.cards.length >= 5}>
                        <AddCircle />
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </>

          <Divider />

          <Grid item xs={12}>
            <Stack direction="row" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-end' }}>
              <Link to={''} onClick={() => navigate(-1)}>
                <Button variant="outlined" color="error" sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Link>
              <Button variant="contained" type="submit" disabled={isButtonDisabled}>
                {rowData ? 'Update' : 'Add'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default LearningForm;
