// project-imports

import Settings from './Settings';
import Category from './category';
// import Widget from './widget';
// import Applications from './applications';
// import FormsTables from './forms-tables';
import Groups from './groups';
import Course from './course';
import facultyCourse from './facultyCourse';
// import Branches from './Branches';
import Events from './events';
import Report, { facultyReport } from './report';
// import Offer from './offer';
import Notification from './Notification';
import { NavItemType } from 'types/menu';
import Blogs from './Blogs';
// import CertificateTemplate from './certificate';
// import EmailTemplate from './Emailtemplate';
import Quiz from './quiz';
import Badges from './badges';
// import Question from './question';
// import Discussion from './Discussion';
// import Contest from './contest';
import Access from './access';
// import AssesmentList from './assesmentList';
import Discussions from './Discussion';
import Organization from './organization';
import Packages from './Packages';
import AssignPackage from './AssignPackage';
import SuperAdminUser from './SuperAdminUser';

import Feedback from './Feedback';
import Contact from './Contact';
import Exam from './Exam';
// import TimeTable from './time-table';
import Tickets from './ticket';
import classRoom from './classroom';
// import Assignments from './assignments';

import Chat from './chats';

// import Assignments from './assignments';
import AdminUsers, { FacultyUsers } from './users';
// import Subscription from './subscription';
import Meeting from './meeting';
import Subjects from './subjects';
// import Class from './class';
import TimeTableSchedulerMenu from './timetable-scheduler';
import superAdminBlogs from './superAdminBlogs';
import SuperAdminContacts from './SuperAdminContacts';
import superAdminCMS from './superAdminCMS';
import courseSupAdmin from './courseSupAdmin';
import GettingStarted from './GettingStarted';
import LandingPage from 'pages/LandingPage';
import LandingPageManagement from './LandingPageManagement';
import Timeline from './Timeline';
import TransferStudents from './transferStudent';
import { CustomNotification, SuperAdminCustomNotification } from './CustomNotification';
import SuperAdminTickets from './SuperAdminTickets';

// import ContentManagement from './ContentManagement';
// import Pages from './pages';
// import Support from './support';
// import Mylogs from './mylogs';
// types
// import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //
interface userTypeProps {
  superadmin: Array<NavItemType>;
  admin: Array<NavItemType>;
  // adminUnApplied: Array<NavItemType>;
  student?: Array<NavItemType>;
  faculty: Array<NavItemType>;
}
const userType: userTypeProps = {
  superadmin: [
    Organization,
    SuperAdminUser,
    Packages,
    AssignPackage,
    // Tickets,
    SuperAdminTickets,
    SuperAdminContacts,
    superAdminBlogs,
    courseSupAdmin,
    superAdminCMS,
    LandingPageManagement,
    SuperAdminCustomNotification
  ],
  // adminUnApplied: [
  //   AdminUsersUnApplied,
  //   Category,
  //   Course,
  //   Groups,
  //   classRoom,
  //   Subjects,
  //   Exam,
  //   Chat,
  //   Discussions,
  //   Subscription,
  //   // Offer,
  //   Access,
  //   Events,
  //   Badges,
  //   // TimeTable,
  //   // Quiz,
  //   Meeting,
  //   // Question,
  //   // AssesmentList,
  //   Notification,
  //   // Discussion,
  //   // Contest,
  //   Blogs,
  //   // CertificateTemplate,
  //   // EmailTemplate,
  //   Tickets,
  //   Report,
  //   Feedback,
  //   Contact,
  //   Settings,
  //   GettingStarted
  // ],
  admin: [
    AdminUsers,
    TimeTableSchedulerMenu,
    Category,
    Course,
    Groups,
    classRoom,
    Subjects,
    Exam,
    Chat,
    Discussions,
    // Subscription,
    // Offer,
    Access,
    Events,
    Badges,
    // TimeTable,
    // Quiz,
    Meeting,
    // Question,
    // AssesmentList,
    Notification,
    // Discussion,
    // Contest,
    Blogs,
    // TransferStudents,
    // CertificateTemplate,
    // EmailTemplate,
    Tickets,
    Report,
    Feedback,
    Contact,
    Settings,
    GettingStarted,
    Timeline,
    CustomNotification
  ],
  // student: [Course, Report, Discussion, Settings],
  faculty: [
    FacultyUsers,
    Category,
    Course,
    Groups,
    classRoom,
    Subjects,
    Exam,
    Chat,
    Tickets,
    Events,
    // Badges,
    // Quiz,
    // Question,
    // AssesmentList,
    // Discussion,
    // Contest,
    // TimeTable,
    Discussions,
    // Contest,
    // Blogs,
    // CertificateTemplate,
    // EmailTemplate,
    facultyReport,
    Settings,
    GettingStarted,
    Timeline,
    CustomNotification
  ]
};

export default userType;
