import { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import {
  Add,
  AddCircle,
  Bag,
  Code,
  DocumentText1,
  Edit,
  Edit2,
  ExportCurve,
  Minus,
  SearchNormal1,
  TickSquare,
  Text,
  DocumentText,
  DocumentDownload
} from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { OutlinedInput } from '@mui/material';
import Snackbar from 'utils/Snackbar';
import MainCard from 'components/MainCard';
import SkeletonTable from 'components/SkeletonTable';
import Filters from 'pages/superAdmin/Filters';
import useAuth from 'hooks/useAuth';
import { createAiQuiz, deleteQuestions, getAllQuestions, importQuiz } from 'services/quiz.service';
import {
  delquestionbank,
  delquestionbankchecked,
  setquestionbankPageIndex,
  setquestionbankTableSize,
  setquestionbanksort,
  updatequestionbank,
  updatequestionbankchecked,
  updatequestionbankcount,
  updatequestionbankcreatedby,
  updatequestionbankcreatedbysearch,
  updatequestionbanksearch
} from 'store/reducers/questionbank';
import Avatar from 'components/@extended/Avatar';
import CustomTable from 'components/@extended/CustomTable';
import { delSectionQuestionDataDetails, resetExamState, updateSectionQuestionDetails } from 'store/reducers/exam';
import { FormattedMessage } from 'react-intl';
import useContemp from 'hooks/useContemp';
import { addPreferences, addquestions, editQuestion } from 'store/reducers/addQuestionsBank';
import { getAllSubjects } from 'services/subject.service';
import { getAllClassRoom } from 'services/classRoom.service';
import Loader2 from 'components/Loader2';
// import * as pdfjsLib from 'pdfjs-dist';
// Set up workerSrc to load the worker file for pdf.js
// pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { ColorRing, ProgressBar } from 'react-loader-spinner';
import ModalFilter from 'components/ModalFilter3';
import React from 'react';
import moment from 'moment';
import { downloadDemoCsv } from 'services/user.service';

const QuestionBank = ({ title, createdBys, section, usesFor }: any) => {
  const navigate = useNavigate();
  const { user, organization }: any = useAuth();
  const { pathname } = useLocation();
  let reqData = { organizationId: user?.organizationId?._id };
  const [value, setValue] = useState();
  // const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const QuestionPermissions = user?.userTypeId?.permission?.Questions;
  const pageIndex = useSelector((state: any) => state?.questionbank)?.pageIndex;
  const sortData = useSelector((state: any) => state?.questionbank)?.questionbankSort;
  const size = useSelector((state: any) => state?.questionbank)?.size;
  const questionBank = useSelector((state: any) => state?.questionbank)?.questionbank;
  const totalCount = useSelector((state: any) => state?.questionbank)?.count;
  const createdBy = useSelector((state: any) => state?.questionbank)?.questionCreatedBy;
  const questionsearch = useSelector((state: any) => state?.questionbank)?.questionsearch;
  const questionCreatedBy = useSelector((state: any) => state?.questionbank)?.createdbysearch;
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [notFound, setNotFound] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(undefined);
  const { setData, prevStoredData } = useContemp();
  const [openAiQuiz, setOpenAiQuiz] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subjectData, setSubjectData] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [subject, setSubject] = useState('');
  const [className, setClassName] = useState('');
  const [classTypeOption, setClassTypeOption] = useState([]);
  const [classType, setClassType] = useState('');
  const [classRoomId, setClassRoomId] = useState('');
  const [semester, setSemester] = useState('');
  const [otherName, setOtherName] = useState('');
  const [streamName, setStreamName] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [topic, setTopic] = useState('');
  const [contentType, setContentType] = useState('video');
  const [videoUrl, setVideoUrl] = useState('');
  const [pdfFiles, setPdfFiles] = useState<any[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [questionCount, setQuestionCount] = useState(1);
  const [disabledFilter, setDisabledFilter] = useState(true);
  const [submitFilters, setSubmitFilters] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any>(prevStoredData?.[location?.pathname]?.selectedValues || []);
  const theme = useTheme();
  const mode = theme.palette.mode;
  // const [preferences, setPreferences] = useState({});
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  // const handleApplyFilter = (value: any) => {
  //   setSumbitFilters(value);
  // };

  function capitalizeFirstLetter(str: any) {
    return str
      .split(' ')
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
  const [filterValues, setFilterValues] = useState<any>(
    prevStoredData?.[location.pathname]?.filters
      ? prevStoredData?.[location.pathname]?.filters
      : {
          search: '',
          FilterTypes: [],
          type: { names: [], indices: [] },
          startDate: null,
          endDate: null
        }
  );

  const [filterSubmitValues, setFilterSubmitValues] = useState<any>(
    prevStoredData?.[location.pathname]?.filters
      ? prevStoredData?.[location.pathname]?.filters
      : {
          search: '',
          FilterTypes: [],
          type: { names: [], indices: [] },
          startDate: null,
          endDate: null
        }
  );
  const options = ['type'];
  const [totalFilter, setTotalFilter] = useState<any>({
    search: '',
    FilterTypes: { val1: options, val2: 'checkbox', val3: {} }
  });

  const handlePreferences = (field: string, value: any) => {
    switch (field) {
      case 'classType':
        setClassType(value);
        break;
      case 'classRoomId':
        setClassRoomId(value);
        handleClassroomSelect(value);
        break;
      case 'semester':
        setSemester(value);
        handleClassroomSelect(value);
        break;
      case 'streamName':
        setStreamName(value);
        break;
      case 'otherName':
        setOtherName(value);
        handleClassroomSelect(value);
        break;
      case 'subject':
        setSubject(value);
        break;
      default:
        break;
    }
  };

  const handleClassroomSelect = (classroomId: string) => {
    const classroom: any = classrooms.find((item: any) => item._id === classroomId);
    setSubjectData(classroom?.subjects ?? []);
  };

  const handleIncrease = () => {
    setQuestionCount((prevCount) => prevCount + 1);
  };

  const handleDecrease = () => {
    if (questionCount > 1) {
      setQuestionCount((prevCount) => prevCount - 1);
    }
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 1) {
      setQuestionCount(parseInt(value));
    }
  };
  // const handlePDFFileChange = (e: any) => {
  //   const files = Array.from(e.target.files);

  //   console.log('filesssssssss', files);

  //   const updatedFiles = files.map((file: any) => {
  //     return new Promise((resolve) => {
  //       const reader = new FileReader();

  //       reader.onload = () => {
  //         const base64String = reader.result as string;

  //         const fileData = {
  //           file: base64String.split(',')[1],
  //           filename: file.name,
  //           mimetype: file.type
  //         };

  //         resolve(fileData);
  //       };

  //       reader.readAsDataURL(file);
  //     });
  //   });

  //   Promise.all(updatedFiles).then((newFiles) => {
  //     setPdfFiles((prevFiles) => [...prevFiles, ...newFiles]);
  //   });
  // };

  // const handlePreferences = (field: string, value: any) => {
  //   setPreferences((prev) => ({
  //     ...prev,
  //     [field]: value
  //   }));
  // };

  const handlePDFFileChange = (e: any) => {
    const files = Array.from(e.target.files);

    const updatedFiles = files.map((file: any) => {
      return new Promise((resolve, reject) => {
        // Validate PDF before processing
        validatePDF(file)
          .then((isValid) => {
            if (isValid) {
              const reader = new FileReader();
              reader.onload = () => {
                const base64String = reader.result as string;
                const fileData = {
                  file: base64String.split(',')[1],
                  filename: file.name,
                  mimetype: file.type
                };
                resolve(fileData);
              };
              reader.readAsDataURL(file);
            } else {
              // Reject the file if it exceeds the page limit
              reject(new Error(`${file.name} exceeds the 10-page limit.`));
            }
          })
          .catch((error) => {
            console.error('Error validating PDF:', error);
            reject(error);
          });
      });
    });

    Promise.allSettled(updatedFiles).then((results) => {
      const validFiles = results.filter((result) => result.status === 'fulfilled').map((result: any) => result.value);

      const rejectedFiles = results.filter((result) => result.status === 'rejected').map((result: any) => result.reason.message);

      if (rejectedFiles.length > 0) {
        Snackbar(rejectedFiles.join('\n'), 'error'); // Notify the user about invalid files
      }

      setPdfFiles((prevFiles) => [...prevFiles, ...validFiles]);
    });
  };

  const validatePDF = (file: File): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async function (e: any) {
        const typedArray = new Uint8Array(e.target.result);
        try {
          const pdf = await pdfjsLib.getDocument(typedArray).promise;
          const numPages = pdf.numPages;

          resolve(numPages <= 10);
        } catch (error) {
          console.error('Error reading PDF:', error);
          reject(error);
        }
      };
      fileReader.onerror = (error) => {
        console.error('File reading failed:', error);
        reject(error);
      };
      fileReader.readAsArrayBuffer(file);
    });
  };

  // const getSubjectData = () => {
  //   setIsLoading(true);
  //   getAllSubjects({ organizationId: reqData?.organizationId, limit: 100, skip: 1 })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setIsLoading(false);
  //         setSubjectData(res?.data?.data?.data);
  //         setDisabledFilter(false);
  //       } else {
  //         setIsLoading(false);
  //         Snackbar(res.data.message, 'error');
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       if (err?.response?.status === 403) {
  //         const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
  //         Snackbar(errorMessage, 'warning');
  //       } else {
  //         const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
  //         Snackbar(errorMessage, 'error');
  //       }
  //     });

  //   // getAllClassRoom(reqData).then((res) => {
  //   //   if (res.status === 200) {
  //   //     setClassTypeOption(
  //   //       res.data.data?.classRoomData?.map((item: any) => ({
  //   //         _id: item?._id,
  //   //         className: item?.className || item?.class
  //   //       }))
  //   //     );
  //   //   }
  //   // });
  // };

  useEffect(() => {
    getAllClassRoom({
      organizationId: user?.organizationId?._id,
      classType: classType ? classType : 'class',
      active: true
    }).then((res) => {
      if (res.status === 200) {
        setClassrooms(res.data.data?.classRoomData);
        // const subjects = res.data.data?.classRoomData.flatMap((item: any) => item.subjects.map((subject: any) => subject)) || [];
        // console.log(res.data.data?.classRoomData, '----------------------> res.data.data?.classRoomData')
        // setSubjectData(subjects);

        setClassTypeOption(
          res.data.data?.classRoomData?.map((item: any) => {
            return classType === 'class'
              ? { _id: item?._id, className: item?.className || item?.class, subject: item?.subject, classType: item?.classType }
              : classType === 'semester'
              ? {
                  _id: item?._id,
                  semester: item?.semester,
                  streamName: item?.streamName || item?.className,
                  subject: item?.subject,
                  classType: item?.classType
                }
              : classType === 'other'
              ? { _id: item?._id, otherName: item?.className, subject: item?.subject, classType: item?.classType }
              : {};
          })
        );
      }
    });
  }, [classType]);

  const createSkillAiQuiz = () => {
    setLoading(true);

    let quizReqData: any = {
      topic: topic,
      difficulty: difficulty,
      organizationId: reqData?.organizationId,
      numberOfQuestions: questionCount,
      questionType: type,
      classType: classType
    };

    if (classType) {
      if (classType === 'class') {
        quizReqData.classRoomId = classRoomId;
      } else if (classType === 'semester') {
        quizReqData.classRoomId = semester;
        if (semester) {
          quizReqData.classRoomId = streamName;
        }
      } else if (classType === 'other') {
        quizReqData.classRoomId = otherName;
      } else {
        quizReqData.classRoomId = classType;
      }
      if (
        (classType === 'class' && classRoomId) ||
        (classType === 'semester' && semester && streamName) ||
        (classType === 'other' && otherName)
      ) {
        const selectedSubject: any = subjectData?.find((item: { _id: string }) => item?._id === subject);
        quizReqData.subjectName = selectedSubject?.subjectName;
        quizReqData.subjectId = subject;
      }
    }

    if (contentType === 'video') {
      quizReqData.videoLink = videoUrl;
    } else quizReqData.fileData = pdfFiles;

    createAiQuiz(quizReqData)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          const aiGeneratedData = res.data.data || [];
          dispatch(addquestions(aiGeneratedData));
          // dispatch(
          //   addPreferences({
          //     subject: subject,
          //     classType: classType,
          //     classRoomId: classType === 'class' ? classRoomId : classType === 'semester' ? semester : otherName,
          //     semester: classType === 'semester' ? semester : undefined,
          //     streamName: classType === 'semester' ? streamName : undefined,
          //     otherName: classType === 'other' ? otherName : undefined,
          //     count: questionCount || 1,
          //     type: type
          //   })
          // );
          setLoading(false);
          setSubject('');
          setClassName('');
          setTopic('');
          setPdfFiles([]);
          setVideoUrl('');
          setType([]);
          setQuestionCount(0);
          navigate('/quiz/questions/add', {
            state: {
              classType: classType,
              AiPreferences: {
                subject: subject,
                classType: classType,
                classRoomId: classType === 'class' ? classRoomId : classType === 'semester' ? semester : otherName,
                semester: classType === 'semester' ? semester : undefined,
                streamName: classType === 'semester' ? streamName : undefined,
                otherName: classType === 'other' ? otherName : undefined,
                count: questionCount || 1,
                type: type
              }
            }
          });
        } else {
          setLoading(false);
          Snackbar(res.data.message, 'error');
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };
  const questionType = [
    {
      btncolor: 'warning',
      color: '#B57117',
      btnvalue: 'Mcq',
      value: 'mcq',
      icon: TickSquare
    },
    {
      btncolor: 'info',
      color: '#3EC1CE',
      btnvalue: 'Fillups',
      value: 'fill_in_the_blank',
      icon: Edit2
    },
    {
      btncolor: 'primary',
      color: '#5A84E3',
      btnvalue: 'Text',
      value: 'text',
      icon: Text
    },
    {
      btncolor: 'success',
      color: '#298B6D',
      btnvalue: 'Multiple Values',
      value: 'multiple_answer',
      icon: Code
    }
  ];

  // const [questionsCount, setQuestionsCount] = useState(0);
  const [fileError, setFileError] = useState('');
  // const handleSelectFilter = (filter: any) => {
  //   setSelectedFilter(filter);
  //   dispatch(setquestionbankPageIndex(1));
  // };
  const createdByOptions = createdBy;
  const openDeleteDialog = (questionId: any) => {
    setQuestionToDelete(questionId);
    setDeleteDialogOpen(true);
  };
  function checkPermission(createdBy: any, permission: any) {
    return permission ? (checkEnable(createdBy) ? true : false) : true;
  }
  function checkEnable(createdBy: String) {
    if (user?.type === 'admin') return false;
    if (user?.type === 'student') return true;
    if (user?.type === 'faculty') {
      if (user?._id !== createdBy) return true;
      if (user?._id === createdBy) return false;
    }
  }
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setQuestionToDelete(null);
  };
  const deleteQuestionsReq = (questionId: any) => {
    deleteQuestions({ questionId: questionId }).then((res) => {
      if (res.status === 200) {
        Snackbar('Question deleted successfully!', 'success');
        dispatch(delquestionbank(questionId));
        dispatch(updatequestionbankcount(totalCount - 1));
      } else {
        Snackbar('There was an error deleting this question!', 'error');
      }
    });
  };
  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.pageIndex === 'number')
      dispatch(setquestionbankPageIndex(prevStoredData?.[pathname]?.pageIndex));
    if (typeof prevStoredData?.[pathname]?.size === 'number') dispatch(setquestionbankTableSize(prevStoredData?.[pathname]?.size));
    return () => {
      dispatch(resetExamState());
    };
  }, []);
  const setSortingData = (data: any) => {
    const index1 = sortData && sortData.findIndex((item: any) => item.id === data.name);
    let arr = sortData ? sortData : [];
    if (sortData?.[index1]?.sort === true && sortData?.[index1]?.desc === true) {
      let arrCopy = [...sortData];
      arrCopy.splice(index1, 1);
      dispatch(setquestionbanksort(arrCopy));
    } else if (sortData?.[index1]?.sort === true) {
      let data = arr[index1];
      const newData = { ...data, desc: true };
      const newArr = [...arr];
      newArr[index1] = newData;
      dispatch(setquestionbanksort(newArr));
    } else {
      arr = [...arr, { id: data.name, sort: true, desc: false }];
      dispatch(setquestionbanksort(arr));
    }
  };
  const fetchcoursereport = async (signal?: any) => {
    setIsLoading(true);
    let sort: any = {};
    sortData &&
      sortData.length > 0 &&
      sortData.forEach((val: any) => {
        if (val.sort && val.desc) {
          sort[val.id] = -1;
        } else if (val.sort) {
          sort[val.id] = 1;
        } else {
          delete sort[val.id];
        }
      });
    let request: any = {
      organizationId: reqData?.organizationId,
      skip: pageIndex,
      limit: size,
      sort: sort,
      isExam: usesFor === 'exam' || !usesFor
    };
    if (filterValues?.search) request.search = filterValues?.search;
    if (filterValues?.startDate) request.startDate = filterValues.startDate;
    if (filterValues?.endDate) request.endDate = filterValues.endDate ? filterValues.endDate : new Date();
    if (filterValues?.subject) request.subject = filterValues?.subject?._id;
    if (filterValues?.classRoom)
      request.classRoomId = filterValues?.classRoom?._ids?.[0] ? filterValues?.classRoom?._ids?.[0] : filterValues?.classRoom;
    if (filterValues?.classType)
      request.classType = filterValues?.classType?._id ? filterValues?.classType?._ids?.[0] : filterValues?.classType;
    if (filterValues?.type?.names?.length > 0) request.type = filterValues?.type?.names;

    // if (filterValues?.createdBy?.ids?.length > 0) request.createdBy = filterValues?.createdBy?.ids;

    if (questionsearch) request.search = questionsearch;
    if (createdBys || questionCreatedBy) request.createdBy = createdBys ? createdBys : questionCreatedBy;
    if (selectedFilter) request.type = selectedFilter;
    getAllQuestions(request, signal)
      .then((data: any) => {
        setIsLoading(false);
        if (data?.status === 200) {
          setDisabledFilter(false);
          dispatch(updatequestionbank(data?.data?.data?.questionsData));
          dispatch(updatequestionbankcount(data?.data?.data?.count));
          dispatch(updatequestionbankcreatedby(data?.data?.data?.createdBy));
          if (data?.data?.data?.questionsData?.length > 0) setNotFound(false);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  console.log('disabledFilter', disabledFilter);
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setIsButtonDisabled(true);
      if (file.name.endsWith('.csv')) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const base64Data = e.target.result.split(',')[1];
          const mimetype = file.type;
          let reqBody = {
            file: base64Data,
            type: mimetype,
            name: file.name
          };
          importQuiz(reqBody)
            .then((res: any) => {
              if (res?.data?.status === 200) {
                Snackbar(res?.data?.message, 'success');
                closeDialog();
                setIsButtonDisabled(false);
              } else {
                Snackbar('CSV import failed. Please try again later.', 'error');
                setIsButtonDisabled(false);
              }
            })
            .catch((err) => {
              setIsButtonDisabled(false);
              if (err?.response?.status === 403) {
                const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
                Snackbar(errorMessage, 'warning');
              } else {
                const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
                Snackbar(errorMessage, 'error');
              }
            });
        };
        reader.readAsDataURL(file);
        setFileError('');
      } else {
        setFileError('Please select a valid CSV file.');
        Snackbar('Please select a valid CSV file.', 'error');
      }
    }
  };
  useEffect(() => {
    const localDataSetString: any = localStorage.getItem('AssessmentData');
    const localDataSet = localDataSetString && localDataSetString?.userId === user?._id ? JSON.parse(localDataSetString) : {};
    if (localDataSet?.userId && localDataSet?.userId === user?._id) {
      dispatch(updatequestionbankchecked(localDataSet.assessmentData));
    }
    // setQuestionsCount(localDataSet?.assessmentData?.length);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchcoursereport(signal);
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [pageIndex, size, sortData, questionCreatedBy, createdBys, questionsearch, selectedFilter, submitFilters]);
  const selectionColumn = {
    Header: 'Select',
    Cell: ({ row }: { row: any }) => {
      const checked = useSelector((state: any) => state?.questionbank)?.checked;
      const [change, setChange] = useState('');
      if (change === 'Yes') {
        localStorage.setItem('AssessmentData', JSON.stringify({ userId: user?._id, assessmentData: checked }));
        // setQuestionsCount(checked.length);
      }
      const isChecked = checked && checked.some((item: any) => item?._id === row?.original?._id);
      return (
        <Tooltip title="Select For Assessment">
          <span>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Checkbox
                checked={isChecked}
                onClick={() => {
                  setChange('Yes');
                  isChecked
                    ? dispatch(delquestionbankchecked(row?.original?._id))
                    : dispatch(
                        updatequestionbankchecked([
                          ...checked,
                          {
                            _id: row?.original?._id,
                            question: row?.original?.question,
                            type: row?.original?.type
                          }
                        ])
                      );
                  section >= 0 && isChecked
                    ? dispatch(delSectionQuestionDataDetails(row?.original?._id))
                    : dispatch(
                        updateSectionQuestionDetails({
                          index: section,
                          question: {
                            questionId: row?.original?._id,
                            question: row?.original?.question,
                            type: row?.original?.type
                          }
                        })
                      );
                }}
              />
            </div>
          </span>
        </Tooltip>
      );
    }
  };
  const columnData = [
    {
      Header: 'Question',
      accessor: 'question'
    },
    {
      Header: 'Type',
      Cell: ({ row }: { row: any }) => {
        let name = row.original.type;
        const getTypeColor = (type: any) => {
          switch (type) {
            case 'mcq':
              return 'warning';
            case 'fillInTheBlanks':
              name = 'FillUps';
              return 'info';
            case 'simple':
              name = 'Text';
              return 'primary';
            case 'code':
              return 'success';
            default:
              return 'secondary';
          }
        };
        const color = getTypeColor(name);
        return <Chip label={name} color={color} sx={{ textTransform: 'uppercase' }} />;
      }
    }
  ];
  const avatarColumn = {
    Header: 'CreatedBy',
    Cell: ({ row }: { row: any }) => (
      <Tooltip title={`${row?.original?.createdBy?.firstName} ${row?.original?.createdBy?.lastName}`}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar
            alt={row?.original?.createdBy?.firstName}
            size="sm"
            src={
              row?.original?.createdBy?.userProfilePicId?.path
                ? process.env.REACT_APP_FILE_URL + row?.original?.createdBy?.userProfilePicId?.path
                : row?.original?.createdBy?.userProfilePicId?.mimetype && row?.original?.createdBy?.userProfilePicId?.file
                ? `data:${row?.original?.createdBy?.userProfilePicId?.mimetype};base64,${row?.original?.createdBy?.userProfilePicId?.file}`
                : ''
            }
          />
        </div>
      </Tooltip>
    )
  };
  const optionColumn = {
    Header: 'Options',
    Cell: ({ row }: any) => {
      const navigate = useNavigate();
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}>
          <Stack direction="row" spacing={0}>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  color={'primary'}
                  disabled={checkPermission(row?.original?.createdBy?._id, QuestionPermissions?.Update)}
                  onClick={(e) => {
                    if (row?.original?._id) {
                      setData({
                        [pathname]: {
                          size,
                          pageIndex,
                          filters: filterValues,
                          selectedValues: selectedValues,
                          gotoUrl: '/quiz/questions/edit'
                        }
                      });
                      dispatch(
                        addPreferences({
                          subject: row?.original?.subject,
                          classType: row?.original?.classType,
                          classRoomId: row?.original?.classType === 'class' ? row?.original?.classRoomId?._id : undefined,
                          semester: row?.original?.classType === 'semester' ? row?.original?.classRoomId?._id : undefined,
                          streamName: row?.original?.classType === 'semester' ? row?.original?.classRoomId?._id : undefined,
                          otherName: row?.original?.classType === 'other' ? row?.original?.classRoomId?._id : undefined,
                          count: 1,
                          options: row?.original?.option?.length || 0,
                          type: row?.original?.type,
                          multiOptions: row?.original?.multiOptions,
                          optionType: row?.original?.optionType,
                          selectedLanguage: row?.original?.selectedLanguage,
                          levels: row?.original?.levels,
                          classes: row?.original?.classes
                        })
                      );
                      dispatch(editQuestion(true));
                      navigate('/quiz/questions/edit', {
                        state: {
                          questionId: row?.original?._id
                        }
                      });
                    }
                  }}
                  sx={{ display: 'block' }}
                >
                  <Edit size="32" />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  color={'error'}
                  disabled={checkPermission(row?.original?.createdBy?._id, QuestionPermissions?.Delete)}
                  onClick={() => {
                    openDeleteDialog(row.original?._id);
                  }}
                >
                  <Bag size="32" />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </div>
      );
    }
  };
  if (!title) {
    if (!createdBys) {
      columnData.push(avatarColumn);
    }
    columnData.push(optionColumn);
  } else {
    if (!createdBys) {
      columnData.push(avatarColumn);
    }
    columnData.unshift(selectionColumn);
  }
  const columns = useMemo(
    () => columnData,
    // eslint-disable-next-line
    [filterValues, selectedValues]
  );
  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(setquestionbankPageIndex(1));
    dispatch(setquestionbankTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    dispatch(setquestionbankPageIndex(page));
  };
  const setSearchOption = () => {
    if (pageIndex !== 1) {
      dispatch(setquestionbankPageIndex(1));
    }
    dispatch(updatequestionbanksearch(value));
  };
  const openDialog = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };


  const handleAutocompleteChange = (event: any, value: any) => {
    // Ensure that each value is unique
    const uniqueValues = [...new Set(value.map((data: any) => data?._id))];
    setSelectedValues(uniqueValues);

    // Your other logic here
    const _id = value.map((data: any) => data?._id).join(',');
    // setUsers(_id);
    dispatch(updatequestionbankcreatedbysearch(_id));
    if (_id.length === 0) {
      dispatch(updatequestionbankcreatedbysearch(undefined));
    }
    dispatch(setquestionbankPageIndex(1));
  };

  const handleAiQuizOpen = () => {
    setOpenAiQuiz(true);
    // getSubjectData();
  };
  const handleAiQuizClose = () => {
    setOpenAiQuiz(false);
  };

  const handleDifficultyChange = (event: any) => {
    setDifficulty(event.target.value);
  };

  const subjectId = [...new Set(subjectData?.map((item: any) => item._id))];
  const subjectName = [...new Set(subjectData?.map((item: any) => item.subjectName))];
  const typesOptions = usesFor === 'exam' || !usesFor ? ['mcq', 'fillInTheBlanks', 'simple', 'code'] : ['mcq', 'fillintheblanks'];
  const classTypeOptions = ['class', 'semester', 'other'];

  const setModalFilters = (key: string, value: any, isFromDelete?: boolean) => {
    if (key === 'FilterTypes') {
      setFilterValues((prev: any) => {
        const currentValue = prev[key] || [];
        let updatedArray;

        if (currentValue.includes(options[value])) {
          updatedArray = currentValue.filter((item: any) => item !== options[value]);
        } else {
          updatedArray = [...currentValue, options[value]];
        }

        if (isFromDelete) {
          setFilterSubmitValues({
            ...prev,
            [key]: updatedArray,
            // subject: key === 'FilterTypes' && updatedArray.includes('subject') ? prev.subject : { ids: [], names: [], indices: [] },
            type: key === 'FilterTypes' && updatedArray.includes('type') ? prev.type : { names: [], indices: [] }
          });
        }

        return {
          ...prev,
          [key]: updatedArray,
          // subject: key === 'FilterTypes' && updatedArray.includes('subject') ? prev.subject : { ids: [], names: [], indices: [] },
          type: key === 'FilterTypes' && updatedArray.includes('type') ? prev.type : { names: [], indices: [] }
          // classType: key === 'FilterTypes' && updatedArray.includes('classType') ? prev.classType : { semester: '', class: '', other: '' }
        };
      });
    }
    //  else if (key === 'classType') {
    //   setFilterValues((prev: any) => ({ ...prev, [key]: value, semester: '', class: '', other: '' }));
    // }
    // else if (key === 'classType') {
    //   setFilterValues((prev: any) => {
    //     const currentNames = prev[key]?.names || [];
    //     const currentIndices = prev[key]?.indices || [];
    //     let updatedNames;
    //     let updatedIndices;

    //     const selectedType = classTypeOptions[value];

    //     if (currentNames.includes(selectedType)) {
    //       updatedNames = currentNames.filter((item: any) => item !== selectedType);
    //       updatedIndices = currentIndices.filter((item: any) => item !== value);
    //     } else {
    //       updatedNames = [...currentNames, selectedType];
    //       updatedIndices = [...currentIndices, value];
    //     }

    //     return {
    //       ...prev,
    //       [key]: { names: updatedNames, indices: updatedIndices }
    //     };
    //   });
    // }
    // else if (key === 'subject') {
    //   setFilterValues((prev: any) => {
    //     const currentIds = prev[key]?.ids || [];
    //     const currentNames = prev[key]?.names || [];
    //     const currentIndices = prev[key]?.indices || [];

    //     let updatedIds;
    //     let updatedNames;
    //     let updatedIndices;

    //     if (currentIds.includes(subjectId[value]) || currentNames.includes(subjectName[value]) || currentIndices.includes(value)) {
    //       updatedIds = currentIds.filter((item: any) => item !== subjectId[value]);
    //       updatedNames = currentNames.filter((item: any) => item !== subjectName[value]);
    //       updatedIndices = currentIndices.filter((item: any) => item !== value);
    //     } else {
    //       updatedIds = [...currentIds, subjectId[value]];
    //       updatedNames = [...currentNames, subjectName[value]];
    //       updatedIndices = [...currentIndices, value];
    //     }

    //     return {
    //       ...prev,
    //       [key]: { ids: updatedIds, names: updatedNames, indices: updatedIndices }
    //     };
    //   });
    // }
    else if (key === 'type') {
      setFilterValues((prev: any) => {
        const currentNames = prev[key]?.names || [];
        const currentIndices = prev[key]?.indices || [];
        let updatedNames;
        let updatedIndices;

        const selectedType = typesOptions[value];

        if (currentNames.includes(selectedType)) {
          updatedNames = currentNames.filter((item: any) => item !== selectedType);
          updatedIndices = currentIndices.filter((item: any) => item !== value);
        } else {
          updatedNames = [...currentNames, selectedType];
          updatedIndices = [...currentIndices, value];
        }

        if (isFromDelete) {
          setFilterSubmitValues({
            ...prev,
            [key]: { names: updatedNames, indices: updatedIndices }
          });
        }

        return {
          ...prev,
          [key]: { names: updatedNames, indices: updatedIndices }
        };
      });
    } else {
      if (isFromDelete) {
        setFilterSubmitValues((prev: any) => ({ ...prev, [key]: value }));
      }
      setFilterValues((prev: any) => ({ ...prev, [key]: value }));
    }
    // dispatch(setUserPageIndex(1));
  };

  // const getClassRoomData = async () => {
  //   return getAllClassRoom({
  //     organizationId: user?.organizationId?._id,
  //     classType: filterValues?.classType,
  //     active: true
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         return res.data.data?.classRoomData?.map((item: any) => {
  //           return filterValues?.classType === 'class'
  //             ? { _id: item?._id, className: item?.className || item?.class, classType: item?.classType }
  //             : filterValues?.classType === 'semester'
  //             ? {
  //                 _id: item?._id,
  //                 className: item?.semester,
  //                 classType: item?.classType
  //               }
  //             : filterValues?.classType === 'other'
  //             ? { _id: item?._id, className: item?.className, classType: item?.classType }
  //             : {};
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err?.response?.status === 403) {
  //         const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
  //         Snackbar(errorMessage, 'warning');
  //       } else {
  //         const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
  //         Snackbar(errorMessage, 'error');
  //       }
  //     });
  // };

  // console.log('filterValues', filterValues);

  useEffect(() => {
    // if (filterValues.FilterTypes.classType === 'class') {
    //   getClassRoomData().then((res) => {
    //     setTotalFilter((prev: any) => ({
    //       ...prev,
    //       class: { val1: res, val2: 'radio' }
    //     }));
    //   });
    // } else if (filterValues?.classType === 'semester') {
    //   getClassRoomData().then((res) => {
    //     setTotalFilter((prev: any) => ({
    //       ...prev,
    //       semester: { val1: res, val2: 'radio' }
    //     }));
    //   });
    // } else if (filterValues?.classType === 'other') {
    //   getClassRoomData().then((res) => {
    //     setTotalFilter((prev: any) => ({
    //       ...prev,
    //       other: { val1: res, val2: 'radio' }
    //     }));
    //   });
    // } else {
    //   setTotalFilter((prev: any) => {
    //     const { createdBy: classKey, ...rest } = prev;
    //     return rest;
    //   });
    // }
    // if (filterValues.FilterTypes.includes('classType')) {
    //   setTotalFilter((prev: any) => ({
    //     ...prev,
    //     classType: { val1: classTypeOptions, val2: 'radio' }
    //   }));
    // } else {
    //   setTotalFilter((prev: any) => {
    //     const { classType: classKey, ...rest } = prev;
    //     return rest;
    //   });
    // }
    // if (filterValues.FilterTypes.includes('subject')) {
    //   setTotalFilter((prev: any) => ({
    //     ...prev,
    //     subject: { val1: subjectName, val2: 'checkbox', val3: subjectId }
    //   }));
    // } else {
    //   setTotalFilter((prev: any) => {
    //     const { subject: classKey, ...rest } = prev;
    //     return rest;
    //   });
    // }
    if (filterValues.FilterTypes.includes('type')) {
      setTotalFilter((prev: any) => ({
        ...prev,
        type: { val1: typesOptions, val2: 'checkbox' }
      }));
    } else {
      setTotalFilter((prev: any) => {
        const { type: classKey, ...rest } = prev;
        return rest;
      });
    }
  }, [filterValues]);

  const handleDownload = async () => {
    try {
      const blob = await downloadDemoCsv({ organizationId: user?.organizationId?._id });
      const url = window.URL.createObjectURL(blob?.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'demo.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainCard
      // title={title ? String(title) : 'Question Bank'}
      title={
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">
            <FormattedMessage id={title ? String(title) : 'Question Bank'} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="List of questions" />
          </Typography>
        </span>
      }
      content={false}
      sx={{ textTransform: 'capitalize' }}
      secondary={
        <Stack direction={'row-reverse'} spacing={2}>
          <Stack>
            {!title && (
              <Tooltip title="Add Questions">
                <span>
                  <IconButton
                    disabled={!QuestionPermissions?.Create}
                    variant="dashed"
                    color="primary"
                    onClick={() => {
                      navigate('/quiz/questions/add');
                      dispatch(editQuestion(false));
                    }}
                  >
                    <AddCircle />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
          <Stack>
            {!title && (
              <Tooltip title="Upload CSV">
                <span>
                  <Button variant="outlined" color="primary" onClick={openDialog} sx={{ p: '9px', minWidth: 'auto' }}>
                    <ExportCurve />
                  </Button>
                </span>
              </Tooltip>
            )}
          </Stack>{' '}
          {/* <Stack sx={{ alignItems: 'center' }}>
            <Filters
              Filters={usesFor === 'exam' || !usesFor ? ['mcq', 'fillintheblanks', 'simple', 'code'] : ['mcq', 'fillintheblanks']}
              Fields={
                usesFor === 'exam' || !usesFor ? ['mcq based', 'fillup based', 'text based', 'code based'] : ['mcq based', 'fillup based']
              }
              handleSelect={handleSelectFilter}
            />
          </Stack> */}
          <Stack sx={{ mt: 1 }}>
            <Tooltip title={<FormattedMessage id="filter" />}>
              <span>
                <ModalFilter
                  filterKeywords={totalFilter}
                  onFilterKeywordChange={setModalFilters}
                  count={totalCount}
                  applyFilter={setSubmitFilters}
                  filterFlag={submitFilters}
                  values={filterValues}
                  filterValuesSet={setFilterValues}
                  setFilterSubmitValues={setFilterSubmitValues}
                  submittedFilterValues={filterSubmitValues}
                  disabled={disabledFilter}
                  setDisabled={setDisabledFilter}
                  pageIndex={setquestionbankPageIndex}
                />
              </span>
            </Tooltip>
          </Stack>
          {!title && (
            <Stack>
              <Button
                onClick={() => {
                  handleAiQuizOpen();
                }}
                variant="contained"
                sx={{
                  backgroundColor: '#13AC9E',
                  p: 0.9,
                  '&:hover': {
                    backgroundColor: '#13AC9E',
                    opacity: 0.8
                  }
                }}
              >
                Generate AI Quiz
              </Button>
            </Stack>
          )}
        </Stack>
      }
    >
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'} sx={{ my: 1 }}>
        <Stack display={'flex'} sx={{ mx: 1 }} flexDirection={'row'} flexWrap={'wrap'}>
          {filterValues?.type?.names?.length > 0 ||
          filterValues?.search ||
          filterValues?.startDate ||
          filterValues?.subject?.names?.length > 0 ||
          filterValues?.classType?.length > 0 ||
          filterValues?.classRoomId ? (
            <Button
              variant="outlined"
              onClick={() => {
                setSubmitFilters(!submitFilters);
                setFilterValues({
                  search: '',
                  FilterTypes: [],
                  type: { names: [], indices: [] },
                  subject: { names: [], indices: [] },
                  classRoomId: '',
                  classType: { names: [], indices: [] },
                  startDate: null,
                  endDate: null
                });
                setFilterSubmitValues({
                  search: '',
                  FilterTypes: [],
                  type: { names: [], indices: [] },
                  subject: { names: [], indices: [] },
                  classRoomId: '',
                  classType: { names: [], indices: [] },
                  startDate: null,
                  endDate: null
                });
              }}
              sx={{ m: 1 }}
            >
              Clear All
            </Button>
          ) : (
            <></>
          )}
          {/* 
          {Object.entries<any>(filterValues).map(([key, values]: [string, any]) =>
            (Array.isArray(values) ? values : [values]).map(
              (value: any, index: number) =>
                value &&
                value !== undefined &&
                value !== null &&
                value?.names?.length !== 0 && (
                  <>
                    {value?.names && value?.names !== '' ? (
                      <div
                        key={`${key}-${index}`}
                        style={{
                          border: '1px solid gray',
                          margin: '2px',
                          borderRadius: '15px',
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor:
                            key === 'subject' && mode !== 'dark' ? '#F4F7FF' : key === 'type' && mode !== 'dark' ? '#F8F9FA' : 'transparent'
                        }}
                      >
                        <Typography mx={1} mt={1.5}>
                          {' '}
                          <b>{capitalizeFirstLetter(key)}:</b>{' '}
                        </Typography>
                        {value?.names
                          ?.filter((name: string) => name)
                          .map((name: string, nameIndex: number) => (
                            <Chip
                              key={`${key}-${index}-${nameIndex}`}
                              sx={{ my: 1, mx: 0.3 }}
                              label={name}
                              color={key === 'classRoomId' ? 'primary' : 'secondary'}
                              style={{ color: key !== 'classRoomId' && mode === 'dark' ? 'black' : 'white' }}
                              onDelete={() => {
                                if (key === 'FilterTypes' || key === 'classRoomId' || key === 'subject' || key === 'type') {
                                  setSubmitFilters(!submitFilters);
                                  setModalFilters(key, value?.indices?.[nameIndex], true);
                                } else {
                                  setModalFilters(key, '');
                                }
                              }}
                            />
                          ))}
                      </div>
                    ) : (
                      <div
                        key={`${key}-${index}`}
                        style={{
                          border: '1px solid gray',
                          margin: '2px',
                          borderRadius: '15px',
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor:
                            key === 'subject' && mode !== 'dark' ? '#F4F7FF' : key === 'type' && mode !== 'dark' ? '#F8F9FA' : 'transparent'
                        }}
                      >
                        <Typography mx={1} mt={1.5}>
                          {' '}
                          <b>{capitalizeFirstLetter(key)}:</b>{' '}
                        </Typography>
                        {value?.map((name: string, nameIndex: number) => (
                          <Chip
                            key={`${key}-${index}-$`}
                            sx={{ my: 1, mx: 0.3 }}
                            label={values}
                            color={key === 'classRoomId' ? 'primary' : 'secondary'}
                            style={{ color: key !== 'classRoomId' && mode === 'dark' ? 'black' : 'white' }}
                            onDelete={() => {
                              if (key === 'FilterTypes' || key === 'classRoomId' || key === 'subject' || key === 'type') {
                                setSubmitFilters(!submitFilters);
                                setModalFilters(key, value?.indices?.[index], true);
                              } else {
                                setModalFilters(key, '');
                              }
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </>
                )
            )
          )} */}

          {Object.entries<any>(filterValues).map(([key, values]: [string, string[] | string]) =>
            (Array.isArray(values) ? values : [values]).map(
              (value: any, index: number) =>
                value &&
                value !== undefined &&
                value !== null &&
                value?.names?.length !== 0 && (
                  <>
                    {value?.names ? (
                      <div
                        key={`${key}-${index}`}
                        style={{
                          border: '1px solid gray',
                          margin: '2px',
                          borderRadius: '15px',
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor:
                            key === 'type' && mode !== 'dark' ? '#F4F7FF' : key === 'subject' && mode !== 'dark' ? '#F8F9FA' : 'transparent'
                        }}
                      >
                        <Typography mx={1} mt={1.5}>
                          {' '}
                          <b>{capitalizeFirstLetter(key)}:</b>{' '}
                        </Typography>
                        {value?.names?.map((name: string, nameIndex: number) => (
                          <Chip
                            key={`${key}-${index}-${nameIndex}`}
                            sx={{ my: 1, mx: 0.3 }}
                            label={name}
                            color={key === 'type' ? 'primary' : 'secondary'}
                            style={{ color: key !== 'type' && mode === 'dark' ? 'black' : 'white' }}
                            onDelete={() => {
                              if (key === 'FilterTypes' || key === 'type' || key === 'subject') {
                                setSubmitFilters(!submitFilters);
                                setModalFilters(key, value?.indices?.[nameIndex], true);
                              } else {
                                setModalFilters(key, '');
                              }
                            }}
                          />
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )
            )
          )}

          {filterValues.startDate || filterValues.endDate ? (
            <div
              style={{
                border: '1px solid gray',
                margin: '2px',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <React.Fragment>
                <Typography mx={1} mt={1.5}>
                  <b>Range:</b>
                </Typography>
                <Chip
                  label={`${moment(filterValues.startDate).format('DD-MM-YYYY')} to ${moment(filterValues.endDate || new Date()).format(
                    'DD-MM-YYYY'
                  )}`}
                  color="primary"
                  sx={{ m: 1 }}
                  onDelete={() => {
                    setSubmitFilters(!submitFilters);
                    setModalFilters('startDate', null);
                    setModalFilters('endDate', null);
                    setModalFilters('type', null);
                    setModalFilters('subject', '');
                    setModalFilters('classRoomId', '');
                    setModalFilters('FilterTypes', []);
                  }}
                />
              </React.Fragment>
            </div>
          ) : null}

          {filterValues.search ? (
            <div
              style={{
                border: '1px solid gray',
                margin: '2px',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <React.Fragment>
                <Typography mx={1} mt={1.5}>
                  <b>Search:</b>
                </Typography>
                <Chip
                  label={filterValues.search}
                  color="primary"
                  sx={{ m: 1 }}
                  onDelete={() => {
                    setSubmitFilters(!submitFilters);
                    setModalFilters('search', '');
                  }}
                />
              </React.Fragment>
            </div>
          ) : null}
        </Stack>
      </Stack>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
            <b>Delete Question</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Question ?</DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={closeDeleteDialog}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                deleteQuestionsReq(questionToDelete);
                closeDeleteDialog();
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle textAlign={'center'}>Upload CSV File</DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ overflow: 'hidden' }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <strong>Instruction</strong>
              <Button variant="contained" size="small" onClick={handleDownload} startIcon={<DocumentDownload />}>
                Demo File
              </Button>
            </Stack>
            <Box
              sx={{
                overflowY: 'auto',
                maxHeight: '300px',
                overflow: 'auto',
                position: 'relative',
                scrollbarWidth: 'thin',
                scrollbarColor: 'transparent transparent',
                '&::-webkit-scrollbar': { width: '4px', borderRadius: '3px', marginRight: '8px' },
                '&::-webkit-scrollbar-track': { background: 'transparent' },
                '&::-webkit-scrollbar-thumb': {
                  background: 'transparent',
                  transition: 'background-color 0.3s ease-in-out',
                  borderRadius: '3px'
                },
                '&::-webkit-scrollbar-thumb:hover': { background: '#888' },
                '&::-webkit-scrollbar-corner': { backgroundColor: 'transparent' },
                '&:hover::-webkit-scrollbar-thumb': { background: '#888' }
              }}
            >
              <ol>
                <li>
                  <strong>Add Questions by uploading CSV file from here.</strong>
                </li>
                <li>
                  <strong>The file must be in .csv format.</strong>
                </li>
                <li>
                  <strong>The file will be uploaded immediately after selecting it.</strong>
                </li>
                <li>
                  <strong>Require fields</strong>
                  <ul>
                    <li>question</li>
                    <li>type</li>
                    <li>option</li>
                    <li>selectedLanguage</li>
                    <li>optionType</li>
                    <li>answerText</li>
                    <li>correctAnswer</li>
                  </ul>
                </li>
              </ol>
            </Box>
          </Box>
          {fileError && (
            <Typography variant="body2" color="error">
              {fileError}
            </Typography>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={closeDialog} variant="outlined" color="error" style={{ marginRight: '5px' }}>
            Cancel
          </Button>
          <input
            type="file"
            id="csv-file-input"
            name="originalname"
            accept=".csv"
            style={{ display: 'none', marginRight: '5px' }}
            onChange={handleFileChange}
            disabled={isButtonDisabled}
          />
          <label htmlFor="csv-file-input">
            <Button variant="contained" component="span" color="primary" disabled={isButtonDisabled}>
              Choose File
            </Button>
          </label>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAiQuiz}
        onClose={handleAiQuizClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="dialog-title">AI Quiz Generate</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ minWidth: '100px' }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="type">Select Difficulty level</InputLabel>
                <Select fullWidth id="type" name="type" value={difficulty} onChange={handleDifficultyChange}>
                  <MenuItem value="easy">Easy</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="hard">Hard</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              <RadioGroup aria-label="size" name="radio-buttons-group" value={classType} row>
                <FormControlLabel
                  value="class"
                  control={<Radio />}
                  label="Class"
                  disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'class' : false}
                  onClick={(e: any) => {
                    if (classType !== e.target.value) handlePreferences('classType', e.target.value);
                  }}
                />
                <FormControlLabel
                  value="semester"
                  control={<Radio />}
                  label="Semester"
                  disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'semester' : false}
                  onClick={(e: any) => {
                    if (classType !== e.target.value) handlePreferences('classType', e.target.value);
                  }}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                  disabled={user?.organizationId?.allowClassTypes ? user?.organizationId?.allowClassTypes !== 'other' : false}
                  onClick={(e: any) => {
                    if (classType !== e.target.value) handlePreferences('classType', e.target.value);
                  }}
                />
              </RadioGroup>
            </Grid>

            {classType === 'class' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="classRoomId">Classroom Name:</InputLabel>
                  <Select
                    fullWidth
                    id="classRoomId"
                    value={classRoomId}
                    name="classRoomId"
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value as string[];
                      if (selectedValues?.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('classRoomId', selectedValues);
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                      <Add />
                      <FormattedMessage id="Add Class" />
                    </MenuItem>
                    {classTypeOption?.map((value: any, index: number) => (
                      <MenuItem key={index} value={value._id}>
                        {value.className}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}

            {classType === 'semester' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="semester">Semester Name:</InputLabel>
                  <Select
                    fullWidth
                    id="semester"
                    value={semester}
                    name="semester"
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('semester', selectedValues);
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                      <Add />
                      <FormattedMessage id="Add Semester" />
                    </MenuItem>
                    {classTypeOption?.map((value: any) => (
                      <MenuItem key={value._id} value={value._id}>
                        {value.semester}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}
            {classType === 'semester' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="streamName">Learner's stream name:</InputLabel>
                  <Select
                    fullWidth
                    id="streamName"
                    value={streamName}
                    name="streamName"
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('streamName', selectedValues);
                      }
                    }}
                    MenuProps={MenuProps}
                  >
                    {/* <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                            <Add />
                            <FormattedMessage id="Add streamName" />
                          </MenuItem> */}
                    {classTypeOption?.map((value: any) => {
                      if (semester === value._id) return <MenuItem value={value._id}>{value.streamName}</MenuItem>;
                    })}
                  </Select>
                </Stack>
              </Grid>
            )}
            {classType === 'other' && (
              <Grid item xs={6}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="otherName">Learner's other classType:</InputLabel>
                  <Select
                    labelId="otherName"
                    fullWidth
                    id="otherName"
                    value={otherName || ''}
                    onChange={(event: SelectChangeEvent<any>) => {
                      const selectedValues = event.target.value;
                      if (selectedValues.includes('custom')) {
                        navigate('/classroom/add');
                      } else {
                        handlePreferences('otherName', selectedValues);
                      }
                    }}
                  >
                    <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                      <Add />
                      <FormattedMessage id="Add other classType" />
                    </MenuItem>
                    {classTypeOption?.map((value: any) => (
                      <MenuItem key={value._id} value={value._id}>
                        {value.otherName}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
            )}

            {classType &&
              ((classType === 'class' && classRoomId) ||
                (classType === 'semester' && semester && streamName) ||
                (classType === 'other' && otherName)) && (
                <Grid item xs={12}>
                  <Stack>
                    <InputLabel htmlFor="Subject">Subject:</InputLabel>
                    <Select
                      name="subject"
                      id="subject"
                      value={subject}
                      onChange={(event) => {
                        if (event.target?.value?.includes('custom')) {
                          navigate('/subject/add');
                        } else {
                          handlePreferences('subject', event.target.value);
                        }
                      }}
                      fullWidth
                    >
                      <MenuItem value="custom" sx={{ fontWeight: 'bold' }}>
                        <Add />
                        <FormattedMessage id="Add Subject" />
                      </MenuItem>
                      {subjectData?.map((item: any) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.subjectName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
              )}

            <Grid item xs={6} sm={6}>
              <Stack spacing={1.25}>
                <InputLabel htmlFor="topic">
                  <FormattedMessage id="Topic" />
                </InputLabel>
                <TextField
                  fullWidth
                  id="topic"
                  placeholder={'Enter Topic..'}
                  value={topic}
                  onChange={(event: any) => {
                    const selectedValues = event.target.value;
                    setTopic(selectedValues);
                  }}
                />
              </Stack>
            </Grid>

            {/* Content Type Selection */}
            <Grid item xs={6}>
              <Stack spacing={1.25}>
                <InputLabel>Content Type</InputLabel>
                <RadioGroup row value={contentType} onChange={(e) => setContentType(e.target.value)}>
                  <FormControlLabel value="video" control={<Radio />} label="Video URL" />
                  <FormControlLabel value="pdf" control={<Radio />} label="PDF Upload" />
                </RadioGroup>
              </Stack>
            </Grid>

            {/* Conditional rendering based on contentType */}
            {contentType === 'video' && (
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="videoUrl">Video URL</InputLabel>
                  <TextField
                    fullWidth
                    id="videoUrl"
                    value={videoUrl}
                    onChange={(e) => setVideoUrl(e.target.value)}
                    placeholder="Enter video URL"
                  />
                </Stack>
              </Grid>
            )}
            {contentType === 'pdf' && (
              <Grid item xs={6}>
                <Stack spacing={2}>
                  {/* PDF Upload Button */}
                  <Stack spacing={1}>
                    <InputLabel htmlFor="pdfUpload">Upload PDF(s)</InputLabel>
                    <Button variant="contained" component="label">
                      Upload File(s)
                      <input type="file" hidden accept=".pdf" multiple onChange={handlePDFFileChange} />
                    </Button>
                  </Stack>

                  {/* Uploaded Files */}
                  {pdfFiles.length > 0 && (
                    <Stack spacing={2} direction="column">
                      {pdfFiles.map((file, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                          <DocumentText color="primary" />
                          <Typography variant="body2" sx={{ ml: 1 }}>
                            {file.filename}
                          </Typography>
                        </Box>
                      ))}
                    </Stack>
                  )}
                  {pdfFiles?.length > 0 && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setPdfFiles([]);
                      }}
                    >
                      Clear All Files
                    </Button>
                  )}
                </Stack>
              </Grid>
            )}
            <Grid item xs={6} sm={6} sx={{ py: 1 }}>
              <Stack spacing={1.25} direction="row" alignItems="center">
                <InputLabel htmlFor="questionCount">No of Questions</InputLabel>

                <IconButton onClick={handleDecrease} disabled={questionCount <= 1}>
                  <Minus />
                </IconButton>

                <TextField
                  id="questionCount"
                  type="number"
                  value={questionCount}
                  onChange={handleInputChange}
                  inputProps={{
                    min: 1,
                    style: { textAlign: 'center' }
                  }}
                  sx={{ maxWidth: '80px' }}
                />

                <IconButton onClick={handleIncrease}>
                  <Add />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} sx={{ py: 1 }}>
              <Stack spacing={1.25}>
                <Typography>Select the question type:</Typography>
                <Select
                  name="type"
                  id="type"
                  multiple
                  value={type}
                  onChange={(event: any) => {
                    const selectedValues = event.target.value;
                    setType(typeof selectedValues === 'string' ? selectedValues.split(',') : selectedValues);
                  }}
                  fullWidth
                  renderValue={(selected: any) =>
                    selected.map((val: any) => questionType.find((t) => t.value === val)?.btnvalue).join(', ')
                  }
                  sx={{ my: 1, '& .MuiSelect-select': { p: '9px 40px 9px 9px !important' } }}
                >
                  {questionType.map((typeItem: any) => (
                    <MenuItem key={typeItem.value} value={typeItem.value}>
                      <Checkbox checked={type.includes(typeItem.value)} />
                      <Button variant="outlined" color={typeItem.btncolor} size="small">
                        <typeItem.icon variant="Bold" color={typeItem.color} />
                        &nbsp;{typeItem.btnvalue} Based Answer
                      </Button>
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleAiQuizClose}>
            Close
          </Button>
          <Button
            variant="contained"
            disabled={
              (classType === 'class' ? !classRoomId : classType === 'semester' ? !streamName : !otherName) ||
              !subject ||
              !topic ||
              !difficulty ||
              (contentType === 'pdf' ? pdfFiles?.length === 0 : !videoUrl) ||
              !questionCount ||
              !type
            }
            color="primary"
            onClick={async () => {
              handleAiQuizClose();
              createSkillAiQuiz();
            }}
          >
            Create AI Generated Quiz
          </Button>
        </DialogActions>
      </Dialog>

      <DndProvider backend={HTML5Backend}>
        <Stack
          direction={{
            xs: 'column-reverse',
            sm: 'row-reverse',
            md: 'row-reverse',
            lg: 'row-reverse'
          }}
          spacing={1}
          alignItems="center"
          sx={{ py: 1, flexWrap: 'wrap', mr: { sm: '8px', md: '8px', lg: '8px' } }}
        >
          <Stack>
            <Tooltip title="Search">
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={setSearchOption}
                  sx={{ p: '9px', minWidth: 'auto' }}
                  disabled={!value || value === ''}
                >
                  <SearchNormal1 />
                </Button>
              </span>
            </Tooltip>
          </Stack>
          {/* <Stack>
            <OutlinedInput
              value={value}
              onChange={(e: any) => {
                setValue(e.target.value);
                if (e.target.value === '') {
                  dispatch(updatequestionbanksearch(undefined));
                  dispatch(setquestionbankPageIndex(1));
                }
              }}
              placeholder={`Search ${totalCount || 0} records...`}
              id="start-adornment-email"
            />
          </Stack> */}
          <Stack sx={{ alignItems: 'center' }}>
            <Autocomplete
              multiple
              options={createdByOptions}
              getOptionLabel={(option) => option?._id}
              disableCloseOnSelect
              renderOption={(props, option) => (
                <li {...props}>
                  {option?.firstName} {option?.lastName}
                </li>
              )}
              renderInput={(params: any) => <TextField {...params} placeholder="Search Added By.." sx={{ width: 180 }} />}
              onChange={handleAutocompleteChange}
              sx={{ '& .MuiOutlinedInput-root': { p: 0.8 }, ml: 1 }}
              fullWidth
              value={createdByOptions.filter((option: any) => selectedValues.includes(option._id))}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    avatar={
                      <Avatar
                        alt={option?.firstName}
                        src={
                          option?.userProfilePicId?.path
                            ? process.env.REACT_APP_FILE_URL + option?.userProfilePicId?.path
                            : option?.userProfilePicId?.mimetype && option?.userProfilePicId?.file
                            ? `data:${option?.userProfilePicId?.mimetype};base64,${option?.userProfilePicId?.file}`
                            : ''
                        }
                      />
                    }
                    label={option?.firstName}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Stack>
        </Stack>
        {isLoading ? (
          <SkeletonTable columns={columns} />
        ) : loading ? (
          <Stack display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'center'}>
            <ProgressBar visible={true} height="80" width="80" />
            <Typography variant="h5">Getting Ai Generated Quiz...</Typography>
          </Stack>
        ) : (
          <CustomTable
            icon={DocumentText1}
            sortArray={['question']}
            text={<FormattedMessage id="No Questions available, Add Question now !" />}
            link="/quiz/questions/add"
            columns={columns}
            data={questionBank || []}
            setSortingData={setSortingData}
            sort={sortData}
            notFound={notFound}
            search={questionsearch || createdBys || questionCreatedBy || selectedFilter}
            count={totalCount}
            size={size}
            pageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleSizeChange={handleChange}
          />
        )}
      </DndProvider>
    </MainCard>
  );
};

export default QuestionBank;
