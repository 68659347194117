import React from 'react';
import MainBannerForm from './LandingPageForms/MainBannerForm';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import WhyUsForm from './LandingPageForms/WhyUsForm';
import FeaturesForm from './LandingPageForms/FeaturesForm';
import ConnectWithToolsForm from './LandingPageForms/ConnectWithToolsForm';
import LearningExperienceForm from './LandingPageForms/LearningExperienceForm';
import FAQsForm from './LandingPageForms/FAQsForm';
import UseCaseForm from './LandingPageForms/UseCase';
import OurClients from './LandingPageForms/OurClients';
import ElevateLearning from './LandingPageForms/ElevateLearning';
import CommonBanner from './LandingPageForms/CommonBanner';
import GetInTouchForm from './LandingPageForms/GetInTouch';
import FeaturesCards from './LandingPageForms/featureCards';

const FormRenderer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { typeData } = location.state || {};
  const renderFormComponent = () => {
    switch (typeData) {
      case 'banner':
        return <CommonBanner />;
      case 'whyUs':
        return <WhyUsForm />;
      case 'features':
        return <FeaturesForm />;
      case 'tools':
        return <ConnectWithToolsForm />;
      case 'experience':
        return <LearningExperienceForm />;
      case 'faq':
        return <FAQsForm />;
      case 'useCase':
        return <UseCaseForm />;
      case 'clients':
        return <OurClients />;
      case 'elevateLearning':
        return <ElevateLearning />;
      case 'getInTouch':
        return <GetInTouchForm />;
      case 'testimonials':
        return <GetInTouchForm />;
      case 'featureCards':
        return <FeaturesCards />;
      default:
        return <div>Unknown type</div>;
    }
  };

  return <div>{renderFormComponent()}</div>;
};

export default FormRenderer;
