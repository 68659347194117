import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

// project-imports
import Message from './Message';
import Profile from './Profile';
import Localization from './Localization';
import MobileSection from './MobileSection';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

// type
import { LAYOUT_CONST } from 'types/config';
import useAuth from 'hooks/useAuth';
import Cart from './cart';
import WishList from './wishList';
import Notification from './Notification';
import { useTheme } from '@mui/material/styles';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const theme = useTheme();
  const { i18n, menuOrientation } = useConfig();
  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  // const megaMenu = useMemo(() => <MegaMenuSection />, []);
  const localization = useMemo(() => <Localization />, [i18n]);
  const { user, organization } = useAuth();
  const consent = organization?.basicsettings?.identity?.sellingConsent;
  const permision = user?.userTypeId?.permission;

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && <DrawerHeader open={true} />}
      <Box sx={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
        {!downLG &&
          permision?.UserCourses?.Purchase &&
          consent &&
          user?.type !== 'superadmin' &&
          user?.type !== 'admin' &&
          user?.type !== 'faculty' && <Cart />}
        {!downLG && user?.type !== 'superadmin' && user?.type !== 'admin' && user?.type !== 'faculty' && <WishList />}
        {localization}
        {user?.type !== 'superadmin' && <Notification />}
        {user?.type !== 'superadmin' && user?.type !== 'admin' && <Message />}
        <Profile />
        <Typography
          sx={{
            color: theme.palette.secondary.main,
            ml: 1.5,
            mr: { sm: -2, lg: -4 },
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 2,
            p: 0.5,
            fontWeight: 600,
            '&:hover': {
              bgcolor: 'warning.lighter',
              borderColor: theme.palette.warning.light
            },
            display: { xs: 'none', sm: 'block' }
          }}
          variant="caption"
        >
          {user?.firstName} {user?.lastName}
        </Typography>
      </Box>
    </>
  );
};

export default HeaderContent;
