import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import pass from 'assets/images/pass.gif';
import fail from 'assets/images/fail.gif';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { Add } from 'iconsax-react';
import { addSkillMatrix, generateQuizMatrix } from 'services/quiz.service';
import Snackbar from 'utils/Snackbar';

const AssessmentReport = ({
  open,
  reportcard,
  OtherWise,
  status,
  Ids,
  location,
  answerChecking,
  handleClear,
  setQuiz,
  handleResetAll,
  setId
}: {
  open: boolean;
  reportcard: any;
  OtherWise?: any;
  status?: any;
  Ids?: any;
  location?: string;
  answerChecking?: any;
  handleClear?: any;
  setQuiz?: any;
  handleResetAll?: any;
  setId?: any;
}) => {
  const [resultOpen, setResultOpen] = useState(open || false);
  const report = reportcard;
  const navigate = useNavigate();
  const { user }: any = useAuth();
  const [showGif, setShowGif] = useState(status);
  const hours = Math.floor(report?.report?.duration / 3600);
  const remainingSeconds = report?.report?.duration % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const remainingFinalSeconds = remainingSeconds % 60;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGif(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  const handleClose = () => {
    navigate(-1);
    setResultOpen(false);
    handleClear(true);
  };

  const createSkillMatrix = (data: any) => {
    addSkillMatrix({
      classroomId: user?.classRoomId?._id,
      subjectId: data?.report?.subjectName,
      difficulty: data.difficulty
    })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          const quizId = res?.data?.data?._id;
          generateQuizMatrix({
            quizId: quizId,
            difficulty: data.difficulty
          })
            .then((additionalRes) => {
              if (additionalRes.status === 201 || additionalRes.status === 200) {
                // setQuizData(additionalRes?.data?.data);
                const rowData = {
                  ...additionalRes?.data?.data,
                  quizId: quizId,
                  difficulty: data.difficulty
                };
                if (rowData?.questions?.length > 0) {
                  setQuiz(rowData);
                  setId(quizId);
                  handleResetAll();
                  handleClose();
                } else Snackbar("There's no Questions in the Subject", 'warning');
              } else {
                Snackbar(additionalRes.data.message, 'error');
              }
            })
            .catch((error) => {
              Snackbar('Failed to fetch additional data', 'error');
            });
        } else {
          Snackbar(res.data.message, 'error');
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  const cardStyle = {
    maxWidth: 400,
    margin: 'auto',
    padding: '16px'
  };
  const cardStyle1 = {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  const mediaStyle = {
    height: 300
  };
  const mediaStyle1 = {
    height: 100,
    width: 100
  };
  const typographyStyle = {
    marginBottom: '8px'
  };
  const typographyStyle1 = {
    marginBottom: '0px'
  };
  const boxStyle1 = {
    p: 1,
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    textAlign: 'center'
  };

  function closeFullscreen() {
    if (document && document?.exitFullscreen) {
      document.exitFullscreen();
    } else if (document && document?.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document && document?.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
  return (
    <Dialog open={resultOpen} onClose={handleClose}>
      <DialogTitle sx={{ pl: 2, pr: 0.45, py: 1 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>Result</Grid>
          <Grid item>
            <IconButton color="error" onClick={handleClose}>
              <Add style={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          {showGif && (
            <Box style={cardStyle}>
              {report?.report?.status === 'pass' || report?.report?.pass ? (
                <img style={mediaStyle} alt="Pass" src={pass} />
              ) : (
                <img style={mediaStyle} alt="Fail" src={fail} />
              )}
            </Box>
          )}
          {!showGif && (
            <Box>
              <Box style={cardStyle1}>
                {report?.report?.status === 'pass' || report?.report?.pass ? (
                  <img style={mediaStyle1} alt="Pass" src={pass} />
                ) : (
                  <img style={mediaStyle1} alt="Fail" src={fail} />
                )}
              </Box>
              <Typography variant="h3" component="div" style={typographyStyle} sx={{ textAlign: 'center', textTransform: 'capitalize' }}>
                {report?.report?.status || (report?.report?.pass ? 'pass' : 'fail')}
              </Typography>
              <Typography variant="h5" color="textPrimary" sx={{ textAlign: 'center' }}>
                {report?.report?.message ? report?.report?.message : report?.message}
              </Typography>
              <Grid container spacing={1} sx={{ py: 1, px: 2, textAlign: 'center' }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h6" color="textPrimary">
                    Total Score:&nbsp;
                    <strong>
                      {Math.round(
                        report?.report?.userScore ? Number(report?.report?.userScore) || 0 : Number(report?.report?.overallScore) || 0
                      )}
                    </strong>
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {report?.level ? (
                    <Typography variant="h6" color="textPrimary">
                      {report?.report?.pass ? 'passed level:' : 'failed level:'}
                      <strong>{report?.level}</strong>
                    </Typography>
                  ) : (
                    <Typography variant="h6" color="textPrimary">
                      Time Taken:&nbsp;
                      <strong>
                        {hours < 10 ? '0' : ''}
                        {hours}H: {minutes < 10 ? '0' : ''}
                        {minutes}M: {remainingFinalSeconds < 10 ? '0' : ''}
                        {remainingFinalSeconds}S
                      </strong>
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Box sx={boxStyle1}>
                    <Typography variant="h4" color="textPrimary">
                      {report?.report?.correctAnswer
                        ? report?.report?.correctAnswer
                        : report?.report?.correctAnswers
                        ? report?.report?.correctAnswers
                        : '0'}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Correct
                      <br />
                      Answers
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Box sx={boxStyle1}>
                    <Typography variant="h4" color="textPrimary">
                      {report?.report?.inCorrectAnswer ? report?.report?.inCorrectAnswer : '0'}
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Incorrect
                      <br />
                      Answers
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Box sx={boxStyle1}>
                    <Typography variant="h4" color="textPrimary" style={typographyStyle1}>
                      {report?.report?.notGivenAnswer ? report?.report?.notGivenAnswer : '0'}
                    </Typography>
                    <Typography variant="body2" color="textPrimary" style={typographyStyle1}>
                      Skipped
                      <br />
                      Answers
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={1} justifyContent={'center'}>
                {answerChecking && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {answerChecking}
                  </Grid>
                )}
                {reportcard?.attempt && (
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Button variant="contained" color="info" fullWidth onClick={OtherWise}>
                      Re-Attempt Test
                    </Button>
                  </Grid>
                )}
                {/* {report?.level && (
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      {report?.level === 'easy' && (
                        <Button variant="contained" color="primary" onClick={() => createSkillMatrix({ ...report, difficulty: 'medium' })}>
                          Go to Medium Level
                        </Button>
                      )}
                      {report?.level === 'medium' && (
                        <Button variant="contained" color="primary" onClick={() => createSkillMatrix({ ...report, difficulty: 'hard' })}>
                          Go to Hard Level
                        </Button>
                      )}
                      {
                        <Button variant="contained" color="secondary" onClick={() => navigate('/learner/quiz')}>
                          Close
                        </Button>
                      }
                    </Grid>
                  </Grid>
                )} */}
                {report?.fromQuiz && (
                  <Grid
                    item
                    lg={reportcard?.attempt ? 6 : 12}
                    md={reportcard?.attempt ? 6 : 12}
                    sm={reportcard?.attempt ? 6 : 12}
                    xs={reportcard?.attempt ? 6 : 12}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        closeFullscreen();
                        navigate(location ? location : user?.type === 'student' ? '/learner/quiz' : 'quiz', { state: Ids });
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {report?.level === 'easy' && (
          <Button variant="contained" color="primary" onClick={() => createSkillMatrix({ ...report, difficulty: 'medium' })}>
            Go to Medium Level
          </Button>
        )}
        {report?.level === 'medium' && (
          <Button variant="contained" color="primary" onClick={() => createSkillMatrix({ ...report, difficulty: 'hard' })}>
            Go to Hard Level
          </Button>
        )}
        {
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              // navigate(-1);
              handleClose();
              closeFullscreen();
            }}
          >
            Close
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default AssessmentReport;
