import React from 'react';
import { chatEvents, socket } from 'utils/socket';
import useAuth from './useAuth';
import { dispatch, useSelector } from 'store';
import { getRoomUser, hasError, setAIRoomId } from 'store/reducers/chat';
import Snackbar from 'utils/Snackbar';
import useChat from './useChat';
import { useLocation } from 'react-router';

const useAIChatOptions = () => {
  const { user } = useAuth();
  const roomUsers = useSelector((state: any) => state?.chat?.roomUsers);
  function createAIRoom(users: [string]) {
    try {
      socket?.emit(
        chatEvents.CREATE_ROOM,
        { userId: user?._id, organizationId: user?.organizationId?._id, isAIChat: true, users },
        async (data: any) => {
          if (!data?.result?.message) {
            const updatedData = roomUsers?.length > 0 ? [data?.result, ...roomUsers] : [data?.result];
            dispatch(getRoomUser(updatedData));
            dispatch(setAIRoomId(data?.result?._id));
          } else {
            Snackbar(data?.result?.message, 'error');
          }
        }
      );
    } catch (error) {
      dispatch(hasError(error));
    }
  }
  return { createAIRoom };
};

export default useAIChatOptions;
