import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Box,
  TextField,
  FormControl,
  Typography,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText
} from '@mui/material';
import MainCard from 'components/MainCard';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Snackbar from 'utils/Snackbar';
import { addContent, editContent } from 'services/landingPageManagement.service';
import BackToggle from 'components/third-party/dropzone/BackToggle';
import React from 'react';
import { AddCircle, MinusCirlce } from 'iconsax-react';
import UploadAvatar from 'components/third-party/dropzone/Avatar';
import { FormattedMessage } from 'react-intl';
import { MuiTelInput } from 'mui-tel-input';
import { parsePhoneNumber } from 'libphonenumber-js';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  description: yup.string().required('Description is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  files: yup.mixed().test('fileType', 'Only JPG, PNG, JPEG, and GIF files are allowed', (value) => {
    if (!value || value.length === 0) return true; // Allow empty file
    const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
    return allowedFileTypes.includes(value.type);
  }),
  contactNo: yup.string(),
  points: yup
    .array()
    .of(
      yup.object({
        point: yup.string().required('point is required'),
        image: yup
          .mixed()
          .required('image is required')
          .test('fileType', 'Only JPG, PNG, JPEG, SVG and GIF files are allowed', (value) => {
            if (!value || value.length === 0) return false;
            const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg', 'image/svg+xml'];
            return allowedFileTypes.includes(value.type ? value.type : value.mimetype);
          })
      })
    )
    .min(1, 'At least one point is required')
    .max(5, 'You can only add up to 5 cards')
});

function GetInTouchForm(data: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { rowData } = location.state || {};
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const existingImage = rowData?.image ? process.env.REACT_APP_FILE_URL + rowData?.image?.path : null;
  const [contactObj, setContactObj] = useState<any>({});

  const formik = useFormik({
    initialValues: {
      //   ...(rowData ? { cmsId: rowData?._id } : {}),
      type: rowData?.type || '',
      title: rowData?.title || '',
      description: rowData?.description || '',
      email: rowData?.contactEmail || '',
      contactNo: rowData?.contactNo || '+91',
      points: rowData?.points || [{ image: rowData ? rowData.points.image : [], point: '' }],
      files: rowData ? rowData.image : []
    },
    validationSchema,

    onSubmit: (values) => {
      if (contactObj?.nationalNumber && contactObj?.countryCode) {
        const parsedNumber = parsePhoneNumber(contactObj.nationalNumber, contactObj.countryCode);

        if (!parsedNumber.isValid()) {
          Snackbar('Invalid contact number', 'error');
          return;
        }
      }

      const formData = new FormData();
      setIsButtonDisabled(true);
      const serviceMethod = rowData ? editContent : addContent;

      console.log('values.points', values.points);
      formData.append('title', values.title);
      formData.append('description', values.description);
      formData.append('contactEmail', values.email);
      formData.append('contactNo', values.contactNo);
      rowData && formData.append('id', rowData?._id?.toString());

      if (rowData) {
        values.points.forEach((point: any, index: any) => {
          formData.append(`points[${index}][image]`, point.image._id ? point.image._id : point.image);
          formData.append(`points[${index}][point]`, point.point);
        });
      } else {
        values.points.forEach((point: any, index: any) => {
          formData.append(`points[${index}][image]`, point.image);
          formData.append(`points[${index}][point]`, point.point);
        });
      }

      serviceMethod(formData, 'getInTouch')
        .then((res) => {
          const successMessage = rowData ? 'Content updated successfully' : 'Content added successfully';
          const errorMessage = res?.data?.message || 'An error occurred';

          if (res.status === 200 || res.status === 201) {
            Snackbar(successMessage, 'success');
            navigate('/superadmin/landingpagemanagement');
          } else {
            Snackbar(errorMessage, 'error');
            setIsButtonDisabled(false);
          }
        })
        .catch((err) => {
          Snackbar(err?.data?.message || 'An error occurred', 'error');
          setIsButtonDisabled(false);
        });
    }
  });

  const handleAddPoints = () => {
    if (formik.values.points.length < 5) {
      formik.setFieldValue('points', [...formik.values.points, { point: '', image: '' }]);
    }
  };

  const handleRemovePoints = (index: number) => {
    if (formik.values.points.length > 1) {
      const updatedPoints = [...formik.values.points];
      updatedPoints.splice(index, 1);
      formik.setFieldValue('points', updatedPoints);
    }
  };
  return (
    <MainCard
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <BackToggle show={true} />
          <span style={{ marginLeft: 10 }}>{rowData ? 'Update Content' : 'Add Content'}</span>
        </span>
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Title</InputLabel>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="description">Description</InputLabel>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  multiline
                  rows={4}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="personal-experience">
                <FormattedMessage id="Contact Number" />
              </InputLabel>
              <FormControl>
                <MuiTelInput
                  name="contactNo"
                  value={formik.values.contactNo}
                  onChange={(e, number: any) => {
                    setContactObj(number);
                    formik.handleChange;
                    formik.setFieldValue('contactNo', number.numberValue);
                  }}
                  error={formik.touched.contactNo && Boolean(formik.errors.contactNo)}
                  helperText={formik.touched.contactNo && formik.errors.contactNo && String(formik.errors.contactNo)}
                />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                <TextField
                  fullWidth
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </Stack>
            </Grid> */}
            <Divider />
          </>

          <>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                p: 4,
                m: 2,
                border: '1px solid #e0e0e0',
                borderRadius: '8px'
              }}
            >
              <>
                {formik.values.points.map((point: any, index: any) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      p: 4,
                      m: 2,
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px'
                    }}
                  >
                    <Grid container spacing={3} sx={{ width: '100%' }}>
                      <Grid item xs={12} sm={4}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="image">Upload Image Icon</InputLabel>
                          <Stack spacing={1}>
                            <UploadAvatar
                              setFieldValue={formik.setFieldValue}
                              file={point.image ? process.env.REACT_APP_FILE_URL + point?.image?.path : null}
                              name={`points[${index}].image`}
                            />
                            {formik.touched.points?.[index]?.image && formik.errors.points?.[index]?.image && (
                              <FormHelperText error style={{ color: 'red' }}>
                                {formik.errors.points?.[index]?.image as string}
                              </FormHelperText>
                            )}
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="point"
                          name={`points[${index}].point`}
                          value={point.point}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={formik.touched.points?.[index]?.point && Boolean(formik.errors.points?.[index]?.point)}
                          helperText={formik.touched.points?.[index]?.point && formik.errors.points?.[index]?.cardTitle}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                          <IconButton onClick={() => handleRemovePoints(index)} disabled={formik.values.points.length <= 1}>
                            <MinusCirlce />
                          </IconButton>
                          <IconButton onClick={handleAddPoints} disabled={formik.values.points.length >= 5}>
                            <AddCircle />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </>
            </Box>
          </>

          <Divider />

          <Grid item xs={12}>
            <Stack direction="row" justifyContent={{ xs: 'center', sm: 'center', md: 'flex-start', lg: 'flex-end' }}>
              <Link to={''} onClick={() => navigate(-1)}>
                <Button variant="outlined" color="error" sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Link>
              <Button variant="contained" type="submit" disabled={isButtonDisabled}>
                {rowData ? 'Update' : 'Add'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
}

export default GetInTouchForm;
