import { createSlice } from '@reduxjs/toolkit';
import StatusCode from '../../utils/StatusCode';
const initialState: {
  notes: Array<any>;
  announcement: Array<any>;
  faqs: Array<any>;
  status: any;
  selectedNote: any;
  bookmarks: Array<any>;
  selectedBookmark: any;
  search: any;
} = {
  notes: [],
  bookmarks: [],
  search: undefined,
  announcement: [],
  faqs: [],
  selectedNote: null,
  selectedBookmark: null,
  status: StatusCode.IDLE
};

const notes: any = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setAllNotes(state: any, action: any) {
      // action.payload.sort((a: any, b: any) => {
      //   if (a.commentOnTime && b.commentOnTime) {
      //     const timeA = a.commentOnTime && a.commentOnTime !== '0' ? a.commentOnTime.split(':') : [0, 0];
      //     const timeB = b.commentOnTime && b.commentOnTime !== '0' ? b.commentOnTime.split(':') : [0, 0];
      //     if (timeA[0] !== timeB[0]) {
      //       return timeA[0] - timeB[0];
      //     } else {
      //       return timeA[1] - timeB[1];
      //     }
      //   }
      //   return 0;
      // });
      state.notes = action.payload
      // return { ...state, notes: action.payload };
    },
    updateNotes(state: any, action: any) {
      const arr = state.notes.filter((item: any) => item._id !== action.payload._id);
      state.notes = [...arr, action.payload];
    },
    deleteNote(state: any, action: any) {
      const arr = state.notes.filter((item: any) => item._id !== action.payload);
      state.notes = arr;
    },
    setNote(state: any, action: any) {
      const notesData = [...state.notes, action.payload];
      notesData.sort((a, b) => {
        if (a.commentOnTime && b.commentOnTime) {
          const timeA = a.commentOnTime && a.commentOnTime !== '0' ? a.commentOnTime.split(':') : [0, 0];
          const timeB = b.commentOnTime && b.commentOnTime !== '0' ? b.commentOnTime.split(':') : [0, 0];
          if (timeA[0] !== timeB[0]) {
            return timeA[0] - timeB[0];
          } else {
            return timeA[1] - timeB[1];
          }
        }
        return 0;
      });
      return { ...state, notes: notesData };
    },
    setAllBookMarks(state: any, action: any) {
      state.bookmarks = action.payload;
    },
    // updateBookmarks(state: any, action: any) {
    //   const arr = state.bookmarks.filter((item: any) => item._id !== action.payload._id);
    //   state.bookmarks = [...arr, action.payload];
    // },
    deleteBookmarks(state: any, action: any) {
      const { courseId, sectionId, contentId, _id } = action.payload;
      const updatedBookmarks = state.bookmarks.map((bookmark: any) => {
        if (bookmark.course._id === courseId) {
          const updatedSections = bookmark.course.section.map((section: any) => {
            if (section._id === sectionId) {
              const updatedContents = section.contents.filter((content: any) => content.bookMarkId !== _id);
              return {
                ...section,
                contents: updatedContents
              };
            }
            return section;
          });

          return {
            ...bookmark,
            course: {
              ...bookmark.course,
              section: updatedSections
            }
          };
        }
        return bookmark;
      });

      state.bookmarks = updatedBookmarks;
    },
    setBookmark(state: any, action: any) {
      const bookmarkData = [...state?.bookmarks, action.payload];
      state.bookmarks = bookmarkData;
    },
    setBookmarkSearch(state, action) {
      state.search = action.payload;
    },
    setAllAnnouncement(state: any, action: any) {
      state.announcement = action.payload;
    },
    setReduxAnnouncement(state: any, action: any) {
      const arr = state.announcement.map((item: any) => {
        if (item._id === action.payload.announcement._id) {
          return action.payload.announcement;
        }
        return item;
      });
      state.announcement = arr;
    },
    deleteAnnouncementComment(state: any, action: any) {
      const arr = state.announcement.map((item: any) => {
        if (item._id === action.payload.announcementId) {
          const comments = item.comments.filter((item: any) => item._id !== action.payload.commentId);
          item.comments = comments;
          return item;
        }
        return item;
      });
      state.announcement = arr;
    },
    updateAnnouncementComment(state: any, action: any) {
      const arr = state.announcement.map((item: any) => {
        if (item._id === action.payload.announcementId) {
          for (let i = 0; i <= item.comments.length; i++) {
            if (item.comments[i]._id === action.payload.commentId) {
              item.comments[i].comment = action.payload.comment;
              return item;
            }
          }
          return item;
        }
        return item;
      });
      state.announcement = arr;
    },
    setAllFAQ(state: any, action: any) {
      state.faqs = action.payload;
    },
    addFAQ(state: any, action: any) {
      const arr = [action.payload, ...state.faqs];
      state.faqs = arr;
    },
    updateFAQ(state: any, action: any) {
      const arr = state.faqs.filter((item: any) => item._id !== action.payload._id);
      state.faqs = [action.payload, ...arr];
    },
    deleteFAQ(state, action) {
      const arr = state.faqs.filter((item: any) => item._id !== action.payload);
      state.faqs = arr;
    },
    setSelectedNote(state, action) {
      state.selectedNote = action.payload;
    },
    setSelectedBookmark(state, action) {
      state.selectedBookmark = action.payload;
    }
  }
});

export const {
  setAllNotes,
  setNote,
  updateNotes,
  deleteNote,
  setAllAnnouncement,
  setReduxAnnouncement,
  deleteAnnouncementComment,
  updateAnnouncementComment,
  setAllFAQ,
  addFAQ,
  updateFAQ,
  deleteFAQ,
  setSelectedNote,
  setAllBookMarks,
  updateBookmarks,
  deleteBookmarks,
  setSelectedBookmark,
  setBookmark,
  setBookmarkSearch
} = notes.actions;
export default notes.reducer;
