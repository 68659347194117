import { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Collapse,
  TextField,
  Grid,
  IconButton,
  Typography,
  Stack,
  Button,
  Avatar,
  Tooltip,
  Divider,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  Select,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  OutlinedInput,
  DialogContentText
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { dispatch, useSelector } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddCircle, ArrowDown2, ArrowUp2, Award, CloseSquare, Edit, Minus, SearchNormal1 } from 'iconsax-react';
import MainCard from 'components/MainCard';
import Loader2 from 'components/Loader2';
import { deleteSingleBadge, deleteType, getBadgeTypeName } from 'services/badges.service';
import useAuth from 'hooks/useAuth';
import { delbadgelist, setbadgelistPageIndex, setbadgelistTableSize, updatebadgelist, delbadgetypelist, resetStateBadges } from 'store/reducers/badgelist';
import Snackbar from 'utils/Snackbar';
import IconButtons from 'components/@extended/IconButton';
import { EmptyTable } from 'components/third-party/ReactTable';
import NotFound from 'pages/extra-pages/not-found';
import { formatDateBasedOnOrgSettings, getLocaleName } from 'utils/helper';
import { FormattedMessage } from 'react-intl';
import useConfig from 'hooks/useConfig';
import moment from 'moment';
import SkeletonTable from 'components/SkeletonTable';
import NewSampleBadge from './NewSampleBadge';
import useContemp from 'hooks/useContemp';

function BadgesList() {
  const navigate = useNavigate();
  const { user, organization }: any = useAuth();
  const { i18n } = useConfig();
  const { pathname } = useLocation();
  const BadgesPermission = user?.userTypeId?.permission?.Badges;
  const [open, setOpen] = useState(false);
  const pageIndex = useSelector((state: any) => state?.badgelist)?.pageIndex;
  const size = useSelector((state: any) => state?.badgelist)?.size;
  const badgelist = useSelector((state: any) => state?.badgelist)?.badgelist;
  const [isLoading, setLoading] = useState(badgelist && badgelist.length > 0 ? false : true);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [notFound, setNotFound] = useState(true);
  const [PlaceValues, setPlaceValues] = useState<any>();
  const { prevStoredData } = useContemp();

  useEffect(() => {
    getLocaleName(i18n)
      .then((result) => {
        setPlaceValues(result);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [i18n]);

  const setDeleteCount = () => {
    setTotalCount(totalCount - 1);
  };

  useEffect(() => {
    if (typeof prevStoredData?.[pathname]?.pageIndex === 'number') dispatch(setbadgelistPageIndex(prevStoredData?.[pathname]?.pageIndex));
    if (typeof prevStoredData?.[pathname]?.pageSize === 'number') dispatch(setbadgelistTableSize(prevStoredData?.[pathname]?.pageSize));
    return () => {
      dispatch(resetStateBadges());
    };
  }, [dispatch]);

  useEffect(() => {
    fetchbadgelist();
  }, [size, pageIndex, searchValue]);
  const fetchbadgelist = () => {
    let reqData: any = {
      orgId: user?.organizationId?._id,
      skip: pageIndex,
      limit: size
    };
    if (search) reqData.search = search;
    try {
      setLoading(true);
      getBadgeTypeName(reqData)
        .then((result: any) => {
          setLoading(false);
          if (result?.status === 200) {
            dispatch(updatebadgelist(result?.data?.data?.data));
            setTotalCount(result?.data?.data?.count);
            if (result?.data?.data?.count > 0) {
              setNotFound(false);
            }
          } else {
            Snackbar(result?.data?.message, 'error');
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response.status === 403) {
            Snackbar("you don't have access to view badges", 'warning');
          } else {
            Snackbar('Something went wrong', 'error');
          }
        });
    } catch (error) {
      // console.error(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: '#'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Created By',
        accessor: 'createdBy'
      },
      {
        Header: 'Total Badges',
        accessor: 'totalBadges'
      },
      {
        Header: 'Options',
        accessor: 'options'
      }
    ],
    []
  );
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event: SelectChangeEvent<number>) => {
    dispatch(setbadgelistPageIndex(1));
    dispatch(setbadgelistTableSize(+event.target.value));
  };
  const handlePageChange = (event: any, page: number) => {
    dispatch(setbadgelistPageIndex(page));
  };
  const OnClickSearch = () => {
    setSearchValue(search);
  };
  const badgeRows = useMemo(() => {
    return badgelist?.map((row: any, index: any) => <Row key={row?._id} row={row} indexField={index} setDeleteCount={setDeleteCount} pageIndex={pageIndex} size={size} />);
  }, [badgelist]);
  return (
    <MainCard
      content={false}
      title={
        <span style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">
            <FormattedMessage id="Badges List" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="View the complete list of badges. You can add, edit, update, and delete badges as needed." />
          </Typography>
        </span>
      }
      secondary={
        <>
          <Tooltip title={<FormattedMessage id="Add Badge" />}>
            <span>
              <IconButtons
                sx={{ mr: 2 }}
                variant="dashed"
                color="primary"
                onClick={(e) => {
                  navigate('/badges/add');
                }}
                disabled={!BadgesPermission?.Create}
              >
                <AddCircle />
              </IconButtons>
            </span>
          </Tooltip>
        </>
      }
    >
      <Divider />
      <Stack direction="row-reverse" spacing={1} sx={{ py: 1, mr: 2 }} alignItems="center">
        <Stack>
          <Tooltip title="Search">
            <span>
              <Button
                variant="outlined"
                color="primary"
                disabled={!search || search === ''}
                onClick={OnClickSearch}
                sx={{ p: '9px', minWidth: 'auto' }}
              >
                <SearchNormal1 />
              </Button>
            </span>
          </Tooltip>
        </Stack>
        <Stack>
          <OutlinedInput
            value={search}
            onChange={(e: any) => {
              setSearch(e.target.value);
              if (e.target.value === '') {
                setSearchValue('');
              }
            }}
            placeholder={`Search ${totalCount || 0} records...`}
            id="start-adornment-email"
          />
        </Stack>
      </Stack>
      {isLoading ? (
        <SkeletonTable columns={columns} count={5} />
      ) : (
        <TableContainer>
          <Table aria-label="Result History">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>
                  <FormattedMessage id="Type" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="Created By" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="Total Badges" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="Created At" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="Options" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notFound ? (
                <TableRow>
                  <TableCell
                    colSpan={12}
                    sx={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center', height: '10vh', fontWeight: 'bold' }}
                  >
                    <NotFound icon={Award} text={<FormattedMessage id="No Badges available, Add Badge now!" />} link={'/badges/add'} />
                  </TableCell>
                </TableRow>
              ) : badgelist?.length === 0 ? (
                <EmptyTable msg={<FormattedMessage id="No Data" />} colSpan={9} />
              ) : (
                badgeRows
              )}
            </TableBody>
          </Table>
          <Divider />
          <Box sx={{ p: 2, py: 2 }}>
            <Grid container alignItems="center" justifyContent="space-between" sx={{ width: 'auto' }}>
              <Grid item>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="caption" color="secondary">
                    <FormattedMessage id="Row per page" />
                  </Typography>
                  <FormControl sx={{ m: 1 }}>
                    <Select
                      id="demo-controlled-open-select"
                      open={open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={size}
                      onChange={handleChange}
                      size="small"
                      sx={{ '& .MuiSelect-select': { py: 0.75, px: 1.25 } }}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
                {totalCount === 0 ? (
                  <></>
                ) : (
                  <Pagination
                    onChange={handlePageChange}
                    count={Math.ceil(totalCount / size)}
                    page={pageIndex}
                    variant="combined"
                    color="primary"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </TableContainer>
      )}
    </MainCard>
  );
}

function Row({ row, indexField, setDeleteCount, size, pageIndex }: any) {
  const navigate = useNavigate();
  const { user, organization }: any = useAuth();
  const theme = useTheme();
  const { pathname } = useLocation();
  const backColor = alpha(theme.palette.success.darker, 0.1);
  const BadgesPermission = user?.userTypeId?.permission?.Badges;
  let reqData = { organizationId: user?.organizationId?._id };
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
  const [badgeToDelete, setBadgeToDelete] = useState(null);
  const [delBadge, setDelBadge] = useState<{ id: any; index: any } | null>(null);
  const { setData } = useContemp();

  const handleDeleteType = (id: any) => {
    setBadgeToDelete(id);
    setDialogOpen(true);
  };
  const handleDeleteConfirmedType = () => {
    if (badgeToDelete) {
      deleteTypeRequest(badgeToDelete);
    }
    setDialogOpen(false);
  };
  const handleDeleteBadge = (id: any, index: any) => {
    setDelBadge({ id, index });
    setBadgeToDelete(id);
    setDialogBoxOpen(true);
  };
  const handleDeleteConfirmedBadge = () => {
    if (badgeToDelete) {
      deleteBadge(delBadge);
    }
    setDialogBoxOpen(false);
  };
  const deleteTypeRequest = (badgeTypeId: any) => {
    deleteType({ badgeTypeId: badgeTypeId, organizationId: reqData.organizationId })
      .then((res: any) => {
        if (res.status === 200) {
          Snackbar('Badge Type Deleted Successfully!', 'success');
          dispatch(delbadgetypelist(badgeTypeId));
          setDeleteCount();
        } else {
          Snackbar('There Was An Error Deleting This Type!', 'error');
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };
  const deleteBadge = (badgeId: any) => {
    deleteSingleBadge({ badgeId: badgeId.id, organizationId: reqData.organizationId })
      .then((res: any) => {
        if (res.status === 200) {
          Snackbar('Badge Deleted Successfully!', 'success');
          dispatch(delbadgelist(badgeId));
        } else {
          Snackbar('There Was An Error Deleting This Badge!', 'error');
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          const errorMessage = err?.response?.data?.message || "You don't have the required permissions to perform this action.";
          Snackbar(errorMessage, 'warning');
        } else {
          const errorMessage = err?.response?.data?.message || 'An unexpected error occurred. Please try again later.';
          Snackbar(errorMessage, 'error');
        }
      });
  };

  const CSSShapes = [
    'mini-diamond-border',
    'diamond-border',
    'mini-circle-border',
    'circle-border',
    'mini-square-border',
    'square-border',
    'star-border1',
    'star-border2'
  ];

  const handleDeleteClose = () => {
    setDialogBoxOpen(false);
  };

  return (
    <>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ pl: 3 }}>
          {Array.isArray(row?.badges) && row.badges.filter((badge: any) => Object.keys(badge).length > 0).length > 0 ? (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <ArrowUp2 /> : <ArrowDown2 />}
            </IconButton>
          ) : (
            <Tooltip title={<FormattedMessage id="Please Add Badge!" />}>
              <span>
                <IconButton size="small">
                  <Minus />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </TableCell>
        <TableCell sx={{ textTransform: 'capitalize' }}>{row?.type}</TableCell>
        <TableCell sx={{ textTransform: 'capitalize' }}>
          <Tooltip title={`${row?.users?.firstName} ${row?.users?.lastName}`}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textTransform: 'capitalize' }}>
              <Avatar
                alt={row?.users?.firstName}
                src={
                  row?.users?.userProfilePicture?.path
                    ? process.env.REACT_APP_FILE_URL + row?.users?.userProfilePicture?.path
                    : `data:${row?.users?.userProfilePicture?.mimetype};base64,${row?.users?.userProfilePicture?.file}`
                }
              />
            </div>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ textTransform: 'capitalize' }}>
          {Array.isArray(row?.badges) && row.badges.filter((badge: any) => Object.keys(badge).length > 0).length > 0
            ? row.badges.length
            : 0}
        </TableCell>
        <TableCell sx={{ textTransform: 'capitalize' }}>
          {
            formatDateBasedOnOrgSettings(row?.created_at, organization)
          }
        </TableCell>
        <TableCell sx={{ textTransform: 'capitalize' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={!BadgesPermission?.Update}
            onClick={(e) => {
              navigate('/badges/edit', { state: { data: row?.type } });
            }}
            sx={{ m: 1 }}
          >
            <FormattedMessage id="Add" />
          </Button>
          <Button color="error" variant="contained" disabled={!BadgesPermission?.Delete} onClick={() => handleDeleteType(row?._id)}>
            <FormattedMessage id="Delete" />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow sx={{ bgcolor: backColor, '&:hover': { bgcolor: `${backColor} !important` } }}>
        <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
              <b>Delete Badge Type</b>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Badge Type?</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button variant="outlined" onClick={() => setDialogOpen(false)}  >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteConfirmedType}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        <Dialog open={dialogBoxOpen} onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <Box sx={{ p: 1, pb: 1.2, textAlign: 'center' }}>
            <DialogTitle id="alert-dialog-title" sx={{ fontSize: '20px' }}>
              <b>Delete Badge</b>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">Are you sure you want to delete this Badge ?</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button variant="outlined" onClick={handleDeleteClose}  >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteConfirmedBadge}
                autoFocus
              >
                Delete
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <TableCell sx={{ p: '0 !important' }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open &&
              Array.isArray(row?.badges) &&
              row.badges.filter((badge: any) => Object.keys(badge).length > 0).length > 0 &&
              row?.badges && (
                <MainCard sx={{ borderRadius: 0 }}>
                  <Grid container textAlign={'center'}>
                    {row?.badges?.map((badges: any, index: number) => {
                      return (
                        <div
                          key={index}
                          style={{
                            margin: 1,
                            padding: 1,
                            textAlign: 'center',
                            borderRadius: '8px',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          className="hover-grid"
                        >
                          <Box
                            className="outer-box"
                            sx={{
                              pb: 2,
                              pt: 1.8,
                              '&:hover': {
                                filter: 'blur(2px)'
                              },
                              '&:hover .inner-box': {
                                display: 'flex'
                              }
                            }}
                          >
                            <NewSampleBadge
                              badgeTitle={badges?.BadgeLevel}
                              badgeDesc={badges?.unlockCount + ' ' + badges?.BadgeTitle}
                              lowerBackground={badges?.lowerBackgroundColor}
                              upperBackground={badges?.upperBackgroundColor}
                              icon={badges?.icon}
                            />
                          </Box>
                          <Box
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderRadius: '8px',
                              position: 'absolute',
                              textAlign: 'center',
                              display: 'none',
                              '.hover-grid:hover &': {
                                display: 'flex'
                              }
                            }}
                            className="inner-box"
                            onMouseEnter={(e) => {
                              const parentElement: any = e.currentTarget.parentElement;
                              if (parentElement) {
                                const outerBox = parentElement.querySelector('.outer-box');
                                if (outerBox) {
                                  outerBox.style.filter = 'blur(2px)';
                                }
                              }
                            }}
                            onMouseLeave={(e) => {
                              const parentElement: any = e.currentTarget.parentElement;
                              if (parentElement) {
                                const outerBox = parentElement.querySelector('.outer-box');
                                if (outerBox) {
                                  outerBox.style.filter = 'blur(0px)';
                                }
                              }
                            }}
                          >
                            <Stack direction="row" sx={{ textAlign: 'center' }}>
                              <IconButton
                                color="primary"
                                onClick={(e) => {
                                  setData({
                                    [pathname]: { size, pageIndex, gotoUrl: '/badges/edit' }
                                  });
                                  navigate('/badges/edit', { state: { dataset: badges } });
                                }}
                              >
                                <Edit />
                              </IconButton>
                              <IconButton color="error" onClick={() => handleDeleteBadge(badges?._id, indexField)}>
                                <CloseSquare />
                              </IconButton>
                            </Stack>
                          </Box>
                        </div>
                      );
                    })}
                  </Grid>
                </MainCard>
              )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default BadgesList;
